import axios from 'axios';

 const API= axios.create({
  baseURL: `https://6gah8x5fyc.execute-api.us-east-2.amazonaws.com/dev/`
});
API.interceptors.response.use((res)=>{
  return res;
},
async(err)=>{
  const originalConfig=err.config;
//Checking Network error which is coming as a string  .
  if(err && typeof err === 'object'){     
    if((err.response.status===401 || err.response.status=== 403) && !originalConfig._retry ){
      originalConfig._retry=true;
    console.log("error caught")
    }
    
   
      // window.location.assign(origin);
    
  }
  window.location.assign(origin);
  return Promise.reject(err);
})
export default API;
