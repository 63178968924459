import React, { Component } from 'react'
import { Col, Row, Card, Modal } from 'react-bootstrap';
import API from '../../api/apis';
import UserContext from '../../context/UserContext';
import { dateTimeformat } from '../../utility/helperFunctions';
import InputField from '../common/input-fields/InputField';
import LoaderEx from '../common/loader/LoaderEx';
import { isCoordinator, showMessage, currentWorkflow, getWorkflowUrl } from './../ToReviewDetails/commonFunctions';
import Button from "../common/Button";
import DatatTableEx from '../common/DataTableEx';
import SwitchView from "../common/SwitchView";
import { switchEnum } from "../common/SwitchView/switchEnum";
import SwitchHistory from "../common/SwitchHistory";
import { OBJ_TYPES } from "../common/SwitchHistory/historyConsts";

class InvestigatorModalEx extends React.Component {
    static contextType = UserContext;
    state = {
        investigator_last_name: "",
        email: "",
        loading: true,
        investigator_first_name: '',
        investigator_middle_initial: '',
        customer_id: '',
        createdby: '',
        updatedby: '',
        patchObject: {},
        viewDetails: true,
    }
    SERVICES = {
        "CREATE-INVESTIGATOR": 'submission/investigator/add',
        "GET-INVESTIGATOR": 'submission/investigator/getbyid',
        "UPDATE-INVESTIGATOR": 'submission/investigator/update',
    }
    async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator });

        const { investigatorId } = this.props;
        if (investigatorId) {
            let investigatorData = await this.getInvestigatorById(investigatorId);

            if (investigatorData) {

                investigatorData["createdby"] =
                    (investigatorData.created_by_name?.length ? investigatorData.created_by_name + "  " : '') + (investigatorData?.createdAt ? dateTimeformat(investigatorData.createdAt) : "");

                investigatorData["updatedby"] = investigatorData.updated_by?.length > 0 ? investigatorData.updated_by_name + "  " + (investigatorData?.updatedAt ? dateTimeformat(investigatorData.updatedAt) : "") : investigatorData.createdby;

                investigatorData.submissions = investigatorData?.submissions.map(elm => ({ submissionId: `Submission-${elm.id}`, ...elm }));
                this.setState({
                    ...this.state,
                    ...investigatorData,
                    loading: false
                })
            } else {
                showMessage({ text: "Failed to get data", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false));
            }
        } else {
            this.setState({
                loading: false,
            })
        }

    }
    getInvestigatorById = async (investigatorId) => {
        try {
            const res = await API.get(`${this.SERVICES['GET-INVESTIGATOR']}/${investigatorId}`);
            if (res.data.success) {
                return res.data.data || null;
            } else {
                showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false));
            }

        } catch (e) {
            console.error(e);
            showMessage({ text: "Failed", icon: "error", button: "Ok" },
                () => this.props.handleModalShowHide(false));
        }
        return null;
    }
    setLoader = (status) => {
        this.setState({ loading: status });
    }
    handleSave = async (state) => {
        try {
            this.setLoader(true);

            if (await this.formValidation()) {
                let { investigatorId } = this.props;
                //trimming text fields
                const textFields = ['investigator_first_name', 'investigator_last_name', 'investigator_middle_initial', 'customer_id', 'email'];

                const { patchObject } = state;
                let patchObjectKeys = Object.keys(patchObject);
                patchObjectKeys.forEach((key) => {
                    if (textFields.includes(key)) {
                        patchObject[key] = patchObject[key].trim();
                    }
                }
                )
                //
                let payload = {};

                if (investigatorId) {

                    let updateObj = {
                        ...patchObject,
                        id: investigatorId,
                        updated_by: this.context.userSystemId
                    }
                    payload = { ...updateObj }

                } else {
                    let addObj = {
                        ...patchObject,
                        updated_by: null,
                        created_by: this.context.userSystemId,
                    };
                    payload = { ...addObj }
                }
                let url = investigatorId ? this.SERVICES['UPDATE-INVESTIGATOR'] : this.SERVICES['CREATE-INVESTIGATOR'];
                let investigatorResponse = await API.post(url, payload);
                if (investigatorResponse && investigatorResponse.data && investigatorResponse.data.success) {
                    showMessage({ text: "Saved Successfully", icon: "success", button: "Ok" }, () => {
                        this.props.handleModalShowHide(false, null, true);
                    });
                } else if (!investigatorResponse.data.success && investigatorResponse.data?.errors?.length && investigatorResponse.data.errors.includes('already exists!')) {
                    showMessage({ text: `Failed, ${investigatorResponse.data.errors}`, icon: "error", button: "Ok" });
                } else if (!investigatorResponse.data.success && investigatorResponse.data?.errors?.errors && investigatorResponse.data?.errors?.errors[0].validatorKey === 'isEmail') {
                    showMessage({ text: "Failed, Invalid Email", icon: "error", button: "Ok" });
                } else {
                    showMessage({ text: "Failed", icon: "error", button: "Ok" });
                }
            }
        }
        catch (exp) {
            console.error(exp);
        }
        finally {
            this.setLoader(false);
        }
    }
    columns = [
        {
            dataField: 'submissionId',
            text: 'Record Number',
            sort: true,
        },
        {
            dataField: 'unique_submission_identifier',
            text: 'Unique Submission Identifier',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Submission Status',
            sort: true,
        },
        {
            dataField: 'stage',
            text: 'Stage',
            sort: true,
        },];
    rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.navigateToNewWorkflow(row);
        }
    };
    navigateToNewWorkflow = async (data) => {
        this.setLoader(true);
        try {
            let is_new_workflow = await currentWorkflow(this.context.userSystemId);
            let url = getWorkflowUrl(is_new_workflow, data.id);
            window.location = url;
        } catch (e) {
            console.error(e);
        } finally {
            this.setLoader(false);
        }

    }

    get inputFieldsArray() {
        return [
            {
                label: "Investigator First Name ",
                type: "text",
                name: "investigator_first_name",
            },
            {
                label: "Investigator Middle Initial ",
                type: "text",
                name: "investigator_middle_initial",
            },
            {
                label: "Investigator Last Name *",
                type: "text",
                name: "investigator_last_name",
            },
            {
                label: "Email *",
                type: "text",
                name: "email",
            },
            {
                label: "Customer ID",
                type: "text",
                name: "customer_id",
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                disabled: true,
                edit: true
            }
        ];
    }
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
        });
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: e },
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            investigator_last_name: this.state.investigator_last_name,
            email: this.state.email

        }
        const errors = {};
        let customError = {};
        // const regex = new RegExp(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/);
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null || initialValues[key].toString().trim().length <= 0) {
                errors[key] = true;
                is_valid = false;
            }
            // if (regex.test(initialValues[key]) && (key === "name" || key === "countrycode")) {
            //     customError[key] = "Special Characters are not allowed"
            //     is_valid = false;
            // }

            this.setState({
                error: { ...errors },
                customerror: { ...customError }
            }, () => {
                try {
                    this.modalWindow._modal.dialog.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
                catch (exp) {
                    console.log(exp)
                }
            });
        });

        return is_valid;

    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.edit && !this.props.investigatorId)
                return false;
            if (field.name !== "createdBy" || field.name !== "updatedBy") {
                if (!this.state.is_Coordinator) {
                    field.disabled = true;
                }
            }
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || this.onInputChange : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, investigatorId } = this.props;
        const { is_Coordinator, loading, submissions, investigator_first_name, investigator_last_name, viewDetails } = this.state;
        return (
            <div>
                <Modal
                    ref={ref => this.modalWindow = ref}
                    show={showHideModal}
                    size="lg"
                    className="modal-mdx new-flow-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header className="d-flex border-0" >
                        <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-left">
                            <h5 className='modal-title text-left w-100 font-weight-bold'>
                                {investigatorId ?
                                    `Investigator Details for ${investigator_first_name?.length ? investigator_first_name + ', ' + investigator_last_name : investigator_last_name}` : "Add a New Investigator"}
                            </h5>
                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={() => handleModalShowHide(false)} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="position-relative">
                        {loading ? <LoaderEx /> :
                            <>
                            {investigatorId && 
                                <SwitchView
                                    viewMode={(value) => {
                                        if (value === switchEnum.DETAILS) {
                                            this.setState({ viewDetails: true });
                                        } else {
                                            this.setState({ viewDetails: false });
                                        }
                                    }}
                                />
                            }

                            { viewDetails ? <><Row>
                                {this.renderInputFields()}
                            </Row>
                                {investigatorId ? <Row><DatatTableEx
                                    id="submissionInvestigator"
                                    csvName='Investigator_Submissions'
                                    data={submissions} columns={this.columns}
                                    title='Submissions'
                                    titleClass='title-lg'
                                    rowEvents={this.rowEvents} />
                                </Row> : <></>}</>

                            : <SwitchHistory 
                                object_type={OBJ_TYPES.INVESTIGATOR} 
                                object_id={investigatorId} 
                              />
                            }
                            </>
                        }
                    </Modal.Body>
                    {(!loading && viewDetails) ? <Modal.Footer className="d-flex border-0 d-flex justify-content-center">
                        <Button style="danger w-100px" onClick={() => handleModalShowHide(false)} className="d-flex align-items-center p-2 justify-content-center"><span>Cancel</span></Button>
                        {is_Coordinator ?
                            <Button style="success w-100px" onClick={() => this.handleSave(this.state)} className="d-flex align-items-center p-2 justify-content-center"><span>{investigatorId ? 'SAVE' : 'ADD'}</span></Button>
                            : <></>}
                    </Modal.Footer> : <></>}
                </Modal>
            </div>
        )
    }

}

export default InvestigatorModalEx;