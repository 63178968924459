const OBJ_TYPES = { 
  DELEGEE: "Delegee",
  INVESTIGATOR: "Investigator",
  COUNTRY: "Country",
  COMPOUND: "Compound",
  STRENGTH: "Strength",
  PRESENTATION: "Presentation",
  COUNT: "Count",
  DRUGORDER: "DrugOrder",
  CONCEPT: "Concept",
  CONTACT: "Contact",
  STUDY_COMPOUND: "StudyCompound",
  PROPOSAL: "Proposal",
  DIAGNOSTIC_PROPOSAL: "DiagnosticProposal",
  TRANSLATIONAL_PROPOSAL: "TranslationalProposal",
  CURRENCY: "Currency",
  REVIEWER: "Reviewer"
};

export { OBJ_TYPES };
