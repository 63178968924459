import React, { Component } from 'react';
import {Accordion,Card, Button, Col, Container, Row,Modal } from 'react-bootstrap';
import InputField from './common/input-fields/InputField';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';
import { INDICATIONS } from '../consts';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import UserContext from '../context/UserContext';
import { dateChange } from '../utility/helperFunctions';
import * as IIRBtn  from './common/Button';
export default class NewDiagnosticEx extends Component {
    titleRef = React.createRef();
    static contextType = UserContext;

    state={
    recordID:"",
    diagnosticSubmitted:false,
    submissionId: "Submission-"+this.props.submissionId,
    deleted:"",
    diagnostic:"",
    currencyISOCode:"",
    createdDate:"",
    createdByID :"",
    lastModifiedDate:"",
    LastModifiedByID:"",
    systemModstamp:"",
    lastActivityDate:"",
    submission:"",
    assayMethod:"None",
    assayName:"",
    assayRegulatoryStatus:"None",
    iSLabCLIAISOcertified:"None",
    cityCoinvestigator:"",
    countryCoinvestigator:"",
    coInvestigatorState:"",
    diagnosticSubmitted:"",
    emailAddressCoInvestigator:"",
    emailAddressLegalRepresentative:"",
    emailAddressforSafetyMailNotification:"",
    firstNamecoinvestigator:"",
    firstNamelegalreprresentative:"",
    uniquePersonalClinicalsamples:"None",
    institution:"",
    isAnotherentitythesponsorofthisst:"",
    labExternalQualityAssessmentProgram:"None",
    biobankowner:"None",
    lastNameCoInvestigator:"",
    lastNamelegal:"",
    cityLegalRepresentative:"",
    countryLegal: "",
    stateRepresentativeState:[],
    methods:"",
    middleInitiallcoinvestigator:"",
    middleInitialLegal:"",
    otherHelpfulProjectTimelineInformation:"",
    phoneNumberCoInvestigator:"",
    phoneNumberLegalRepresentative:"",
    platform:"",
    primaryObjectiveHypothesis:"",
    projectedStudyCompleteDate:"",
    projectedStudyStartDate:"",
    projectedDateForPublication:"",
    projectedDateForStudyReportSubmission:"",
    projectedStudyLength:"",
    projectedTimingForKeyStudyMilestone:"",
    publicationStrategy:"",
    references:"",
    regulatoryApproved:"None",
    sampleDescription:"None",
    pleaseDescribeIfOther1:"",
    pleaseDescribeIfOther2:"",
    pleaseDescribeIfOther3:"",
    scientificRationale:"",
    secondaryObjective:"",
    sourceOfSamples:"None",
    specialty:"",
    streetAddressCoInvestigator:"",
    streetAddressLegalRepresentative:"",
    studyReportRequestExplain:"",
    studyTitle:"",
    studyType:"None",
    targetCompletingDate:"",
    targetStartingDate:"",
    specificTimingRequirements:"",
    testingTiming:"None",
    tumorType:"None",
    zipCodeCoInvestigator:"",
    zipCodeLegalRepresentative:"",
    evidenceOfAssayValidation:"",
    finalStudyReport:"None",
    ifAnyCoInvestigators:"None",
    laboratoryCertificationsDescription:"",
    medicalLicenseNumber:"",
    rawDataIfRequestedExplain:"",
    rawDataRequested:"None",
    requestingResearchFunding:"None",
    successModal: false,
    errorModal: false,
    countryList:[],
    stateListLegal: [],
    stateListCoinvestigator: [],
    patchObject: {}
}
get inputFieldsArrayInformation() {
    return[
        
         {
             label: "Diagnostic Submitted",
             type: "checkbox",
             name: "diagnosticSubmitted",
         },
         {
             label: "Submission",
             type: "text",
             name: "submissionId",
             disabled: true
         },
          
    ]
}
get inputFieldsArrayLegalRepresentative() {
    return[
       
         {
            label: "[EXT] First Name Legal Representative",
           type: "text",
            name: "firstNamelegalreprresentative",
        },
        {
            label: "[EXT] Middle Initial Legal",
            type: "text",
            name: "middleInitialLegal",
            
        },
        {
            label: "[EXT] Last Name Legal",
            type: "text",
            name: "lastNamelegal"
        },
        {
            label: "[EXT] Street Address Legal Representative",
            type: "text",
            name: "streetAddressLegalRepresentative"
        },
        
        {
            label: "[EXT] City Legal Representative",
            type: "text",
            name: "cityLegalRepresentative",
        },
        {
            label: "[EXT] Country Legal",
            type: "searchableDropdown",
            placeholder: "Select Country legal",
            name: "countryLegal",
            options: this.state.countryList,
           onSelect: this.onCountryChange
        },
        {
            label: "[EXT] State Legal Representative",
            type: "searchableDropdown",
            placeholder: "Select [EXT] State Legal Representative",
            name: "stateRepresentativeState",
            options: this.state.stateListLegal,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Zip Code Legal Representative",
            type: "text",
            name: "zipCodeLegalRepresentative",
           
        },
        {
            label: "[EXT] Phone Number Legal Representative",
            type: "text",
            name: "phoneNumberLegalRepresentative",
           
        },
        {
            label: "[EXT] Email Address Legal Representative",
            type: "text",
            name: "emailAddressLegalRepresentative",
           
        },
    ]
}
get inputFieldsArrayCoInvestigator() {
    return[
      
         {
            label: "[EXT] If any Co-Investigators",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "ifAnyCoInvestigators",
            options: [
                "None",
                "Yes",
                "No"
            ]
           
        },
        {
            label: "[EXT] Middle Initial Co-Investigator",
            type: "text",
            name: "middleInitiallcoinvestigator",
           
        },
        {
            label: "[EXT] First Name Co-Investigator",
            type: "text",
            name: "firstNamecoinvestigator",
           
        },
        {
            label: "[EXT] Institution",
            type: "text",
            name: "institution",
           
        },
        {
            label: "[EXT] Last Name Co-Investigator",
            type: "text",
            name: "lastNameCoInvestigator",
           
        },
        {
            label: "[EXT] City Co-Investigator",
            type: "text",
            name: "cityCoinvestigator",
           
        },
        {
            label: "[EXT] Street Address Co-Investigator",
            type: "text",
            name: "streetAddressCoInvestigator",
           
        },
        {
            label: "[EXT] Co-Investigator State",
            type: "searchableDropdown",
            placeholder: "Select CoInvestigator State",
            name: "coInvestigatorState",
            options: this.state.stateListCoinvestigator,
            onSelect: this.onSearchSelect
           
        },
        {
            label: "[EXT] Country Co-Investigator",
            type: "searchableDropdown",
            placeholder: "Select Country legal",
            name: "countryCoinvestigator",
            options: this.state.countryList,
            onSelect: this.onCountryChange
           
        },
        {
            label: "[EXT] Phone Number Co-Investigator",
            type: "text",
            name: "phoneNumberCoInvestigator",
           
        },
        {
            label: "[EXT] Zip Code Co-Investigator",
            type: "text",
            name: "zipCodeCoInvestigator",
           
        },
        {
            label: "[EXT] Email Address Co-Investigator",
            type: "text",
            name: "emailAddressCoInvestigator",
           
        },
    ]
}
get inputFieldsArrayTargetDates() {
    return[
        
         {
            label: "Target Starting Date",
            type: "date",
            name: "targetStartingDate",
           
        },
        {
            label: "Target Completing Date",
            type: "date",
            name: "targetCompletingDate",
           
        },
        {
            label: "Specific Timing Requirements",
            type: "textarea",
            name: "specificTimingRequirements",
            helptext: "If you have any specific timing requirements for receiving approval and/or compound, please explain"
        },
    ]
}
get inputFieldsArrayStudyTitle() {
    return[
       
         {
            label: "[EXT] Study Title",
            type: "text",
            name: "studyTitle",
           
        },
    ]
}
get inputFieldsArrayPrimaryObjectives() {
    return[
        {
            type: "alert",
             label: "Primary Objective/Hypothesis",
             variant: "secondary"
         },
         {
            label: "[EXT] Primary Objective/Hypothesis",
            type: "textarea",
            name: "primaryObjectiveHypothesis",
            
        },
    ]
}
get inputFieldsArraySecondaryObjjectives() {
    return[
        
         {
            label: "[EXT] Secondary Objective",
            type: "textarea",
            name: "secondaryObjective",
            
        },
    ]
}
get inputFieldsArrayScientificRationale() {
    return[
       
         {
            label: "[EXT] Scientific Rationale",
            type: "textarea",
            name: "scientificRationale",
            
        },
    ]
}
get inputFieldsArrayMethods() {
    return[
       
         {
            label: "[EXT] Methods",
            type: "textarea",
            name: "methods",
           
        },
    ]
}
get inputFieldsArrayTestDetail() {
    return[
      
         {
            label: "[EXT] Assay Name",
            type: "text",
            name: "assayName",
           
        },
         {
            label: "[EXT] Assay Method",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "assayMethod",
            options: [
                "None",
                "Yes",
                "No"
            ]
            
        }, 
        {
            label: "[EXT] Please describe if Other",
            type: "textarea",
            name: "pleaseDescribeIfOther1",
           
        },
        {
            label: "[EXT] Assay Regulatory Status",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "assayRegulatoryStatus",
            options: [
                "None",
                "FDA Cleared",
                "CE Marked",
                "Laboratory Developed Test"
            ]
            
        }, 
        {
            label: "[EXT] Regulatory Approved",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "regulatoryApproved",
            options: [
                "None",
                "Yes",
                "No",
            ]
        },
        {
            label: "[EXT] Evidence of assay validation",
           type: "textarea",
            name: "evidenceOfAssayValidation",
        },
        {
            label: "[EXT] Platform",
           type: "text",
            name: "platform",
        },
        {
            label: "[EXT] Sample Description",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "sampleDescription",
            options: [
                "None",
                "CSF",
                "Plasma",
                "Cell Free DNA/RNA",
                "Serum",
                "Tumor FFPE",
                "Tumor-FreshFrozen",
                "Rare Cell",
                "Other"
            ]
        },
        {
            label: "[EXT] Please describe if Other",
            type: "textarea",
            name: "pleaseDescribeIfOther2",
           
        },
        {
            label: "[EXT] Tumor Type",
                type: "multi-select",
                placeholder: "Select Tumor Type",
                name: "tumorType",
                options: INDICATIONS,
        },
        {
            label: "[EXT] Study Type",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "studyType",
            options: [
                "None",
                "Validation",
                "Assay Comparison",
                "Epidemiology",
                "Diagnostic Workflows/Algorithms",
                "Other"
            ]
            
        }, 
        {
            label: "[EXT] Please describe if Other",
            type: "textarea",
            name: "pleaseDescribeIfOther3",
           
        },
        {
            label: "[EXT] Testing Timing",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "testingTiming",
            options: [
                "None",
                "Prospective",
                "Retrospective",
                "Prospective and Retrospective",
                
            ]
            
        }, 
    ]
}
get inputFieldsArrayOperationalInformation() {
    return[
      
         {
            label: "[EXT] Source of samples",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "sourceOfSamples",
            options: [
                "None",
                "Biobank",
                "Collected specially for this study"
            ]                
        }, 
        {
            label: "[EXT] Unique, Personal, Clinical samples",
            type: "dropdown",
            name: "uniquePersonalClinicalsamples",
            options: [
                "None",
                "Yes",
                "No"
            ]                
        }, 
        {
            label: "[EXT] Biobank owner",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "biobankowner",
            options: [
                "None",
                "Biobank",
                "Third Party"
            ]                
        }, 
        {
            label: "[EXT] Lab External Quality Assessment Program",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "labExternalQualityAssessmentProgram",
            options: [
                "None",
                "Yes",
                "No"
            ],
            helptext: "Does the performing laboratory participate in an external quality assessment program?"               
        }, 
        {
            label: "[EXT] Is Lab CLIA/ISO certified",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "iSLabCLIAISOcertified",
            options: [
                "None",
                "Yes",
                "No",
               "Not Applicable"                   
            ],
            helptext: "Is the laboratory CLIA/ISO certified?"                         
        },
        {
            label: "[EXT] Laboratory's certifications Description",
            type: "textarea",
            name: "laboratoryCertificationsDescription",
           
        },

    ]
}
get inputFieldsArrayTimeline() {
    return[
       
         {
            label: "[EXT] Projected study length",
            type: "number",
            name: "projectedStudyLength",
           
        },
        {
            label: "[EXT] Projected Study Start Date",
            type: "date",
            name: "projectedStudyStartDate",
           
        },
        {
            label: "[EXT] Projected Study Complete Date",
            type: "date",
            name: "projectedStudyCompleteDate",
           
        },
        {
            label: "[EXT] Projected timing for key study milestone",
            type: "text",
            name: "projectedTimingForKeyStudyMilestone",
           
        },
        {
            label: "[EXT] Projected date for study report submission",
            type: "date",
            name: "projectedDateForStudyReportSubmission",
           
        },
        {
            label: "[EXT] Projected date for publication",
            type: "date",
            name: "projectedDateForPublication",
           
        },
        {
            label: "[EXT] Other helpful project timeline information",
            type: "textarea",
            name: "otherHelpfulProjectTimelineInformation",
           
        },
    ]
}
get inputFieldsArrayResearchFunding() {
    return[
      
         {
            label: "[EXT] Requesting Research Funding",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requestingResearchFunding",
            options: [
                "None",
                "Yes",
                "No"
            ]
            
        },
    ]
}
get inputFieldsArrayStudyReport(){
    return[
       
         {
            label: "[EXT] Final study report",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "finalStudyReport",
            options: [
                "None",
                "Yes",
                "No"
            ]
            
        }, 
        {
            label: "[EXT] Study Report Request explain",
            type: "textarea",
            name: "studyReportRequestExplain",
           
        },

        //  *************
      

    ]
}
get inputFieldsArrayBriefDescription() {
    return[
        
         {
            label: "[EXT] Publication Strategy",
            type: "textarea",
            name: "publicationStrategy",
           
        },
        // **************
       
    ]
}
get inputFieldsArrayRawData(){
    return[
       
         {
            label: "[EXT] Raw Data Requested",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "rawDataRequested",
            options: [
                "None",
                "Yes",
                "No"
            ]
            
           
        }, 
        {
            label: "[EXT] Raw Data If Requested Explain",
            type: "textarea",
            name: "rawDataIfRequestedExplain",
           
        }, 
    ]
}
    get inputFieldsArrayReferences() {
        return [
       
          
             {
                label: "[EXT] References",
                type: "textarea",
                name: "references",
               
            },
        ];
    }  
    getErrorDescriptions=()=>{

        let {error,proposallist}=this.state;
    
        let allFields=[...this.inputFieldsArrayRawData,
            ...this.inputFieldsArrayReferences,
         ...this.inputFieldsArrayBriefDescription,
        ...this.inputFieldsArrayStudyReport,
    ...this.inputFieldsArrayResearchFunding,
...this.inputFieldsArrayTimeline,...this.inputFieldsArrayOperationalInformation,...this.inputFieldsArrayTestDetail,
    ...this.inputFieldsArrayMethods,...this.inputFieldsArrayScientificRationale,
    ...this.inputFieldsArraySecondaryObjjectives,
    ...this.inputFieldsArrayStudyTitle,...this.inputFieldsArrayTargetDates,...this.inputFieldsArrayCoInvestigator,
    ...this.inputFieldsArrayLegalRepresentative,...this.inputFieldsArrayInformation];
    
        let messages = [];
    
        for (let [key, value] of Object.entries(error)) {
    
            let exist = allFields.find(p => p.name === key);
    
            if (exist) {
    
                let messageText=exist.label;
                if(error["lengthValidation"][key]){
                    messageText=messageText+' - Maximum Length should be less than '+proposallist[key]+" chars"
                }
                messages.push(messageText);
    
            }
    
        }
    
        return <ul>
    
            {messages&&messages.map((item,index)=>{
    
                return <li>{item}</li>
    
            })}
    
        </ul>}

   async componentDidMount() {
        let res=await API.get('reviewConcept/schemaInformation/diagnostic_proposals')

        console.log(res)

        this.setState({

            proposallist:res.data

        })
        this.getCountryList();     
    }

   
    getCountryList = async () => {
        const res = await API.get("seed/country/data");
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({countryList: resultArray});
            
    }

    getStateList = async (country, name) => {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({[name]: resultArray});
    }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [name]: e.value},
        });
        if(name === "countryLegal")
            this.getStateList(e.name,"stateListLegal")
        else
            this.getStateList(e.name,"stateListCoinvestigator")
    }

  
    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]:dateChange(e) },
            });
        }
        else 
        { this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.value},
        });}
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join(";") : e},
        });        
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [name]: e.value},
        }); 
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject:  {...this.state.patchObject, [e.target.name]: e.target.checked},
        });
    }

    getHtmlFromEditorValue = (val) => {
        const tobesaved = convertToRaw(val.getCurrentContent());
        return stateToHTML(convertFromRaw(tobesaved));
    }

    getEditorValueFromHtml = (html) => {
        const blocksFromHTML = convertFromHTML(html);        
        const state = ContentState.createFromBlockArray(blocksFromHTML);
        return EditorState.createWithContent(state);
    }


    onEditorChange = (val, e) => {
        this.setState({
            [e]: val,
            patchObject: {...this.state.patchObject, [e]: this.getHtmlFromEditorValue(val)}
        });
    };

    renderInputFieldsInformation = () => {
        return this.inputFieldsArrayInformation.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsLegalRepresentative = () => {
        return this.inputFieldsArrayLegalRepresentative.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsCoInvestigator = () => {
        return this.inputFieldsArrayCoInvestigator.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsTargetDates = () => {
        return this.inputFieldsArrayTargetDates.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsStudyTitle = () => {
        return this.inputFieldsArrayStudyTitle.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsPrimaryObjectives = () => {
        return this.inputFieldsArrayPrimaryObjectives.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsSecondaryObjectives = () => {
        return this.inputFieldsArraySecondaryObjjectives.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsScientificRationale = () => {
        return this.inputFieldsArrayScientificRationale.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsMethods = () => {
        return this.inputFieldsArrayMethods.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsTestDetail = () => {
        return this.inputFieldsArrayTestDetail.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsOperationalInformation = () => {
        return this.inputFieldsArrayOperationalInformation.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsTimeline = () => {
        return this.inputFieldsArrayTimeline.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsResearchFunding = () => {
        return this.inputFieldsArrayResearchFunding.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsStudyReport = () => {
        return this.inputFieldsArrayStudyReport.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsBriefDescription = () => {
        return this.inputFieldsArrayBriefDescription.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    renderInputFieldsRawData = () => {
        return this.inputFieldsArrayRawData.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
     renderInputFieldsReferences = () => {
        return this.inputFieldsArrayReferences.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.name || field.label }><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.name || field.label}>
                <InputField 
                    {...field } 
                    
                    value = {this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? field.onChange || ((e)=>this.onInputChange(e,field)): this.checkboxChange}
                />
            </Col>
            )
        });            
    }
    formValidation = () => {
        let is_valid = true;
       
        let{proposallist}=this.state;
        
        const obj = ({ ...this.state.patchObject });
        let error={};
        error["lengthValidation"]={};
        for (let [key, value] of Object.entries(proposallist)) {
            if(value){
                if(this.state[key]&&this.state[key].length>value){
                    is_valid = false;
                    error[key] = true;
                    error["lengthValidation"][key]=true;
                }
            }
        }
        this.setState({error})

        return is_valid;
    }
    submitForm = async () => {
        if(this.formValidation())
        try {
            const res = await API.post("diagnosticProposal/add", {"proposal": {...this.state.patchObject, createdByID: this.context.userSystemId}, "submission" : {
                id : this.props.submissionId
                // stage :"Proposal"                
            }});
            this.setState({successModal: true});
        }catch(err){
            this.setState({errorModal: true});
      }else{
        this.setState({errorModal:true})
     }
     
     }
     clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    onHide=()=>{
        let {onClose}=this.props;
        if(onClose){
            
            this.clearFormData(onClose);
            
        }
    }
     changeShowState = () => {
        let {onClose}=this.props;
        if(!this.state.errorModal)
        //this.props.history.push(`/submissions/${this.props.submissionId}`)
        this.clearFormData(onClose,true);
        this.setState({errorModal: false});
    }

    onNavOut = () => {
        this.props.history.push(`/submissions/${this.props.submissionId}`)
    }
    handleStartClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth',block:'center' })}
    handleBackClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth' })}
    render() {
        let {modalProps,formData,fileInfo,isIUDOperation}=this.state;
        let loaderConfig={};
        if(isIUDOperation){
             loaderConfig={disabled:"disabled"};
        }
        console.log(this.props.submissionId)
        return (
            <Container className="new-flow-modal">
                <Modal className="new-flow-modal"
      show   
      size="lg"
      dialogClassName="lg-xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

    <Modal.Header style={{border:0}}>
        <Modal.Title id="contained-modal-title-vcenter">
         <h5 className="font-weight-bold"> Add a New Diagnostic proposal</h5>
         <div style={{fontSize:'1rem'}}>
            <span>Please fill out this form to add a new Diagnostic proposal </span>
         </div>
        </Modal.Title>
      </Modal.Header><Modal.Body className="new-flow-modal">
                <Accordion defaultActiveKey="1" ref={this.titleRef}>
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                           Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsInformation()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={this.handleBackClick}>
                                           Legal Representative
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsLegalRepresentative()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={this.handleBackClick}>
                                        C. Co-Investigator(s) and Location
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsCoInvestigator()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="4" onClick={this.handleBackClick}>
                                        Target Dates for Starting and Completing Study
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsTargetDates()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="5" onClick={this.handleBackClick}>
                                        Study Title
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsStudyTitle()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="6" onClick={this.handleBackClick}>
                                        Primary Objective/Hypothesis
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsPrimaryObjectives()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="7" onClick={this.handleBackClick}>
                                        Secondary Objective(s)
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="7">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsSecondaryObjectives()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="8" onClick={this.handleBackClick}>
                                        Scientific Rationale (no more than 1 page; include references)
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="8">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsScientificRationale()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="9" onClick={this.handleBackClick}>
                                        Methods: Study Design including Endpoints and Statistical Analysis
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="9">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsMethods()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="10" onClick={this.handleStartClick} >
                                        Test Details
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="10">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsTestDetail()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="11" onClick={this.handleStartClick} >
                                          Operational Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="11">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsOperationalInformation()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="12" onClick={this.handleStartClick}>
                                        Timeline (target dates for starting and completing study)
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="12">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsTimeline()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="13" onClick={this.handleStartClick} >
                                        Research Funding Request:
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="13">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsResearchFunding()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="14" >
                                        Study Report Request
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="14">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsStudyReport()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="15" >
                                        Brief Description of Publication Strategy
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="15">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsBriefDescription()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="16" >
                                           Raw Data Request
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="16">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsRawData()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="17" >
                                        References
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="17">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsReferences()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                            <Row>
                       
                        {/* <div className="w-100 d-flex justify-content-center">
                        <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button>
                        </div> */}
                </Row>
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Diagnostic Proposal has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body={<><div>An error occured! Please try again later!</div>{this.getErrorDescriptions()}</>}
                        changeShowState={this.changeShowState}
                    />
                }</Modal.Body>
                 <Modal.Footer>
                    {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                    <div className="d-flex align-items-center justify-content-center w-100 p-2">
                        <IIRBtn.default {...loaderConfig} onClick={this.onHide} className="mr-2 p-2" style="danger w-100px">Cancel</IIRBtn.default>
                        <IIRBtn.default {...loaderConfig} onClick={this.submitForm} className="p-2 d-flex align-items-center justify-content-center" style="success w-100px"><i className="bi bi-plus" ></i><span>ADD</span></IIRBtn.default>
                    </div>
      </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}