import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DatatTableEx from '../DataTableEx';
import API from '../../../api/apis';
import DTAButton from '../Button/DTAButton';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
import UserContext from '../../../context/UserContext';
import Alert from 'react-bootstrap/Alert';
import swal from 'sweetalert';
import CountryModalEx from './CountryModalEx';
import BootstrapModal from '../../common/modal/BootstrapModal';
class CountryEx extends Component {
    static contextType = UserContext;
    state = {
        CountryList: [],
        is_Coordinator: false,
        access: false,
        filterSource: [],
        showLoader: false,
        showModal: false,
        editMode: null
    }
    columns = [
        {
            dataField: 'id',
            text: 'id',
            sort: true,
            excludeFilter: true,
            hidden: true,
            csvExport: false,
            formatter: (cell, row, rowIndex) => {
                return <p onClick={() => this.handleModal(true, "edit", row, rowIndex)}></p>
            }
        }, {
            dataField: 'name',
            text: 'Country',
            sort: true,
        },
    ]
    attachColumEvent = () => {
        this.columns.map((column, index) => {
            column.events = this.columnEvent;
        });
    }
    columnEvent = {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            if (column.dataField != 'actions') {
                this.handleModal(true, "edit", row, rowIndex)
            }

        }
    }
    componentDidMount() {
        this.attachColumEvent()
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator, access: _isCoordinator });
        this.getCountryList();
    }
    getCountryList = async () => {
        this.setState({
            showLoader: true,
        })
        const res = await API.get(`seed/Country/getall`);
        if (res &&res.data&& res.data.success) {
            this.setState({
                CountryList: res.data.data || [],
                showLoader: false
            })
        } else {
            this.setState({ errorModal: true })
        }

    }
    handleCountrySave = async (state) => {
        state.updated_by = null;
        state.created_by = this.context.userSystemId;
        // state.active = true;
        try {
            const res = await API.post("seed/country/edit/add", state);
            if (res && res.data && res.data.success) {
                this.setState({
                    success_body: "Created successfully!",
                    successModal: true,
                    hasChanged: false,
                    showModal: false,
                    editMode: null,
                });
                this.getCountryList();
            } else {
                this.setState({ errorModal: true });
            }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleCountryUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        //let temp={...state,compound_ids:state.compound_ids.join(",")}
        try {
            const res = await API.post("/seed/country/modify/update", state);
            if (res && res.data && res.data.success) {
                this.setState({
                    success_body: "Updated successfully!",
                    successModal: true,
                    hasChanged: false,
                    showModal: false,
                    editMode: null,
                });
                this.getCountryList();
            } else {
                this.setState({ errorModal: true });
            }

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleModal = async (flag, editMode, row) => {

        this.setState({ showModal: flag, editMode, rowId: row.id });

    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
        this.getCountryList();
    }
    render() {
        let { successModal, success_body, errorModal } = this.state;
        return <><div>
            <DatatTableEx
                autoFilter={true}
                filterChange={() => { }}
                csvName="Country_details"
                data={this.state.CountryList}
                title={<Alert.Heading>
                    <span><i class="bi bi-globe" style={{ fontSize: "1.8rem", margin: ".5rem", padding: ".1rem" }}></i></span>
                    Countries</Alert.Heading>} columns={this.columns}
                pageIcons={this.state.is_Coordinator && <DTAButton onClick={() => this.handleModal(true, null, {})} icon="ADD" text="Add Country"></DTAButton>}>
            </DatatTableEx>
            {this.state.showModal &&
                <CountryModalEx
                    rowId={this.state.rowId}
                    handleCountrySave={this.handleCountrySave}
                    handleCountryUpdate={this.handleCountryUpdate}
                    editMode={this.state.editMode}
                    showModal={this.state.showModal}
                    handleModal={this.handleModal} />}
        </div>
            {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            } </>

    }
}
export default withRouter(CountryEx);