import React, { Component } from 'react';

import { Col, Row } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import Loader from '../../common/loader/Loader';
import UserContext from '../../../context/UserContext';
import { dateTimeformat,dateStringformat,dateChange } from '../../../utility/helperFunctions';
import API from "../../../api/apis";


export default class Milestones extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        }, patchObject: {},
        proposalExist: true,
    }

    async componentDidUpdate(previousProps, previousState) {
        await this.checkProposalExist();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                access: {
                    view: true,
                    create: selectedRole.includes("administrator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("administrator"),
                },
            })
        }
    }
    async componentDidMount() {
        const { submissionDetails } = this.props;
        await this.checkProposalExist();
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create: selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },

            proposal_submitted_date: submissionDetails.proposal_submitted_date ? dateStringformat(submissionDetails.proposal_submitted_date) : "",
            interim_report_baseline: submissionDetails.interim_report_baseline ? dateStringformat(submissionDetails.interim_report_baseline) : "",
            proposal_approval_date: submissionDetails.proposal_approval_date ? dateStringformat(submissionDetails.proposal_approval_date) : "",
            interim_report_estimate: submissionDetails.interim_report_estimate ? dateStringformat(submissionDetails.interim_report_estimate) : "",
            protocol_received_baseline: submissionDetails.protocol_received_baseline ? dateStringformat(submissionDetails.protocol_received_baseline) : "",
            interim_report_actual: submissionDetails.interim_report_actual ? dateStringformat(submissionDetails.interim_report_actual) : "",
            protocol_received_estimate: submissionDetails.protocol_received_estimate ? dateStringformat(submissionDetails.protocol_received_estimate) : "",
            interim_report_2_baseline: submissionDetails.interim_report_2_baseline ? dateStringformat(submissionDetails.interim_report_2_baseline) : "",
            protocol_received_actual: submissionDetails.protocol_received_actual ? dateStringformat(submissionDetails.protocol_received_actual) : "",
            interim_report_2_estimate: submissionDetails.interim_report_2_estimate ? dateStringformat(submissionDetails.interim_report_2_estimate) : "",
            protocol_approved_baseline: submissionDetails.protocol_approved_baseline ? dateStringformat(submissionDetails.protocol_approved_baseline) : "",
            interim_report_2_actual: submissionDetails.interim_report_2_actual ? dateStringformat(submissionDetails.interim_report_2_actual) : "",
            protocol_approved_estimate: submissionDetails.protocol_approved_estimate ? dateStringformat(submissionDetails.protocol_approved_estimate) : "",
            first_contact_with_partner: submissionDetails.first_contact_with_partner ? dateStringformat(submissionDetails.first_contact_with_partner) : "",
            protocol_approved_actual: submissionDetails.protocol_approved_actual ? dateStringformat(submissionDetails.protocol_approved_actual) : "",
            collaborative_partner_approval: submissionDetails.collaborative_partner_approval ? dateStringformat(submissionDetails.collaborative_partner_approval) : "",
            budget_complete_baseline: submissionDetails.budget_complete_baseline ? dateStringformat(submissionDetails.budget_complete_baseline) : "",
            collaboration_kick_off_meeting: submissionDetails.collaboration_kick_off_meeting ? dateStringformat(submissionDetails.collaboration_kick_off_meeting) : "",
            budget_complete_estimate: submissionDetails.budget_complete_estimate ? dateStringformat(submissionDetails.budget_complete_estimate) : "",
            cross_reference_letters_due: submissionDetails.cross_reference_letters_due ? dateStringformat(submissionDetails.cross_reference_letters_due) : "",
            budget_complete_actual: submissionDetails.budget_complete_actual ? dateStringformat(submissionDetails.budget_complete_actual) : "",
            cross_reference_letters_received: submissionDetails.cross_reference_letters_received ? dateStringformat(submissionDetails.cross_reference_letters_received) : "",
            contract_complete_baseline: submissionDetails.contract_complete_baseline ? dateStringformat(submissionDetails.contract_complete_baseline) : "",
            study_start_date_baseline:submissionDetails.study_start_date_baseline?dateStringformat(submissionDetails.study_start_date_baseline):"",
            study_start_date_estimate:submissionDetails.study_start_date_estimate?dateStringformat(submissionDetails.study_start_date_estimate):"",
            study_start_date_actual:submissionDetails.study_start_date_actual?dateStringformat(submissionDetails.study_start_date_actual):"",
            fpv_baseline: submissionDetails.fpv_baseline ? dateStringformat(submissionDetails.fpv_baseline) : "",
            contract_complete_estimate: submissionDetails.contract_complete_estimate ? dateStringformat(submissionDetails.contract_complete_estimate) : "",
            fpv_estimate: submissionDetails.fpv_estimate ? dateStringformat(submissionDetails.fpv_estimate) : "",
            contract_complete_actual: submissionDetails.contract_complete_actual ? dateStringformat(submissionDetails.contract_complete_actual) : "",
            fpv_actual: submissionDetails.fpv_actual ? dateStringformat(submissionDetails.fpv_actual) : "",
            initial_irb_approval_baseline: submissionDetails.initial_irb_approval_baseline ? dateStringformat(submissionDetails.initial_irb_approval_baseline) : "",
            fpet_baseline: submissionDetails.fpet_baseline ? dateStringformat(submissionDetails.fpet_baseline) : "",
            initial_irb_approval_estimate: submissionDetails.initial_irb_approval_estimate ? dateStringformat(submissionDetails.initial_irb_approval_estimate) : "",
            fpet_estimate: submissionDetails.fpet_estimate ? dateStringformat(submissionDetails.fpet_estimate) : "",
            initial_irb_approval_actual: submissionDetails.initial_irb_approval_actual ? dateStringformat(submissionDetails.initial_irb_approval_actual) : "",
            fpet_actual: submissionDetails.fpet_actual ? dateStringformat(submissionDetails.fpet_actual) : "",
            initial_ct_material_ship_baseline: submissionDetails.initial_ct_material_ship_baseline ? dateStringformat(submissionDetails.initial_ct_material_ship_baseline) : "",
            lpet_baseline: submissionDetails.lpet_baseline ? dateStringformat(submissionDetails.lpet_baseline) : "",
            initial_ct_material_ship_estimate: submissionDetails.initial_ct_material_ship_estimate ? dateStringformat(submissionDetails.initial_ct_material_ship_estimate) : "",
            lpet_estimate: submissionDetails.lpet_estimate ? dateStringformat(submissionDetails.lpet_estimate) : "",
            initial_ct_material_ship_actual: submissionDetails.initial_ct_material_ship_actual ? dateStringformat(submissionDetails.initial_ct_material_ship_actual) : "",
            lpet_actual: submissionDetails.lpet_actual ? dateStringformat(submissionDetails.lpet_actual) : "",
            ct_material_ship_2_estimate: submissionDetails.ct_material_ship_2_estimate ? dateStringformat(submissionDetails.ct_material_ship_2_estimate) : "",
            lpetx_baseline: submissionDetails.lpetx_baseline ? dateStringformat(submissionDetails.lpetx_baseline) : "",
            ct_material_ship_2_actual: submissionDetails.ct_material_ship_2_actual ? dateStringformat(submissionDetails.ct_material_ship_2_actual) : "",
            lpetx_estimate: submissionDetails.lpetx_estimate ? dateStringformat(submissionDetails.lpetx_estimate) : "",
            ct_material_ship_2_estimate: submissionDetails.ct_material_ship_2_estimate ? dateStringformat(submissionDetails.ct_material_ship_2_estimate) : "",
            lpetx_actual: submissionDetails.lpetx_actual ? dateStringformat(submissionDetails.lpetx_actual) : "",
            ct_material_ship_3_actual: submissionDetails.ct_material_ship_3_actual ? dateStringformat(submissionDetails.ct_material_ship_3_actual) : "",
            lpv_baseline: submissionDetails.lpv_baseline ? dateStringformat(submissionDetails.lpv_baseline) : "",
            ct_material_ship_4_estimate: submissionDetails.ct_material_ship_4_estimate ? dateStringformat(submissionDetails.ct_material_ship_4_estimate) : "",
            lpv_estimate: submissionDetails.lpv_estimate ? dateStringformat(submissionDetails.lpv_estimate) : "",
            ct_material_ship_4_actual: submissionDetails.ct_material_ship_4_actual ? dateStringformat(submissionDetails.ct_material_ship_4_actual) : "",
            lpv_actual: submissionDetails.lpv_actual ? dateStringformat(submissionDetails.lpv_actual) : "",
            ct_material_ship_5_estimate: submissionDetails.ct_material_ship_5_estimate ? dateStringformat(submissionDetails.ct_material_ship_5_estimate) : "",
            fsr_baseline: submissionDetails.fsr_baseline ? dateStringformat(submissionDetails.fsr_baseline) : "",
            ct_material_ship_5_actual: submissionDetails.ct_material_ship_5_actual ? dateStringformat(submissionDetails.ct_material_ship_5_actual) : "",
            fsr_estimate: submissionDetails.fsr_estimate ? dateStringformat(submissionDetails.fsr_estimate) : "",
            contract_expiration_date: submissionDetails.contract_expiration_date ? dateStringformat(submissionDetails.contract_expiration_date) : "",
            fsr_actual: submissionDetails.fsr_actual ? dateStringformat(submissionDetails.fsr_actual) : "",
            // type_of_material_provided: submissionDetails.type_of_material_provided || "",
            milestone_comments: submissionDetails.milestone_comments || "",
            milestone_comments_external: submissionDetails.milestone_comments_external || ""
        })
    }

    async checkProposalExist() {
        const submission_id = this.props.submissionDetails.id;
        const {proposalExist} = this.state;
        try{
            const res = await API.get(`proposal/submittedExist/${submission_id}`);
            if(res && res.data && res.data.success){
                const proposalExistRes = res.data?.data?.is_proposal_submitted;
                if(typeof(proposalExistRes)==='boolean' && proposalExistRes!==proposalExist){
                    this.setState({
                        proposalExist: res.data?.data?.is_proposal_submitted
                    })
                }
            }else{   
                console.error('Failed to fetch checkProposalExist');
            }
        }catch(err){
            console.error('error in checkProposalExist: ',err);
        }
    }

    get inputFieldsArray() {
        return [
            {
                label: "Proposal Submitted",
                name: "proposal_submitted_date",
                type: "date",
                // disabled: true
                disabled: this.state.proposalExist
            },
            {
                label: "Interim Report (Baseline)",
                name: "interim_report_baseline",
                type: "date",
                onChange: this.onDateChange,
                helptext: "Initial date estimated for interim report"
            },
            {
                label: "Proposal Approval",
                name: "proposal_approval_date",
                type: "date"
            },
            {
                label: "Interim Report (Estimate)",
                name: "interim_report_estimate",
                type: "date",
                helptext: "Current estimated date for Interim Report"
            },
            {
                label: "Protocol Received (Baseline)",
                name: "protocol_received_baseline",
                type: "date",
                helptext: "Initial date estimated for receiving protocol"
            },
            {
                label: "Interim Report (Actual)",
                name: "interim_report_actual",
                type: "date",
                helptext: "Date of Interim Report"
            },
            {
                label: "Protocol Received (Estimate)",
                name: "protocol_received_estimate",
                type: "date",
                helptext: "Current estimated date for receiving protocol"
            },
            {
                label: "Interim Report 2 (Baseline)",
                name: "interim_report_2_baseline",
                type: "date",
                helptext: "Initial date estimated for Interim Report 2"
            },
            {
                label: "Protocol Received (Actual)",
                name: "protocol_received_actual",
                type: "date",
                helptext: "Date protocol received"
            },
            {
                label: "Interim Report 2 (Estimate)",
                name: "interim_report_2_estimate",
                type: "date",
                helptext: "Current estimated date for Interim Report 2"
            },
            {
                label: "Protocol Approved (Baseline)",
                name: "protocol_approved_baseline",
                type: "date",
                helptext: "Initial date estimated for Protocol Approval"
            },
            {
                label: "Interim Report 2 (Actual)",
                name: "interim_report_2_actual",
                type: "date",
                helptext: "Date of Interim Report 2"
            },
            {
                label: "Protocol Approved (Estimate)",
                name: "protocol_approved_estimate",
                type: "date",
                helptext: "Current estimated date for protocol approval "
            },
            {
                label: "First contact with partner",
                name: "first_contact_with_partner",
                type: "date"
            },
            {
                label: "Protocol Approved (Actual)",
                name: "protocol_approved_actual",
                type: "date",
                helptext: "Date protocol approved"
            },
            {
                label: "Collaborative Partner Approval",
                name: "collaborative_partner_approval",
                type: "date"
            },
            {
                label: "Budget Complete (Baseline)",
                name: "budget_complete_baseline",
                type: "date",
                helptext: "Initial date estimated for completed Budget"
            },
            {
                label: "Collaboration Kick Off Meeting",
                name: "collaboration_kick_off_meeting",
                type: "date"
            },
            {
                label: "Budget Complete (Estimate)",
                name: "budget_complete_estimate",
                type: "date",
                helptext: "Current estimated date for completed Budget"
            },
            {
                label: "Cross Reference Letters Due",
                name: "cross_reference_letters_due",
                type: "date"
            },
            {
                label: "Budget Complete (Actual)",
                name: "budget_complete_actual",
                type: "date",
                helptext: "Date budget completed"
            },
            {
                label: "Cross Reference Letters Received",
                name: "cross_reference_letters_received",
                type: "date"
            },
            {
                label: "Contract Complete (Baseline)",
                name: "contract_complete_baseline",
                type: "date",
                helptext: "Initial date estimated for completed Contract"
            },
            
            
            {
                label: "[EXT] FPV (Baseline)",
                name: "fpv_baseline",
                type: "date",
                helptext: "Initial date estimated for First Patient Visit"
            },
            {
                label: "Contract Complete (Estimate)",
                name: "contract_complete_estimate",
                type: "date",
                helptext: "Current estimated date for completed Contract"
            },
            {
                label: "[EXT] FPV (Estimate)",
                name: "fpv_estimate",
                type: "date",
                helptext: "Current estimated date for First Patient Visit"
            },
            {
                label: "Contract Complete (Actual)",
                name: "contract_complete_actual",
                type: "date",
                helptext: "Date Contract completed"
            },
            {
                label: "[EXT] FPV (Actual)",
                name: "fpv_actual",
                type: "date",
                helptext: "Date of First Patient Visit"
            },
            {
                label: "Study Start Date (Baseline)",
                name: "study_start_date_baseline",
                type: "date",
                
            },
            {
                label: "[EXT] FPET (Baseline)",
                name: "fpet_baseline",
                type: "date",
                helptext: "Initial date estimated for First Patient Enter Trial"
            },
            {
                label: "Study Start Date (Estimated)",
                name: "study_start_date_estimate",
                type: "date",
            },
            {
                label: "[EXT] FPET (Estimate)",
                name: "fpet_estimate",
                type: "date",
                helptext: "Current estimated date for First Patient Enter Trial"
            },
            {
                label: "Study Start Date (Actual)",
                name: "study_start_date_actual",
                type: "date",
            },
            {
                label: "[EXT] FPET (Actual)",
                name: "fpet_actual",
                type: "date",
                helptext: "Date of First Patient Enter Trial"
            },
            {
                label: "Initial IRB Approval (Baseline)",
                name: "initial_irb_approval_baseline",
                type: "date",
                helptext: "Initial date estimated for IRB Approval"
            },
            {
                label: "[EXT] LPET (Baseline)",
                name: "lpet_baseline",
                type: "date",
                helptext: "Initial date estimated for Last Patient Enter Trial"
            },
            {
                label: "Initial IRB Approval (Estimate)",
                name: "initial_irb_approval_estimate",
                type: "date",
                helptext: "Current estimated date for IRB approval"
            },
            {
                label: "[EXT] LPET (Estimate)",
                name: "lpet_estimate",
                type: "date",
                helptext: "Current estimated date for Last Patient Enter Trial"
            },
            {
                label: "Initial IRB Approval (Actual)",
                name: "initial_irb_approval_actual",
                type: "date",
                helptext: "Date of receiving initial IRB/EC approval"
            },
            {
                label: "[EXT] LPET (Actual)",
                name: "lpet_actual",
                type: "date",
                helptext: "Date of Last Patient Enter Visit"
            },
            {
                label: "Initial CT Material Ship (Baseline)",
                name: "initial_ct_material_ship_baseline",
                type: "date",
                helptext: "Initial date estimated for shipping CT Material"
            },
            {
                label: "[EXT] LPETx (Baseline)",
                name: "lpetx_baseline",
                type: "date",
                helptext: "Initial date estimated for Last Patient Enter Treatment"
            },
            {
                label: "Initial CT Material Ship (Estimate)",
                name: "initial_ct_material_ship_estimate",
                type: "date",
                helptext: "Current estimated date for shipping CT Material"
            },
            {
                label: "[EXT] LPETx (Estimate)",
                name: "lpetx_estimate",
                type: "date",
                helptext: "Current estimated date for Last Patient Enter Treatment"
            },
            {
                label: "Initial CT Material Ship (Actual)",
                name: "initial_ct_material_ship_actual",
                type: "date",
                helptext: "Date of initial CT Material Shipment"
            },
            {
                label: "[EXT] LPETx (Actual)",
                name: "lpetx_actual",
                type: "date",
                helptext: "Date of Last Patient Enter Treatment"
            },
            {
                label: "CT Material Ship 2 (Estimate) ",
                name: "ct_material_ship_2_estimate",
                type: "date"
            },
            {
                label: "[EXT] LPV (Baseline)",
                name: "lpv_baseline",
                type: "date",
                helptext: "Initial date estimated for Last Patient Visit"
            },
            {
                label: "CT Material Ship 2 (Actual)",
                name: "ct_material_ship_2_actual",
                type: "date"
            },
            {
                label: "[EXT] LPV (Estimate)",
                name: "lpv_estimate",
                type: "date",
                helptext: "Current estimated date for Last Patient Visit"
            },
            {
                label: "CT Material Ship 3 (Estimate) ",
                name: "ct_material_ship_2_estimate",
                type: "date"
            },
            {
                label: "[EXT] LPV (Actual)",
                name: "lpv_actual",
                type: "date",
                helptext: "Date of Last Patient Visit"
            },
            {
                label: "CT Material Ship 3 (Actual)",
                name: "ct_material_ship_3_actual",
                type: "date"
            },
            {
                label: "[EXT] FSR (Baseline)",
                name: "fsr_baseline",
                type: "date",
                helptext: "Initial date estimated for Final Study Report"
            },
            {
                label: "CT Material Ship 4 (Estimate) ",
                name: "ct_material_ship_4_estimate",
                type: "date"
            },
            {
                label: "[EXT] FSR (Estimate)",
                name: "fsr_estimate",
                type: "date",
                helptext: "Current estimated date for Final Study Report"
            },
            {
                label: "CT Material Ship 4 (Actual)",
                name: "ct_material_ship_4_actual",
                type: "date"
            },
            {
                label: "FSR (Actual)",
                name: "fsr_actual",
                type: "date",
                helptext: "Date of Final Study Report"
            },
            {
                label: "CT Material Ship 5 (Estimate) ",
                name: "ct_material_ship_5_estimate",
                type: "date"
            },
            {
                label: "Internal Milestone Comments",
                name: "milestone_comments",
                type: "textarea"
            },
            {
                label: "CT Material Ship 5 (Actual)",
                name: "ct_material_ship_5_actual",
                type: "date"
            },
            { },
            {
                label: "Contract Expiration Date",
                name: "contract_expiration_date",
                type: "date"
            },
            {},
            // {
            //     label: "Type of Material Provided",
            //     name: "type_of_material_provided",
            //     type: "dropdown",
            //     options: [
            //         "None",
            //         "No drug",
            //         "Comm pack with aux sticker",
            //         "Investigational label"
            //     ]
            // },
            // { },
            {
                label: "[EXT] Milestone Comments",
                name: "milestone_comments_external",
                type: "textarea"
            }
        ]
    }

    onInputChange = (e, eo) => {
        
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]: e },
            });
        }
        else {
            let { name, value } = e.target;
            this.setState({
                [name]: value,
                patchObject: { ...this.state.patchObject, [e.target.name]: e.target.value },
            });
        }

        // if (value !== "")
        //     this.setState({ error: { ...this.state.error, [name]: false } });
    }
    onDateChange = (e, eo) => {
        console.log(eo)
        let { name, value } = eo;
        this.setState({
            [name]: e,
            [value]: e,
            patchObject: { ...this.state.patchObject, [eo.name]: e },
        });
    }
    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject: { ...this.state.patchObject, [eo.name || eo.target.name]: Array.isArray(e) ? e.map(obj => obj.label).join() : e },
            error: { ...this.state.error, [eo.name || eo.target.name]: false }
        });
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject: { ...this.state.patchObject, [e.target.name]: e.target.checked },

        })
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        disabled={field.disabled ? field.disabled : !this.state.access.edit}
                        value={this.state[field.name]??''}
                        // error={this.state.error[field.name]} 
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={((e) => { this.onInputChange(e, field) })}
                    />
                </Col>
            )
        });
    }

    /**
     * function use for send child state level data to parent Submit Function
     * @returns {Object}
     */
    milestonesData = () => {
        const { submissionDetails } = this.props;
        const newObject = {};
        let obj = this.state.patchObject;
        // Object.keys(this.state).forEach((key) => {
        //     if (this.state[key] === "" || this.state[key] === null) {
        //         newObject[key] = null;
        //         return false;
        //     } else{
        //         if (key != "type_of_material_provided" && key != "milestone_comments" && key != "error") {
        //             newObject[key] = new Date(this.state[key]).toISOString();
        //         } else{
        //             newObject[key] = this.state[key];
        //         }
        //     }
        // });
        Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null) {
                newObject[key] = null;
            } else{
                        if (key !== "type_of_material_provided" && key !== "milestone_comments" && key !== "milestone_comments_external" ) {
                            newObject[key] = dateChange(obj[key]);
                        } else{
                            newObject[key] = obj[key];
                        }
                    }
        });
        this.state.patchObject = {};
        delete newObject.error;
        return newObject;
    }

    render() {
        if (!this.props.submissionDetails.id)
            return <Loader />;

        return (
            <Row>
                {this.renderInputFields()}
            </Row>
        )
    }
}
