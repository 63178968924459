import React, { Component } from 'react';
import API from '../../../api/apis';
import { Col, Row, Card, Modal } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import LoaderEx from '../loader/LoaderEx';
import UserContext from '../../../context/UserContext';
import Button from '../../common/Button';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
import { dateTimeformat } from '../../../utility/helperFunctions';
import StateEx from './StateEx';
import CompoundEx2 from './CompoundEx2';
import SwitchView from '../SwitchView';
import { switchEnum } from '../SwitchView/switchEnum';
import SwitchHistory from '../SwitchHistory';
import { OBJ_TYPES } from '../SwitchHistory/historyConsts';
class CountryModalEx extends React.Component {
    static contextType = UserContext;
    state = {
        showLoader: true,
        countryData: {},
        name: "",
        hasChanged: false,
        id: this.props.rowId,
        currencyList: [],
        compound_ids: [],
        compound_deleted_ids: [],
        countrycode: "",
        active: false,
        currency: "",
        compoundList: [],
        states: [],
        viewDetails: true
    }
    get inputFields() {
        return [
            {
                label: "Country Name *",
                type: "text",
                name: "name",
                disabled: !this.state.is_Coordinator
            },
            {
                label: "Country Code *",
                type: "text",
                name: "countrycode",

            },
            {
                label: "Submit a Concept Active",
                type: "checkbox",
                name: "active"
            },
            {
                label: "Currency*",
                type: "searchableDropdown",
                placeholder: "Select Currency",
                name: "currency",
                options: this.state.currencyList,
                onSelect: this.onSearchSelect1
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                disabled: true,
                edit: true

            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                disabled: true,
                edit: true

            }
        ];
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    getCurrencyList = async () => {
        try {
            const currencyList = await API.get(`seed/currency/data`);

            if (currencyList.data.success) {
                this.setState({
                    currencyList: currencyList.data.data,
                    showLoader: false
                })
            }
            else {
                this.setState({
                    currencyList:[]
                })
            }
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    getCompoundList = async (e) => {

        const res = await API.get(`/seed/compound/fulldata`)

        const resultArray = res.data.data.map(elm => ({ name: elm.name, value: elm.id }))


        this.setState(
            {
                compoundList: resultArray

            }
        );
    }
    onInputChange = (e) => {
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }
    async componentDidMount() {

        await this.getCurrencyList();
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator, access: _isCoordinator });
        if (this.props.editMode === 'edit') {
            await this.getCompoundList(this.props.rowId);
            let CountryData = await this.getCountryById(this.props.rowId);
            if (CountryData) {
                CountryData["currency"] = this.state.currencyList.filter(item => item.id === CountryData.currency_id)[0];
                CountryData["createdby"] = CountryData.created_by?.length ? CountryData.created_name + "  " + dateTimeformat(CountryData.createdAt) : "";

                CountryData["updatedby"] = CountryData.updated_by?.length > 0 ? CountryData.updated_name + "  " + dateTimeformat(CountryData.updatedAt) : CountryData.createdby;

                let { name, createdby, updatedby, currency, countrycode, active } = CountryData;
                this.setState({ name, createdby, updatedby, currency, countrycode, active, countryData: CountryData, showLoader: false })
            }
        }
        else {
            this.setState({
                showLoader: false,

            })
        }

    }

    getCountryById = async (id) => {

        try {

            const res = await API.get(`/seed/getCompoundbyCountry/modify/${id}`);

            if (res.data.success) {

                return res.data.data || null;

            } else {

                this.setState({

                    error: true,

                    showLoader: false

                });

            }

        } catch (e) {

            console.log(e);

            this.setState({

                error: true,

                showLoader: false

            });

        }

        return null;

    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            name: this.state.name,
            countrycode: this.state.countrycode,
            currency: this.state.currency

        }
        const errors = {};
        let customError = {};
        const regex = new RegExp(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/);
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {


                errors[key] = true;
                is_valid = false;


            }
            if (regex.test(initialValues[key]) && (key === "name" || key === "countrycode")) {
                customError[key] = "Special characters are not allowed"
                is_valid = false;

            }
            else

                this.setState({
                    error: { ...errors },
                    customerror: { ...customError }
                });
        });

        return is_valid;

    }
    onSave = (validate,state) => {
       
        if (this.formValidation(state)) {
            if (validate === "new") {
                this.props.handleCountrySave(state)
            }
            else {
                state["compound_ids"]=state.compound_ids.join(",")
                //state.compound_ids=state.compound_ids.join(",");
                state["compound_deleted_ids"]=state.compound_deleted_ids.join(",")
                //console.log("compound2",this.state.compound_ids,this.state.compound_ids.join(","))
                this.props.handleCountryUpdate(state, this.props.rowId)
            }
        }

    }
    renderInputFields = () => {
        return this.inputFields.map(field => {
            if (field.type === "alert-new")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.editMode != "edit")
                return false;

            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        disabled={!this.state.is_Coordinator || field.disabled}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => { this.onInputChange(e, field) }) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    onSearchSelect1 = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            currency_id: e.id
        });
    }
    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    render() {
        const { showModal, handleModal, handleCountryUpdate, rowId } = this.props;
        const { viewDetails } = this.state;
        return (
            <div>
                <Modal show={true} size="lg" className='new-flow-modal'>
                    {<div> <Modal.Header className='border-0' >
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h5 className='p-0 m-0'><strong>{this.props.editMode === "edit" ? " Country" : "Add a Country"}</strong></h5>

                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons"
                                onClick={() => handleModal(false, null, {})} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                        <Modal.Body>
                            { rowId && 
                                <SwitchView
                                    viewMode={(value) => {
                                        if (value === switchEnum.DETAILS) {
                                            this.setState({ viewDetails: true });
                                        } else {
                                            this.setState({ viewDetails: false });
                                        }
                                    }}
                                />
                            }

                            { viewDetails ? <>
                                <Row>
                                    {this.state.showLoader === true ? <LoaderEx /> : this.renderInputFields()}
                                </Row>
                                {
                                    this.props.editMode === "edit" && <StateEx States={this.state.states} CountryData={this.state.countryData} />
                                }
                                {
                                    this.props.editMode === "edit" && <CompoundEx2 compound_deleted_ids={this.state.compound_deleted_ids} compound_ids={this.state.compound_ids} CompoundList={this.state.compoundList} CountryData={this.state.countryData} />
                                }
                            </> 
                            : <SwitchHistory 
                                    object_type={OBJ_TYPES.COUNTRY} 
                                    object_id={rowId} 
                                    showRowType={true}
                              />
                            }
                        </Modal.Body>
                        { viewDetails && <Modal.Footer className="d-flex align-items-center justify-content-center w-100 p-2 border-0" >
                            <Button style="danger w-100px" onClick={() => handleModal(false, null, {})} className="d-flex align-items-center p-2 justify-content-center"><span>CANCEL</span></Button>
                            {this.state.is_Coordinator ?
                                (this.props.editMode === "edit") ?
                                    <Button style="success w-100px" onClick={() => this.onSave("update",this.state)} className="d-flex align-items-center p-2 justify-content-center"><span>SAVE</span></Button> :
                                    <Button style="success w-100px" onClick={() => this.onSave("new",this.state)} className="d-flex align-items-center p-2 justify-content-center"><span>ADD</span></Button>
                                : <></>}



                        </Modal.Footer> } </div>}
                </Modal>
            </div>
        )
    }
}
export default CountryModalEx;