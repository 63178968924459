import React from 'react'
import {  Modal, Col, Row } from 'react-bootstrap';
import Button from '../../common/Button'
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { getUserInfo, dateTimeformat } from '../../../utility/helperFunctions';
import Loader from '../../common/loader/Loader';
import SwitchView from '../../common/SwitchView';
import { switchEnum } from '../../common/SwitchView/switchEnum';
import SwitchHistory from '../../common/SwitchHistory';
import { OBJ_TYPES } from '../../common/SwitchHistory/historyConsts';
const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ContactsModal extends React.Component {
    state = {
        compoundList: [],
        countryList: [],
        stateList: [],
        hasChanged: false,
        submission: `Submission- ${this.props.submissionId}`,
        submissionId: this.props.submissionId,
       isLoader:true,
        name: "",
       
        type: "",
        institutiion: "",
stateid:"",
        viewDetails: true

    }
    async componentDidMount() {
        await this.getCountryList();
      
        const { viewEditData, viewEditFlag } = this.props;
        viewEditData["createdby"] =viewEditData.created_by?.length ?  await getUserInfo(viewEditData.created_by) + "  " + dateTimeformat(viewEditData.createdAt): "";
        viewEditData["last_modifiedby"] =viewEditData.last_modified_by_id?.length > 0 ? await getUserInfo(viewEditData.last_modified_by_id) + "  " + dateTimeformat(viewEditData.updatedAt):viewEditData.createdby ;
       
        viewEditData["countryId"] = this.state.countryList.filter(item => item.value === viewEditData.country_id)[0];
        viewEditData["countryid"]= viewEditData["countryId"]?.name;
        await this.getStateList(viewEditData["countryid"]);
        viewEditData["stateid"] =this.state.stateList.filter(item => item.value === viewEditData.state_id)[0];
       viewEditData["submission"]= `Submission- ${this.props.submissionId}`;

        if (viewEditFlag === "edit") {
            this.setState({
                //set Your form state details here
                ...this.state,
                ...viewEditData,
                isLoader:false

            })
        }
        else{
            this.setState({
                isLoader:false
            })
           } 
    }

    get inputFieldsArray() {
        const { viewEditFlag } = this.props;
        return [ {
            label: "Submission *",
            name: "submission",
            type: "text",
            disabled: true
        },
        {
            label: "Street Address",
            type: "text",
            name: "street_address",
        }, {
            label: "Type *",
            type: "dropdown",
            placeholder: "Select Type",
            name: "type",
            options: [
                "None",
                "Additional Investigator",
                "Budget",
                "Contracting",
                "Legal",
                "Pharmacy Coordinator",
                "Regulatory",
                "Study Coordinator"
            ]
        },
        {
            label: "City",
            type: "text",
            name: "city",
        },
        {
            label: "Name *",
            type: "text",
            name: "name",
        },
        {
            label: "Country",
            type: "searchableDropdown",
            name: "country_id",
            options: this.state.countryList,
            onSelect: this.onCountryChange,
        },
        {
            label: "Institution *",
            name: "institutiion",
            type: "text"
        },
        {
            label: "State",
            type: "searchableDropdown",
            name: "stateid",
            
            options: this.state.stateList,
            onSelect: this.onSearchSelect,
           
        },
        {
            label: "Phone Number",
            type: "text",
            name: "phone_number",
        },
        {
            label: "Zip/Postal Code",
            name: "zip_postal_code__c",
            type: "text"
        }, {
            label: "Email ",
            name: "email",
            type: "text",
            customerror :this.state['email'] ? ((!regEmail.test(this.state['email'])) ? "Invalid Email" : "") : "" ,

        }, {
            label: "Last Modified By",
            type: "text",
            name: "last_modifiedby",
            className:"createUpdateByfontsize",
            disabled: true,
            edit: true
        }, {
            label: "Created By",
            name: "createdby",
            className:"createUpdateByfontsize",
            type: "text",
            disabled: true,
            edit: true
        }
        ];
    }
    getCountryList = async () => {
        const res = await API.get("seed/country/data");
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ countryList: resultArray });

    }

    getStateList = async (country) => {
        if (country) {
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id }));
        this.setState({ stateList: resultArray });
    }}

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            country_id: e.value

        });
        
            this.getStateList(e.name)

    }


    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }

    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
            state_id: e.value,
            // stateName:e.name
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                </Col>
            )
        });
    }
    formValidation = () => {
        let is_valid = true;
        const statecopy = { ...this.state };
                delete statecopy.compoundList;
                delete statecopy.stateList;
                delete statecopy.countryList;
                delete statecopy.hasChanged;
                delete statecopy.error;
             const   initialValues={
                   name:this.state.name,
                   type:this.state.type,
                   institutiion:this.state.institutiion,
                  
                  }
                  const errors = {}
                    if (this.state.email && (!regEmail.test(this.state['email'])) ){
                        is_valid = false;
                        
                    }
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null ) {
                        errors[key] = true;
                        is_valid = false;
                        
                    } 
                    
                });
                this.setState({
                    error:  { ...errors}
                });
        return is_valid;

    }
    onSave = () => {

      if(this.formValidation(this.state)){
        this.props.handleContactsSave(this.state);
      }
       
    }
    async helperhandleContactsUpdate(state){
        if(this.formValidation(this.state)){
            await this.props.handleContactsUpdate(state);
        }
    }
    render() {
        const { handleModalShowHide, showHideModal, handleContactsSave, viewEditFlag, handleContactsUpdate } = this.props;
        const { submissionId, viewDetails } = this.state;
        const contactId = this.props.viewEditData?.id;
        return (
            <div>
                <Modal show={showHideModal} className="new-flow-modal" size="lg">
                {this.state.isLoader=== true ?
        <Loader /> :  <div>  <Modal.Header className='border-0'closeButton onClick={() => handleModalShowHide(false, null, {})}>
                        <Modal.Title className="w-100 d-flex justify-content-center">
                          <h5 className='font-weight-bold'>  {viewEditFlag === "edit" ?
                                `Edit Additional Study Contact ` : "New Additional Study Contact"}</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { contactId && 
                            <SwitchView
                                viewMode={(value) => {
                                    if (value === switchEnum.DETAILS) {
                                        this.setState({ viewDetails: true });
                                    } else {
                                        this.setState({ viewDetails: false });
                                    }
                                }}
                            />
                        }
                        
                        { viewDetails ? 
                            <Row>
                                {this.renderInputFields()}
                            </Row>
                        : <SwitchHistory 
                            object_type={OBJ_TYPES.CONTACT} 
                            object_id={contactId} 
                          /> 
                        }
                    </Modal.Body>
                    { viewDetails && <Modal.Footer className="w-100 d-flex justify-content-center border-0">
                        <Button style="danger w-100px" className="d-flex align-items-center p-2 justify-content-center" onClick={() => handleModalShowHide(false, null, {})}>
                            Cancel
                        </Button>
                        {
                            viewEditFlag === "edit" ?
                                <Button style="success w-100px" className="d-flex align-items-center p-2 justify-content-center" onClick={() => this.helperhandleContactsUpdate(this.state)}>
                                    Save
                                </Button> : <Button style="success w-100px" className="d-flex align-items-center p-2 justify-content-center" onClick={this.onSave}>
                                    Save
                                </Button>
                        }

                    </Modal.Footer> } </div>}
                </Modal>
            </div>
        )
    }

}

export default ContactsModal;