import React, { Component } from 'react'
import { Col,Form, Row ,Modal} from 'react-bootstrap';
import InputField from './common/input-fields/InputField';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';
import UserContext from '../context/UserContext';
import { INDICATIONS } from '../consts';
import Button from "./common/Button";

export default class NewConceptEx extends Component {
    static contextType = UserContext;
    state = {
        investigatorName: "",
        country: "",
        primaryInstitution: "",
        therapeuticArea: "",
        interventional_study:"",
        request_for_lilly_trial_data:"",
        requesting_research_funding:"",
        vitro_vivo_study:"",
        assessing_utility_biomarkers:"",
        compound: "",
        indication: [],
        otherIndication: "",
        conceptTitle: "",
        conceptDescription: "",
        sosl_help_field: "",
        unique_submission_identifier: "",
        tumorIndication: "",
        error: {
            investigatorName: false,
            country: false,
            primaryInstitution: false,
            therapeuticArea: false,
            compound: false,
            indication: false,
            otherIndication: false,
            conceptTitle: false,
            conceptDescription: false,
            investigator_first_name: false,
            investigator_last_name: false,
            phone_number: false,
            email: false
        },
        trialCategory: "",
        compoundList: [],
        countryList: [],
        successModal: false,
        errorModal: false,
        investigator_middle_initial: "",
        investigator_first_name: "",
        investigator_last_name: "",
        phone_number: "",
        email: "",
        indication: [],
        organisationList: [],
    }

    get inputFieldsArray() {
        return [
            {
                label: "Investigator First Name *",
                type: "text",
                name: "investigator_first_name",
            },
            {
                label: "Investigator Middle Initial",
                type: "text",
                name: "investigator_middle_initial",
            },
            {
                //label:(this.state.selectedRole === "global_coordinator_industry_sponsored") &&((((this.state.requestType  === "Consortium Collaboration" ) || (this.state.requestType ==="Industry Sponsored")) &&((this.state.email ===  "")  || (this.state.email ===  null))))? "Investigator Last Name" : "Investigator Last Name *",
                label: "Investigator Last Name *",
                type: "text",
                name: "investigator_last_name",
            },
            {
                label: "[EXT] Country *",
                type: "searchableDropdown",
                placeholder: "Select Country",
                name: "country",
                options: this.state.countryList,
                onSelect: (e, eo) => { this.onCountryChange(e, eo) }
            },
            {
            
                label: "[EXT] Primary Institution *",
                type: "creatable",
                placeholder: "Enter Primary Institution",
                name: "primaryInstitution",
                options: this.state.organisationList,
                onSelect: this.onSearchSelect
            },
            {
                label: "[EXT] Secondary Institution",
                type: "text",
                placeholder: "Enter Secondary Institution",
                name: "secondary_institution_name",
            },
            {
              
                label: "Phone Number *",
                type: "text",
                placeholder: "Enter Phone Number",
                name: "phone_number",
            },
            {
                label: "Contact Email *",
                type: "text",
                placeholder: "Enter Contact Email",
                name: "email",
            },
            {
                label: "Is this request for an interventional study (i.e., involving human subjects)?",
                type: "radiobutton",
                options:["Yes","No"],
                name: "interventional_study",
            },
            {
                label: "Is this a request for Lilly trial data or samples?",
                type: "radiobutton",
                options:["Yes","No"],
                name: "request_for_lilly_trial_data",
            },
            {
                label: "Is this a pre-clinical/non-clinical request for an in vivo (i.e., mouse model) and/or in vitro (i.e., test tube/cell line) study?",
                type: "radiobutton",
                options:["Yes","No"],
                name: "vitro_vivo_study",
            },
            {
                label: "Will you be assessing the utility of diagnostic methods and/or predictive or prognostic biomarkers?",
                type: "radiobutton",
                options:["Yes","No"],
                name: "assessing_utility_biomarkers",
            },
            {
                label: "Are you requesting research funding?",
                type: "radiobutton",
                options:["Yes","No"],
                name: "requesting_research_funding",
            },
            {
                label: "",
                disabled:true
                
            },           
            {
                label: "[EXT] Therapeutic Area *",
                type: "dropdown",
                placeholder: "Select Therapeutic Area",
                name: "therapeuticArea",
                options: [
                    "None",
                    "Oncology",
                    "Diabetes & Obesity",
                    "Immunology",
                    "Neurodegenerative Diseases",
                    "Headache & Pain"
                ]
            },

            {
                label: "[EXT] Tumors",
                type: "multi-select",
                placeholder: "Select Tumor",
                name: "indication",
                options: INDICATIONS,
                disabled: this.state.therapeuticArea !== "Oncology"
            },
            {
                label: "[EXT] Other Tumors *",
                type: "text",
                placeholder: "Enter Other Tumors",
                name: "tumorIndication",
                display: JSON.stringify(this.state.indication).includes("Other")
            },
            {
                label: "[EXT] Compound *",
                type: "searchableDropdown",
                placeholder: "Select Compound",
                name: "compound",
                options: this.state.compoundList,
                onSelect: this.onSearchSelect
            },

            {
                label: "[EXT] Concept Title *",
                type: "textarea",
                placeholder: "Concept Title",
                name: "conceptTitle",
            },
            {
                label: "[EXT] Other Indication",
                type: "text",
                placeholder: "Enter Other Indication",
                name: "otherIndication",
            },
            {
                label: "[EXT] Concept Description *",
                type: "textarea",
                maxLength: 1000,
                placeholder: "Enter Concept Description",
                name: "conceptDescription",
                helptext: "Maximum length allowed upto 1000 characters"
            },

        ];
    }

    componentDidMount() {
        this.getCountryList();
        this.getOrganizationlist();
    }
    getOrganizationlist = async () => {

        const res = await API.get("/seed/organisation/data")
        const resultArray = (res.data?.data || [] ).map(elm => ({ label: elm.name, value: elm.name }));
        this.setState({ organisationList: resultArray });
    }
    submitForm = async () => {
        if (this.formValidation(this.state)) {
            let date = new Date();
            // if (this.state.requestType) {
            //     switch (this.state.requestType) {
            //         case "Pre-clinical":
            //             this.state.trialCategory = "Preclinical/Research";
            //             this.state.unique_submission_identifier = "Concept-PRE";
            //             break;
            //         case "Diagnostic Research":
            //             this.state.trialCategory = "Diagnostic Research";
            //             this.state.unique_submission_identifier = "Concept-DGN";
            //             break;
            //         case "Translational Research":
            //             this.state.trialCategory = "Translational Research";
            //             this.state.unique_submission_identifier = "Concept-TR";
            //             break;
            //         default:
            //             this.state.trialCategory = "Clinical Trial";
            //             this.state.unique_submission_identifier = "Concept-" + 'IIT';
            //     }
            // }
            const conceptObj = {
                concept: {
                    "interventional_study":this.state.interventional_study,
                    "request_for_lilly_trial_data":this.state.request_for_lilly_trial_data,
                    "vitro_vivo_study":this.state.vitro_vivo_study,
                    "assessing_utility_biomarkers":this.state.assessing_utility_biomarkers,
                    "investigatorname": this.state.investigator_first_name + " " + this.state.investigator_middle_initial + " " + this.state.investigator_last_name,
                    "country": this.state.country.value,
                    "primary_institution": this.state.primaryInstitution.value,
                    //"request_type": this.state.requestType,
                    "therapeutic_area": this.state.therapeuticArea,
                    "compound_id": this.state.compound.value,
                    "indication": this.state.therapeuticArea === "Oncology" &&this.state.indication.length? this.state.indication.map(obj => obj.value).join(";") : null,
                    "other_indication": this.state.tumorIndication,
                    "title": this.state.conceptTitle,
                    "description": this.state.conceptDescription,
                    "created_by": this.context.userSystemId,
                    "updated_by": null
                },
                investigator: {
                    "investigator_first_name": this.state.investigator_first_name,
                    "investigator_middle_initial": this.state.investigator_middle_initial,
                    "investigator_last_name": this.state.investigator_last_name,
                    "email": this.state.email,
                },
                submission: {
                    "secondary_institution_name": this.state.secondary_institution_name,
                    "investigator_name": this.state.investigator_first_name + " " + this.state.investigator_middle_initial + " " + this.state.investigator_last_name,
                    "stage": "Concept",
                    "requesting_research_funding":this.state.requesting_research_funding,
                    "trial_category": this.state.trialCategory,
                    "status": "None",
                    "email": this.state.email,
                    "phone_number": this.state.phone_number,
                    "indication": this.state.otherIndication,
                    "unique_submission_identifier": this.state.unique_submission_identifier + "-" + this.state.compound.name + "-" + this.state.country.countrycode,
                    "created_by": this.context.userSystemId,
                    "sosl_help_field": this.state.therapeuticArea + " " + this.state.country.countrycode + " " + this.state.compound.name + " " + this.state.unique_submission_identifier,

                    
                    "no_confidential_information": true,
                    "intellectual_property_rights": true,
                    "no_sensitive_personal_information": true,
                    "accept_privacy_statement": true,
                    "no_confidential_info_attested_date": date,
                    "accept_privacy_statement_attested_date": date,
                    "intellectual_property_attested_date": date,
                    "no_sensitive_pi_attested_date": date,
                }

               
            }
            
            try {
                const res = await API.post("submission/add", conceptObj);
                if(res.data.success){
                this.setState({ successModal: true })}
                else{this.setState({ errorModal: true });}
                
            } catch (err) {
                this.setState({ errorModal: true });
            }
        }
    }

    getCountryList = async () => {
        const res = await API.get("seed/country/getall")
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id, countrycode: elm.countrycode }));
        this.setState({ countryList: resultArray });
    }

    getCompoundList = async (e) => {
        const res = await API.get(`seed/getCompoundbyCountryNotRetired/${e}`)
        const arr = (res.data?.data || [] ).compounds?.filter(elm => elm.therapeutic_area === this.state.therapeuticArea);
        const resultArray = arr?.map(elm => ({ name: (elm.name === "Other" ? `${elm.name}  | ${elm.therapeutic_area}` : `${elm.name}`), value: elm.Country_Compound.compoundId }));
        this.setState({ compoundList: resultArray });
    }

    onCountryChange = (e, name) => {
        this.setState({ [name]: e });
        this.setState({
            compound: "",
            indication: [],
            otherIndication: "",
            error: { ...this.state.error, country: false }
        });
        this.getCompoundList(e.value);
    }

    onInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value !== "")
            this.setState({ error: { ...this.state.error, [e.target.name]: false } });
    }

    onSelectChange = (e, eo) => {
        if ((eo.name || eo.target.name) === "therapeuticArea") {
            this.setState({ [eo.target.name]: e, error: { ...this.state.error, [eo.target.name]: false } })
            this.getCompoundList(this.state.country.value);
        }
        else {
            this.setState({
                [eo.name || eo.target.name]: e,
                error: { ...this.state.error, [eo.name || eo.target.name]: false }
            });
        }
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            error: { ...this.state.error, [name]: false }
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
          if (field.type === "radiobutton") {
            let val=this.state[field.name]
            let labelEx= field.name === 'request_for_lilly_trial_data' ? 
                <>Is this a request for <u>Lilly trial data or samples</u>?</> 
                : field.label;
            
            return (
                <Col sm={6} style={{left:"0.5rem",marginBottom:"2rem"}} key={field.label}>
                    <label>{labelEx}</label>
                    <div>
                    {field.options.map((item,index)=>{
                        return <Form.Check name={field.name} checked={val===item} value={item} id={`inline-radio-${field.name}-${index}`} key={`inline-radio-${field.name}-${index}`}  onChange={this.onInputChange}   inline  label={item}  type="radio"  />
                    })}
                    </div>
                   
                 </Col> 
    
                
            );
        }else{
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={this.onInputChange}
                    />
                     
                </Col>
            )
    }});
    }

    formValidation = () => {
        let is_valid = true;
        const errors = {}
        let initialValues = {};
            initialValues = {

                country: this.state.country,
                primaryInstitution: this.state.primaryInstitution,
                //requestType: this.state.requestType,
                therapeuticArea: this.state.therapeuticArea,
                compound: this.state.compound,
                conceptDescription: this.state.conceptDescription,
                conceptTitle: this.state.conceptTitle,
                investigator_first_name: this.state.investigator_first_name,
                investigator_last_name: this.state.investigator_last_name,
                phone_number: this.state.phone_number,
                email: this.state.email,

            }
       
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
               
            }
            if (((this.state.indication.length > 0 ? this.state.indication[0].value === "Other" : this.state.indication === []) && this.state.tumorIndication === "")) {
                errors["tumorIndication"] = true
                is_valid = false;

            }
           
            this.setState({
                error: { ...errors }
            });
            
        });
       
        return is_valid;
    }

    changeShowState = () => {
       if(this.state.successModal){
        this.props.handleConceptForm(false, true)
       }
       this.setState({errorModal:false})
    }

    render() {
        let {handleConceptForm,showconceptForm}=this.props;
        return (
            <Modal show={showconceptForm} className="new-flow modal" size="lg">
                 <div> <Modal.Header className='border-0' closeButton onClick={()=>handleConceptForm(false)}  >
                     <Modal.Title id="contained-modal-title-vcenter">
                     <h5 className="font-weight-bold border-0">Create a New Submission: Concept</h5>
                     <span  className="font-weight-bold mt-6 ml-2" style={{fontSize:"1.0rem"}}>Investigator Information</span>
                        </Modal.Title>
                    </Modal.Header></div>
                    <Modal.Body className="new-flow-modal" >
                   <Row>
                            {this.renderInputFields()}
                        </Row></Modal.Body>
                        
                {this.state.successModal &&
                    <BootstrapModal
                        show={this.state.successModal}
                        header="Success"
                        body="Concept has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal
                        show={this.state.errorModal}
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
                <Modal.Footer className='d-flex justify-content-center align-items-center border-0'  >
                <Button style="success w-100px" onClick={this.submitForm} className="d-flex align-items-center p-2 justify-content-center"><span>SAVE</span></Button>
                        
                           </Modal.Footer>
            </Modal>
        )
    }
}
