import React, { Component } from 'react';

import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import { ORGANIZATION } from '../../../mapping';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { Col, Container, Row, Button } from 'react-bootstrap';
import UserContext from '../../../context/UserContext';

export default class Organization extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        extOrganizationMedicalContactName: this.props.submissionDetails.org_medical_name || "",
        orgaddressasPI: this.props.submissionDetails.org_address_same || false,
        extOrganizationName: this.props.submissionDetails.org_name || "",
        extTypeofOrganization: this.props.submissionDetails.org_type || "",
        extOrganizationRole: this.props.submissionDetails.org_role || "",
        extOrganizationStreetAddress: this.props.submissionDetails.org_address || "",
        extOtherOrganizationRole: this.props.submissionDetails.org_role_other || "",
        extOrganizationCity: this.props.submissionDetails.org_city || "",
        extOrganizationPhoneNumber: this.props.submissionDetails.org_contact_no || "",
        extOrganizationCountry: this.props.submissionDetails.org_country || "",
        extOrgAlternatePhone: this.props.submissionDetails.org_alt_contact_no || "",
        extOrganizationState: this.props.submissionDetails.org_state || "",
        extOrganizationEmail: this.props.submissionDetails.org_email || "",
        extOrganizationZipPostalCode: this.props.submissionDetails.org_zipcode || "",
        organizationistheregulatorysponsor: this.props.submissionDetails.regulatory_sponsor || false,
        organizationFaxNumber: this.props.submissionDetails.org_fax_no || "",
        countryList: [],
        stateList: [],
        patchObject: {"id": this.props.submissionDetails.id}
}

componentDidUpdate(previousProps, previousState) {
    let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
    this.setState({
        selectedRole: selectedRole,
        access: {
       view: true,
       create:  selectedRole.includes("administrator"),
       edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
       delete: selectedRole.includes("administrator"),
   },
    })
}
}
    get inputFieldsArray() { 
        return [
            {
                label: "[EXT] Organization Medical Contact Name",
                name:  "extOrganizationMedicalContactName",
                type: "text",
                helptext:"If different than Primary Investigator-Sponsor"
            },
            {
                label: "Org address as PI",
                name:  "orgaddressasPI",
                type: "checkbox",
            },
            {
                label: "[EXT] Organization Name",
                name:  "extOrganizationName",
                type: "text",
            },
            {
                label: "[EXT] Type of Organization",
                name:  "extTypeofOrganization",
                type: "dropdown",
                placeholder: "Select Organization Type",
                options: [
                    "None",
                    "Academic",
                    "Biotech",
                    "Consortium",
                    "Cooperative",
                    "CRO",
                    "Device",
                    "Government Agency",
                    "Other",
                    "Pharma",
                    "University/Hospital"
                ]
            },
            {
                label: "[EXT] Organization Role",
                name:  "extOrganizationRole",
                type: "dropdown",
                placeholder: "Select Organization Role",
                options: [
                    "None",
                    "CRO",
                    "Consortium",
                    "Hospital",
                    "Academic Institute",
                    "Government Agency",
                    "Other"
                ]
            },
            {
                label: "[EXT] Organization Street Address",
                name:  "extOrganizationStreetAddress",
                type: "text",
            },
            {
                label: "[EXT] Other Organization Role",
                name:  "extOtherOrganizationRole",
                type: "textarea",
            },
            {
                label: "[EXT] Organization City    ",
                name:  "extOrganizationCity",
                type: "text",
            },
            {
                label: "[EXT] Organization Phone Number",
                name:  "extOrganizationPhoneNumber",
                type: "text",
            },
            {
                label: "[EXT] Organization Country    ",
                name:  "extOrganizationCountry",
                type: "searchableDropdown",
                options: this.state.countryList,
                onSelect: this.onCountryChange
            },
            {
                label: "[EXT] Org Alternate Phone",
                name:  "extOrgAlternatePhone",
                type: "text",
            },
            {
                label: "[EXT] Organization State",
                name:  "extOrganizationState",
                type: "searchableDropdown",
                options: this.state.stateList,
                onSelect: this.onSearchSelect
            },
            {
                label: "[EXT] Organization Email",
                name:  "extOrganizationEmail",
                type: "email",
            },
            {
                label: "[EXT] Organization Zip/Postal Code",
                name:  "extOrganizationZipPostalCode",
                type: "text",
            },
            {
                label: "Organization is the regulatory sponsor",
                name:  "organizationistheregulatorysponsor",
                type: "checkbox",
            },
            {
                label: "[EXT] Organization Fax Number",
                name:  "organizationFaxNumber",
                type: "text",
            }
        ];
}
    componentDidMount() {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
    this.setState({
        selectedRole: selectedRole,
        access: {
            view: true,
            create:  selectedRole.includes("administrator"),
            edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
            delete: selectedRole.includes("administrator"),
        },
    })
        this.getCountryList();     
    }

    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
        const resultArray =(res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ 
            countryList: resultArray,
            extOrganizationCountry: resultArray.find(cnt => cnt.value+"" === this.props.submissionDetails.org_country) || ""
        },
            () => this.getStateList(this.state.extOrganizationCountry?.name));
    }

    getStateList = async (country) => {
        if(country){
            const res = await API.get(`seed/states/data/${country}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
            this.setState({ 
                stateList: resultArray,
                extOrganizationState: resultArray.find(state => state.value+"" === this.props.submissionDetails.org_state) || ""
            });
        }
    }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [ORGANIZATION[name]]: e.value},
            hasChanged: true
        });
        this.getStateList(e.name)
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [ORGANIZATION[name]]: e.value},
            hasChanged: true
        }); 
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            patchObject:  {...this.state.patchObject, [ORGANIZATION[e.target.name]]: e.target.value},
            hasChanged: true
        });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [ORGANIZATION[eo.name || eo.target.name]]: e},
            hasChanged: true
        });        
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
            patchObject:  {...this.state.patchObject, [ORGANIZATION[e.target.name]]: e.target.checked},
            hasChanged: true
        });
    }

    submitForm = async () =>  {
        try {
            const res = await API.patch("submission/update", this.state.patchObject);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }
organizationData=()=>{
    const newObject={};
    const obj = {...this.state.patchObject};
    Object.keys(obj).forEach((key) => {
        if (obj[key] === "" || obj[key] === null ) {
            return false;
        } 
        else{
            newObject[key] = obj[key];
        }
    });
    delete newObject.error;
    this.state.patchObject={};
    return newObject;
}
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={ field.type !== "checkbox"? this.onInputChange: this.checkboxChange }
                />
            </Col>
            )
        });
    }

    render() {
        return (
            <Container>
            <Row>
                {this.renderInputFields()}
            </Row>
            {/* <div className="w-100 d-flex justify-content-center">
                <Button className="px-4" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                    Save
                </Button>
            </div> */}
            {this.state.successModal &&
                <BootstrapModal 
                    show={this.state.successModal} 
                    header="Success"
                    body="Updated successfully!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal 
                    show={this.state.errorModal} 
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            </Container>
        )
    }
}
