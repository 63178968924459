import React, { Component } from 'react'
import { Col, Row, Modal } from 'react-bootstrap';
import API from '../../../api/apis';
import UserContext from '../../../context/UserContext';
import { dateTimeformat, getUserInfo } from '../../../utility/helperFunctions';
import InputField from '../../common/input-fields/InputField';
import LoaderEx from '../loader/LoaderEx';
import { isCoordinator, showMessage } from '../../ToReviewDetails/commonFunctions';
import Button from "../../common/Button";
import SwitchView from '../SwitchView';
import { switchEnum } from '../SwitchView/switchEnum';
import SwitchHistory from '../SwitchHistory';
import { OBJ_TYPES } from '../SwitchHistory/historyConsts';

class PresentationModalEx extends React.Component {
    static contextType = UserContext;

    state = {
        hasChanged: false,
        name: "",
        showLoader: true,
        viewDetails: true
    }
    async componentDidMount() {
        let _isCoordinator=isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator });

        const { viewEditFlag } = this.props;

        if (viewEditFlag === 'edit') {
            let presentationData = await this.getPresentationById(this.props.rowId);
            if (presentationData) {
                presentationData["createdby"] = presentationData.created_by?.length ? presentationData.created_name + "  " + dateTimeformat(presentationData.createdAt) : "";
                presentationData["updatedby"] = presentationData.updated_by?.length > 0 ? presentationData.updated_name + "  " + dateTimeformat(presentationData.updatedAt) : presentationData.createdby;

                let { createdby, updatedby, name } = presentationData;
                this.setState({
                    createdby, updatedby, name,
                });
            }else {
                showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false, null, {}));
            }

        }
        this.setState({ showLoader: false });

    }
    getPresentationById = async (id) => {
        try {
            const res = await API.get(`/seed/presentation/getbyid/${id}`);
            if (res.data.success) {
                return res.data.data || null;
            } else {
                showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false, null, {}));
            }
        } catch (e) {
            console.log(e);
            showMessage({ text: "Failed", icon: "error", button: "Ok" },
                    () => this.props.handleModalShowHide(false, null, {}));
        }
        return null;
    }
    handlePresentationSave = async (state) => {
        this.setState({ showLoader: true });
        let saveObj = { name: state.name.trim(), created_by: this.context.userSystemId }

        try {
            const res = await API.post("/seed/presentation/addpresentation", saveObj);
            if (res.data.success) {
                this.props.handleEvents('ADD', true);
            } else {
                this.props.handleEvents('ADD', false);
            }
        } catch (err) {
            this.props.handleEvents('ADD', false);
        } finally {
            this.setState({ showLoader: false });
        }
    }
    handlePresentationUpdate = async (state, rowId) => {
        if (this.formValidation(this.state)) {

            this.setState({ showLoader: true });
            try {
                let updateObj = { id: rowId, name: state.name.trim(), updated_by: this.context.userSystemId }
                const res = await API.post("/seed/presentation/update", updateObj);
                if (res.data.success) {
                    this.props.handleEvents('UPDATE', true);
                } else {
                    this.props.handleEvents('UPDATE', false);
                }

            } catch (err) {
                this.props.handleEvents('UPDATE', false);

            } finally {
                this.setState({ showLoader: false });
            }
        }
    }
    get inputFieldsArray() {
        return [
            {
                label: "Presentation Name *",
                type: "text",
                name: "name",
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                // className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                // className: "createUpdateByfontsize",
                disabled: true,
                edit: true
            }

        ];
    }

    onInputChange = (e) => {
        this.setState({})
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }

    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            name: this.state.name,
        }
        const errors = {};

        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null || initialValues[key].toString().trim().length<=0) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors }
            });
        });
        return is_valid;
    }
    onSave = () => {
        if (this.formValidation(this.state)) {
            this.handlePresentationSave(this.state);
        }
    }
    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.name !== "createdBy" || field.name !== "updatedBy") {
                if (!this.state.is_Coordinator) {

                    field.disabled = true;
                }
            }
            if (field.edit && this.props.viewEditFlag !== "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.onChange || this.onInputChange}
                    />
                </Col>
            )
        });
    }

    render() {
        const { handleModalShowHide, showHideModal, viewEditFlag, rowId } = this.props;
        const { viewDetails } = this.state;
        return (
            <div>
                <Modal show={showHideModal} size="lg" className="modal-mdx new-flow-modal" aria-labelledby="contained-modal-title-vcenter" >

                    <div> <Modal.Header className="d-flex border-0"  >
                        <Modal.Title className="w-100 d-flex justify-content-center flex-grow-1 text-left">
                            <h5 className='modal-title text-left w-100 font-weight-bold'>
                                {viewEditFlag === "edit" ?
                                    `Presentation` : "Add a Presentation"}
                            </h5>

                        </Modal.Title>
                        <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" onClick={() => handleModalShowHide(false, null, {})} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                    </Modal.Header>
                        <Modal.Body>
                            {this.state.showLoader && <LoaderEx></LoaderEx>}
                            { rowId && 
                                <SwitchView
                                    viewMode={(value) => {
                                        if (value === switchEnum.DETAILS) {
                                            this.setState({ viewDetails: true });
                                        } else {
                                            this.setState({ viewDetails: false });
                                        }
                                    }}
                                /> 
                            }

                            { viewDetails ? 
                                <Row>
                                    {this.renderInputFields()}
                                </Row>

                            : <SwitchHistory 
                                object_type={OBJ_TYPES.PRESENTATION} 
                                object_id={rowId} 
                              /> 
                            }
                        </Modal.Body>
                        { viewDetails && <Modal.Footer className="d-flex border-0 justify-content-center">
                            <Button style="danger w-100px" onClick={() => handleModalShowHide(false, null, {})} className="d-flex align-items-center p-2 justify-content-center"><span>Cancel</span></Button>

                            {this.state.is_Coordinator ?
                                (viewEditFlag === "edit") ?
                                    <Button style="success w-100px" onClick={() => this.handlePresentationUpdate(this.state, this.props.rowId)} className="d-flex align-items-center p-2 justify-content-center"><span>SAVE</span></Button> :
                                    <Button style="success w-100px" onClick={this.onSave} className="d-flex align-items-center p-2 justify-content-center"><span>Add</span></Button>
                                : <></>}

                        </Modal.Footer> } </div>
                </Modal>
            </div>
        )
    }

}

export default PresentationModalEx;