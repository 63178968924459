import React from 'react'
import {Modal, Row, Col} from 'react-bootstrap';
import API from '../../../api/apis';
import InputField from '../../common/input-fields/InputField';
import Form from 'react-bootstrap/Form';
import { showMessage} from '../commonFunctions';
import {DateTime} from "luxon"
import { yyyymmddFormat } from '../../common/dateFormat';
import PropTypes from 'prop-types';
import ConceptReview from './ConceptReview';
import UserContext from '../../../context/UserContext';
import axios from 'axios';
import ConceptInfo from './ConceptInfo';
import ProposalReview from './ProposalReview';
import ProtocolReview from './ProtocolReview';
import ProposalDetailsView from '../../submission/detailSubComponents/ProposalDetailView';
import ProtocolDetailView from '../../submission/detailSubComponents/ProtocolDetailView';
import PublicationDetailView from '../../PublicationDetailView';
import AmendmentDetailView from '../../AmendmentDetailView';
import { getReviewTypeDetails } from './commonReviewFn';
import PublicationReview from './PublicationReview';
import AmendmentReview from './AmendmentReview';
import SharepointConnect from '../SharepointConnect';
import DiagnosticDetails from '../../submission/detailSubComponents/DiagnosticDetailView';
import DiagnosticDetailsEx from '../../submission/detailSubComponents/DiagnosticDetailsEx';
import TranslationalDetailsEx from '../../submission/detailSubComponents/TranslationalDetailView';
import Button from '../../common/Button';
import { isCoordinator } from '../commonFunctions';
export default class ReviewForm extends React.Component{
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={isIUDOperation:false,externalurl:'',formView:this.props.formView||''};
    }  
    componentDidMount(){        
       //sessionStorage.removeItem("review-tab-data");
       let _isCoordinator = isCoordinator(this.context);
       this.setState({
        is_Coordinator: _isCoordinator,
    });
        this.getExternalUrl();
    }
    setProgress=(value)=>{
        this.setState({isIUDOperation:value||false});
    }
    handleDownload=()=>{
        this._reviewForm.downloadFormData();
    }
    handleSave=async()=>{
        this.setProgress(true);
        try{
            let requestData=this._reviewForm.getData();
            let additionalFormData=this._reviewForm.getAdditionalFormData();
            let {submission_id,reviewers_detail_id,review_header_id}=this.props;
           
            if(requestData){
                requestData=Object.assign(requestData,{
                    submission_id,
                    reviewers_detail_id,
                    review_header_id,
                    "status":requestData.status||"SUBMITTED",
                    created_by:this.context.userSystemId,
                    "review_type": this.props.review_type
                    
                })              
                if(additionalFormData&&additionalFormData.additionalReviewData){
                    requestData.additional_form_data={
                        "addtnl_review_form":this.props.addtnl_review_form,
                        "review_data":additionalFormData.additionalReviewData||{},
                        created_by:this.context.userSystemId
                    };
                }
                let url="reviewsTab/review/add";
                if(this.props.formType==="COORDINATOR" && this.props.review_submission_id){
                    url="reviewsTab/review/update";
                    requestData.id=this.props.review_submission_id;
                }
                const res = await API.post(url, requestData);
                this.setProgress(false);
                if (res.data.error || res.data.errors) {
                    showMessage({
                        text: "Failed to update the record!",
                        icon: "error",
                        button: "Ok",
    
                    }, (e) => {  });
                }
                else{
                    
                    let fileUploadStatus=await this.initiateFileUpload(res.data);
                    
                    showMessage({
                        text: "Review Submitted!",
                        icon: "success",
                        button: "Ok",
    
                    }, (e) => {
                        this.handleClose();
                      });
                }
            }
        }
        catch(exp){
            this.setProgress(false);
        }
        finally{
            this.setProgress(false);
        }
       
    }
    handleClose=()=>{
        if(this.props.onClose&&!this.props.isNewWindow){
            this.props.onClose();
        } 
        if(this.props.isNewWindow){
            this.setState({formView:'READONLY'},()=>{
                sessionStorage.removeItem("review-tab-data");
            });
        }
    }
    getExternalUrl=async ()=> {
        //debugger;
        let response ='';
        try {
             response = await API.get("user/externalurl");
             //console.warn(response);
           
        }
        catch (err) {
            //console.log(err);
        }
        this.setState({
        externalurl : (response && response.data) ? response.data.exturl : "",
    })
    }
    initiateSharepointUpload=async(sharePointFileInfo,data)=>{
        let { id }=data;
        let { userSystemId } = this.context;
        
        let {submission_id,reviewers_detail_id,review_header_id,review_type}=this.props;
        let userID=this.context.userSystemId;
        if(sharePointFileInfo&&sharePointFileInfo.file){             
            let fileData=[];
            fileData.push({
               fileInfo:sharePointFileInfo ,                     
               submissionId:submission_id,
               review_type:review_type,
               review_header_id:review_header_id,
               reviewers_detail_id:`${reviewers_detail_id}/${review_type.toLowerCase()}review-${id}`
           });
           
            if (fileData && fileData.length > 0) {
                let asyncFiles = [];
                let sc=new SharepointConnect();
                let stateInfo={};
                await sc.loadConfigInformation().then((datax)=>{    
                    stateInfo=datax;
                });
                let item=fileData[0];
                let newState=Object.assign(stateInfo,item, {
                    "userSystemId": userSystemId, 
                    "review_id": id,
                } );
                let sc_uploader=new SharepointConnect(newState);
                let response= await sc_uploader.uploadMultipleFiles(item.fileInfo.file);
               //  let records = [];
               //  await Promise.all(asyncFiles)
               //  .then((d) => {
                   
               //      records=[...d];
               //  });
            }
        }
       
    }
    initiateFileUpload=async (data)=>{
        this.setProgress(true);
        let {submission_id,reviewers_detail_id,review_header_id,addtnl_review_form}=this.props;
        const formData = new FormData();
        let fileInfo=this._reviewForm.getFile();
        let sharePointFileInfo=this._reviewForm.getSharepointFile();
        if(sharePointFileInfo){
            this.initiateSharepointUpload(sharePointFileInfo,data);
        }
        let addtnlForm=this._reviewForm.getAdditionalFormData();
        if(addtnl_review_form&&addtnlForm.additionalReviewFile)
        {
            let key=data.id;
            this.initiateSharepointUpload(addtnlForm.additionalReviewFile,{id:key+"-additionalform"});
        }

        if (fileInfo && fileInfo.file && this.state.externalurl) {
            let { id,reviewers_details  }=data;
           
            formData.append(
                'file',
                fileInfo.file,
                fileInfo.selectedFileName
            );
            formData.append(
                'created_by',
                this.context.userSystemId
            );
            formData.append(
                'updated_by',
                this.context.userSystemId
            );
            formData.append(
                'reviewers_detail_id',
                reviewers_detail_id
            );
            formData.append(
                'review_header_id',
                review_header_id
            );
            
            try {
                let fileUrl = `${this.state.externalurl}/fileupload/Review/${id}`;
                let res = await axios.post(fileUrl, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    },
                });
                // alert("finished")
                // console.info(res);
                return true;

            } catch (e) {
                // alert("failed")
                // console.info(e);
                return false;
            }
        }
        this.setProgress(false);
        return true;
    }
    getReviewDocument=()=>{
        let {review_type,submission_id, review_for}=this.props;   
        let viewProps={review_type,submission_id, review_for}
        switch((review_type||'').toLowerCase()){
            case "concept":return  <ConceptInfo {...viewProps}></ConceptInfo>;break;
            case "proposal":return  <div><ProposalDetailsView {...viewProps}></ProposalDetailsView>
            <DiagnosticDetails submissionId={this.props.submission_id}/>
            <TranslationalDetailsEx submissionId={this.props.submission_id}/></div>;break;
            case "protocol":return  <ProtocolDetailView {...viewProps}></ProtocolDetailView>;break;
            case "publication":return  <PublicationDetailView {...viewProps}></PublicationDetailView>;break;
            case "amendment":return  <AmendmentDetailView {...viewProps}></AmendmentDetailView>;break;
            default:return <></>; break;
        }
    }
    renderReviewForm=(localProps)=>{
        let {review_type}=localProps;
       
        switch(review_type.toLowerCase()){
            case "concept":return <ConceptReview {...localProps}  ref={ref => this._reviewForm = ref}></ConceptReview>;break;
            case "proposal":return <ProposalReview  {...localProps} ref={ref => this._reviewForm = ref}></ProposalReview>;break;
            case "protocol":return <ProtocolReview  {...localProps} ref={ref => this._reviewForm = ref}></ProtocolReview>;break;
            case "publication":return <PublicationReview  {...localProps} ref={ref => this._reviewForm = ref}></PublicationReview>;break;
            case "amendment":return <AmendmentReview  {...localProps} ref={ref => this._reviewForm = ref}></AmendmentReview>;break;
            default:<></>;break;
        }
    }
    render(){
        let {isIUDOperation, is_Coordinator}=this.state;

        let {isCommiteeReview,
            reviewers_detail_id,
            review_header_id,review_submission_id,
            submission_id,isCoordinatorApproval,review_type,addtnl_review_form,review_for}=this.props;
           
            let formType=(this.props.formType?this.props.formType:(isCommiteeReview?"COMMITTEE":"REVIEWER"));
            let {formView}=this.state;
            
        return <div className={(this.props.isNewWindow?"container-fluid new-flow-modal":"")}><Row>
            {
                !isCommiteeReview && <Col md={6}>
                     <div style={{fontSize:'1.3rem',marginTop:'-3rem'}} className="position-absolute w-100">
                  <span style={{marginLeft:'2%'}}>  {this.props.review_type} Document(s)</span>
                </div>
                {
                    this.getReviewDocument()
                }
                </Col>
            }
            <Col md={(isCommiteeReview?12:6)}>
                <div style={{fontSize:'1.3rem',marginTop:'-3rem'}} className="position-absolute w-100">
                  <span style={{marginLeft:'2%'}}>{isCommiteeReview?"Committee ":""}{this.props.review_type} {isCommiteeReview?"Review ":"Forms"}</span>
                </div>
                {
                    this.renderReviewForm({
                        formView,
                        review_type,
                        reviewers_detail_id,
                        review_header_id,
                        review_submission_id,
                        submission_id,
                        isCoordinatorApproval,
                        formType,
                        addtnl_review_form,
                        review_for
                    })
                }
                {/* {this.props.review_type==="Concept"&&<ConceptReview 
                       formView={formView} 
                       review_type={this.props.review_type}
                       reviewers_detail_id={reviewers_detail_id} 
                       review_header_id={review_header_id} 
                       review_submission_id={review_submission_id} 
                       submission_id={submission_id} 
                       isCoordinatorApproval={isCoordinatorApproval} 
                ref={ref => this._reviewForm = ref} formType={formType}></ConceptReview>}
                 {this.props.review_type==="Proposal"&&<ProposalReview 
                       formView={formView} 
                       review_type={this.props.review_type}
                       reviewers_detail_id={reviewers_detail_id} 
                       review_header_id={review_header_id} 
                       review_submission_id={review_submission_id} 
                       submission_id={submission_id} 
                       isCoordinatorApproval={isCoordinatorApproval} 
                ref={ref => this._reviewForm = ref} formType={formType}></ProposalReview>}
                  {this.props.review_type.toLowerCase()==="protocol"&&<ProtocolReview 
                       formView={formView} 
                       review_type={this.props.review_type}
                       reviewers_detail_id={reviewers_detail_id} 
                       review_header_id={review_header_id} 
                       review_submission_id={review_submission_id} 
                       submission_id={submission_id} 
                       isCoordinatorApproval={isCoordinatorApproval} 
                ref={ref => this._reviewForm = ref} formType={formType}></ProtocolReview>} */}
            </Col>
        </Row>
            {isIUDOperation && <div className="d-flex align-items-center justify-content-center p-2 position-absolute w-100 h-100" style={{ left: 0, top: 0 }}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>}
            {
                <div className="mt-3 d-flex align-items-center justify-content-center p-2">
                    {formView!=='READONLY'&& !this.props.isNewWindow&&<Button onClick={e => {
                            if (this.props.onClose) {
                                this.props.onClose();
                            }
                        }} style="danger w-100px" className="mr-2 p-2 d-flex align-items-center justify-content-center" ><span>Cancel</span></Button>
                    }    
                    {formView!=='READONLY'&& 
                        <Button  onClick={e => this.handleSave()} className="ml-2 mr-2 p-2 d-flex align-items-center justify-content-center" style="success w-100px"><div className='d-flex align-items-center text-dark'>
                                <span class="material-icons mr-1" style={{fontSize:'18px'}}>
                                    send
                                </span><span>Submit</span></div></Button>
                    }
                    {is_Coordinator ? <Button onClick={this.handleDownload} className="ml-2 p-2 d-flex align-items-center justify-content-center" style="success w-100px">
                        <div className='d-flex align-items-center text-dark'>
                        <span class="material-icons mr-1" style={{fontSize:'18px'}}>
                        file_download
                        </span><span>EXPORT</span></div></Button> : <></>}
            </div>
            }
           
        </div>
    }
}