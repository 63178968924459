import { SERVICE_ENV } from "../Constants";
import Papa from 'papaparse';
export const getFilterRequest=(source)=>{
    let requestBody={users:null, therapeutic_area:null, stage:null, request_type:null,trial_alias:null,country:null,submission_id:null,created_at:null};
    let maps={'assigned_to':'users'};
    Object.entries(source).forEach(([key,value])=>{
      let selected_data=null;
      if(value && key!="created_at"){
        selected_data=value.map(p=>{
          return (p.id||p.value);
        }); 
      }
      if(value && key=="created_at"){
        selected_data=value[0];
      }
      if(selected_data){
        requestBody={...requestBody,[maps[key]||key]:selected_data};
      }  
    });

    return requestBody;
}
export const getServiceDomain=()=>{
  if(window.location.host.includes("stg"))
    return SERVICE_ENV.QA;
  else  if(window.location.host.includes("dev")||window.location.host.includes("localhost"))
    return SERVICE_ENV.DEV;
  if(window.location.host.includes("prod"))
    return SERVICE_ENV.PROD;
  return "//";
}

export const getSecurityHeader=(method="POST")=>{  
    let token=localStorage.getItem("token");
    const bearer = "Bearer " + token;
    let config = {
      method,
      headers: {
         'Content-Type':'application/json',
        "Authorization": bearer
      }
    }
    return config;
}
export const downloadCsv=(input=[],fileName)=>{
  var csv = Papa.unparse(input);
  var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  var csvURL =  null;
  if (navigator.msSaveBlob)
  {
      csvURL = navigator.msSaveBlob(csvData, 'download.csv');
  }
  else
  {
      csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', `${fileName}.csv`);
  tempLink.click();
}