import React, { Component } from 'react';
import { Accordion, Button, Card, Col, Container, Row,Modal} from 'react-bootstrap';
import InputField from './common/input-fields/InputField';
import API from '../api/apis';
import BootstrapModal from './common/modal/BootstrapModal';
import { DOSING_DSCHEDULE } from '../consts';
import { convertToRaw, convertFromHTML, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import UserContext from '../context/UserContext';
import { dateChange } from '../utility/helperFunctions';
import * as IIRBtn  from './common/Button';
export default class NewProposal extends Component {
    titleRef = React.createRef();
    static contextType = UserContext;

    state = {
        proposalSubmitted: false,
        submissionId: "Submission-"+this.props.submissionId,
        last_updated_by: "",
        proposalTitle: "",
        shippingImportLicenseRequired: "None",
        iNDApplication: "None",
        uSDepartmentOfVetAffairs: "None",
        scientificRationaleForStudy: "",
        studyType: "None",
        primaryAndSecondaryObjectives: "",
        ifOtherStudyType: "",
        lengthOfWashout: "",
        studyToUseAnimals: "None",
        iACUCDocumentationAttached: "None",
        descriptionOfAnimalUsed: "",
        dosesConcentrations: "",
        aCAndUCommitteeApproval: "None",
        endpoints: "",
        modelMethodology: "",
        targetStartDate: null,
        statisticalAnalysis: "",
        reportSubmissionDate: null,
        humanSamplesInvolved: "None",
        publicationDateAnticipated: null,
        typeOfHumanSamples: "None",
        projectedMilestoneTiming: "",
        uniquePersonalClinicalSamples: "None",
        biobankOwner: "None",
        sampleSize: "",
        dateOfFirstPatientVisit: null,
        numberOfTherapyGroups: "",
        dateFirstPtEntersTreatment: null,
        anticipatedQuarterlyEnrollment: "",
        dateLastPatientEntersTrial: null,
        numberOfInvestigativeSite: "",
        dateLastPatientEntersTreatment: null,
        potentialCountries: "",
        dateOfLastPatientVisit: null,
        multiCountryTrial: false,
        dateOfReportSubmission: null,
        clinicalTrialPhase: [],
        includePreClinicalPortion: false,
        dateOfPublicationSubmission: null,
        assignmentToTreatment: [],
        leadInPeriod: [],
        design: [],
        blinding: [],
        controls: [],
        blindingDoneBy: "None",
        extension: [],
        patientType: [],
        extensionLength: "",
        patientGender: [],
        ageRangeStart: "",
        patientTreatmentPeriod: "",
        ageRangeEnd: "",
        studyOnOffLabel: "None",
        lineOfTherapy: "None",
        lillyToSupplyStudyCompound: "None",
        ifNoWhy: "None",
        lillyStudyCompound1: "",
        requireFifthLillyCompound: "None",
        lillyStudyCompound1Other: "",
        lillyStudyCompound5: "",
        compound1AvailableMaterial: "",
        lillyStudyCompound5Other: "",
        compound1AvailableMaterialOther: "",
        compound5AvailableMaterial: "",
        compound1Formulation: "",
        compound5AvailableMaterialOther: "",
        compound1DosingSchedule: "",
        compound5Formulation: "",
        compound1DosingScheduleOther: "",
        compound5DosingSchedule: "None",
        compound1AdministeredDose: "",
        compound5DosingScheduleOther: "",
        compound1AmountQuantity: "",
        compound5AdministeredDose: "",
        requestedAmtOfComp1PreClin: "",
        compound5AmountQuantity: "",
        requireSecondLillyCompound: "",
        requestedAmtOfComp5PreClin: "",
        lillyStudyCompound2: "",
        requireSixthLillyCompound: "None",
        lillyStudyCompound2Other: "",
        lillyStudyCompound6: "",
        compound2AvailableMaterial: "",
        lillyStudyCompound6Other: "",
        compound2AvailableMaterialOther: "",
        compound6AvailableMaterial: "",
        compound2Formulation: "",
        compound6AvailableMaterialOther: "",
        compound2DosingSchedule: "None",
        compound6Formulation: "",
        compound2DosingScheduleOther: "",
        compound6DosingSchedule: "None",
        compound2AdministeredDose: "",
        compound6DosingScheduleOther: "",
        compound2AmountQuantity: "",
        compound6AdministeredDose: "",
        requestedAmtOfComp2PreClin: "",
        compound6AmountQuantity: "",
        requireThirdLillyCompound: "",
        requestedAmtOfComp6PreClin: "",
        lillyStudyCompound3: "",
        requireSeventhLillyCompound: "None",
        lillyStudyCompound3Other: "",
        lillyStudyCompound7: "",
        compound3AvailableMaterial: "",
        lillyStudyCompound7Other: "",
        compound3AvailableMaterialOther: "",
        compound7AvailableMaterial: "",
        compound3Formulation: "",
        compound7Formulation: "",
        compound3DosingSchedule: "None",
        compound7AvailableMaterialOther: "",
        compound3DosingScheduleOther: "",
        compound7DosingSchedule: "None",
        compound3AdministeredDose: "",
        compound7DosingScheduleOther: "",
        compound3AmountQuantity: "",
        compound7AdministeredDose: "",
        requestedAmtOfComp3PreClin: "",
        compound7AmountQuantity: "",
        requireFourthLillyCompound: "None",
        requestedAmtOfComp7PreClin: "",
        lillyStudyCompound4: "",
        lillyStudyCompound4Other: "",
        compound4AvailableMaterial: "",
        compound4AvailableMaterialOther: "",
        compound4Formulation: "",
        compound4DosingSchedule: "None",
        compound4DosingScheduleOther: "",
        compound4AdministeredDose: "",
        compound4AmountQuantity: "",
        requestedAmtOfComp4PreClin: "",
        requestingFunding: "None",
        nonLillySupport: "None",
        amountOfFunds: "",
        nameOfCompany: "",
        currency: "",
        typeOfSupport: "",
        references: "",
        hCPProposalComments: "",
        feedback: "",
        compoundList: [],
        materialList: [],
        currencyList: [],
        successModal: false,
        errorModal: false,
        lillyStudyCompound1MaterialList: [],
        lillyStudyCompound2MaterialList: [],
        lillyStudyCompound3MaterialList: [],
        lillyStudyCompound4MaterialList: [],
        lillyStudyCompound5MaterialList: [],
        lillyStudyCompound6MaterialList: [],
        lillyStudyCompound7MaterialList: [],
    }

    get inputFieldsArrayInformation() {
        return[
        {
            label: "Proposal Submitted",
            type: "checkbox",
            name: "proposalSubmitted"
        },
        
        {
            label: "Submission",
            type: "text",
            placeholder: "Select Submission",
            name: "submissionId",
            disabled: true
        },
       
        {
            label: "Last Updated By",
            type: "text",
            name: "last_updated_by",
        },]
    }
    get inputFieldsArrayProposalIIR() {
        return [
           
            {
                label: "[EXT] Proposal Title *",
                type: "textarea",
                name: "proposalTitle"
            },
            {
                label: "[EXT] Shipping Import License Required?",
                type: "dropdown",
                placeholder: "Select any Option",
                name: "shippingImportLicenseRequired",
                options: [
                    "None",
                    "Yes",
                    "No"
                ]
            },
            {
                label: "[EXT] IND Application",
                type: "dropdown",
                placeholder: "Select any option",
                name: "iNDApplication",
                options: [
                    "None",
                    "Yes",
                    "No",
                    "Not Conducted in US"
                ],
                helptext: "Is this study to be conducted under a US Investigational New Drug Application?"
            },
            {
                label: "[EXT] US Department of Vet. Affairs",
                type: "dropdown",
                placeholder: "Select any option",
                name: "uSDepartmentOfVetAffairs",
                options: [
                    "None",
                    "Yes",
                    "No",
                    "N/A"
                ]
            },
        ]}
    get inputFieldsArrayProposal_Study() {
        return [
        {
            label: "[EXT] Scientific Rationale for Study",
                type: "text-editor",
                name: "scientificRationaleForStudy",                
                onChange: this.onEditorChange,
                helptext: "Study rationale and how it relates to Lilly product."
        },
        {
            label: "[EXT] Study Type",
            type: "dropdown",
            placeholder: "Select any option",
            name: "studyType",
            options: [
                "None",
                "Clinical Efficacy/Safety",
                "Clinical Pharmacology",
                "Retrospective Analysis",
                "Prospective Observational Study",
                "In Vitro",
                "In Vivo",
                "Ex Vivo",
                "Both In Vitro & In Vivo",
                "Other"
            ],
            helptext: "Clinical Efficacy/Safety, Clinical Pharmacology, Retrospective Analysis, Prospective Observational Study"
        },
        {
            label: "[EXT] Primary and Secondary Objectives",
            type: "textarea",
            name: "primaryAndSecondaryObjectives",
    
            helptext: "List the main study objective that the study will focus on, any secondary objectives should also be provided."
        },
        {
            label: "[EXT] If Other Study Type",
            type: "text",
            placeholder: "Enter Other Study Type",
            name: "ifOtherStudyType",
            helptext: "Please explain"
        },
        {
            label: "[EXT] Length of Washout (if Applicable)",
            type: "text",
            placeholder: "Enter Length of Washout",
            name: "lengthOfWashout",
        },]
    }    
    get inputFieldsArrayNCRI() { 
        return[
    {
        label: "[EXT] Study to use animals",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "studyToUseAnimals",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] IACUC Documentation attached?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "iACUCDocumentationAttached",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Description of Animal Used",
        type: "textarea",
        name: "descriptionOfAnimalUsed"
    },
    {
        label: "[EXT] Doses/Concentrations",
        type: "textarea",
        name: "dosesConcentrations"
    },
    {
        label: "[EXT] AC&U Committee approval?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "aCAndUCommitteeApproval",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Endpoints",
        type: "text",
        placeholder: "Enter Endpoints",
        name: "endpoints"
    },
    {
        label: "[EXT] Model/Methodology",
        type: "textarea",
        name: "modelMethodology"
    },
    {
        label: "[EXT] Target Start Date",
        type: "date",
        name: "targetStartDate"
    },
    {
        label: "[EXT] Statistical Analysis",
        type: "textarea",
        name: "statisticalAnalysis"
    },
    {
        label: "[EXT] Report Submission Date",
        type: "date",
        name: "reportSubmissionDate"
    },
    {
        label: "[EXT] Human samples involved",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "humanSamplesInvolved",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Publication Date Anticipated",
        type: "date",
        name: "publicationDateAnticipated"
    },
    {
        label: "[EXT] Type of Human Samples",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "typeOfHumanSamples",
        options: [
            "None",
            "Cells From Biobank",
            "Study Specific"
        ]
    },
    {
        label: "[EXT] Projected Milestone Timing",
        type: "text",
        placeholder: "Enter Projected Milestone Timing",
        name: "projectedMilestoneTiming"
    },
    {
        label: "[EXT] Unique, Personal, Clinical samples",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "uniquePersonalClinicalSamples",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    {
        label: "[EXT] Biobank owner",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "biobankOwner",
        options: [
            "None",
            "Institution",
            "Third Party"
        ]
    },]}
    get inputFieldsArrayClinical() {
       return [  {
        label: "Clinical Research Info",
        type: "alert",
        variant: "secondary"
    },
    {
        label: "[EXT] Sample Size",
        type: "text",
        placeholder: "Enter Sample Size",
        name: "sampleSize",
        helptext: "Screen failures are not included in enrollment estimations"
    },
    {
        label: "[EXT] Date of First Patient Visit",
        type: "date",
        name: "dateOfFirstPatientVisit",
        helptext: "Anticipated date"
    },
    {
        label: "[EXT] Number of Therapy Groups",
        type: "text",
        placeholder: "Enter Number of Therapy Groups",
        name: "numberOfTherapyGroups"
    },
    {
        label: "[EXT] Date First Pt Enters Treatment",
        type: "date",
        name: "dateFirstPtEntersTreatment",
        helptext: "Anticipated date of the first patient entering treatment"
    },
    {
        label: "[EXT] Anticipated quarterly enrollment",
        type: "text",
        placeholder: "Enter Anticipated quarterly enrollment",
        name: "anticipatedQuarterlyEnrollment",
        helptext: "Screen failures are not included in enrollment estimations."
    },
    {
        label: "[EXT] Date Last Patient Enters Trial",
        type: "date",
        name: "dateLastPatientEntersTrial",
        helptext: "Anticipated date the last patient enters the trial"
    },
    {
        label: "[EXT] Number of Investigative Sites",
        type: "text",
        placeholder: "Enter Number of Investigative Sites",
        name: "numberOfInvestigativeSite"
    },
    {
        label: "[EXT] Date Last Patient Enters Treatment",
        type: "date",
        name: "dateLastPatientEntersTreatment",
        helptext: "Anticipated date the last patient enters the treatment"
    },
    {
        label: "[EXT] Potential Countries",
        type: "textarea",
        name: "potentialCountries"
    },
    {
        label: "[EXT] Date of Last Patient Visit",
        type: "date",
        name: "dateOfLastPatientVisit",
        helptext: "Anticipated date of the last patient visit"
    },
    {
        label: "Multi-Country Trial",
        type: "checkbox",
        name: "multiCountryTrial"
    },
    {
        label: "[EXT] Date of Report Submission",
        type: "date",
        name: "dateOfReportSubmission",
        helptext: "Anticipated date the final report will be submitted to Lilly"
    },
    {
        label: "[EXT] Clinical Trial Phase",
            type: "multi-select",
            placeholder: "Select Clinical Trial Phase",
            name: "clinicalTrialPhase",
            options: [
                {
                    label: "I",
                    value: "I"
                },
                {
                    label: "II",
                    value: "II"
                },
                {
                    label: "III",
                    value: "III"
                },
                {
                    label: "IV",
                    value: "IV"
                },
            ],
    },
    {
        label: "[EXT] Include PreClinical Portion?",
        type: "checkbox",
        name: "includePreClinicalPortion"
    },
    {
        label: "[EXT] Date of Publication Submission",
        type: "date",
        name: "dateOfPublicationSubmission"
    },
    {
        label: "[EXT] Assignment to Treatment",
            type: "multi-select",
            placeholder: "Select Assignment to Treatment",
            name: "assignmentToTreatment",
            options: [
                {value: "Randomized", label: "Randomized"},
                {value: "Non-Randomized", label: "Non-Randomized"},
                {value: "Stratified", label: "Stratified"}
            ],
            helptext: "Randomized, Non Randomized, Stratified"
    },
    {
        label: "[EXT] Lead-in Period",
            type: "multi-select",
            placeholder: "Select Lead-in Period",
            name: "leadInPeriod",
            options: [
                {value: "None", label: "None"},
                {value: "Placebo", label: "Placebo"},
                {value: "Previous Therapy", label: "Previous Therapy"},
                {value: "Previous Therapy Failure", label: "Previous Therapy Failure"}
            ],
            helptext: "None, Placebo, Previous Therapy, Previous Therapy Failure"
    },
    {
        label: "[EXT] Design",
            type: "multi-select",
            placeholder: "Select Design",
            name: "design",
            options: [
                {value: "Uncontrolled", label: "Uncontrolled"},
                {value: "Parallel", label: "Parallel"},
                {value: "Crossover", label: "Crossover"},
                {value: "Length of Washouts", label: "Length of Washout"}
            ],
            helptext: "Uncontrolled, Parallel, Crossover,Length of Washout"
    },
    {
        label: "[EXT] Blinding",
            type: "multi-select",
            placeholder: "Select Blinding",
            name: "blinding",
            options: [
                {value: "Open Label", label: "Open Label"},
                {value: "Single-blind", label: "Single-blind"},
                {value: "Double-blind", label: "Double-blind"},
            ],
            helptext: "Open Label, Single Blind, Double Blind"
    },
    {
        label: "[EXT] Controls",
            type: "multi-select",
            placeholder: "Select Controls",
            name: "controls",
            options: [
                {value: "Active", label: "Active"},
                {value: "Placebo", label: "Placebo"},
                {value: "Both", label: "Both"},
                {value: "None", label: "None"},
            ],
            helptext: "Active, Placebo, Both, None"
    },
    {
        label: "[EXT] Blinding Done By",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "blindingDoneBy",
        options: [
            "None",
            "Lilly",
            "Site",
            "Not Applicable"
        ],
        helptext: "Lilly, Site, Not Applicable"
    },
    {
        label: "[EXT] Extension",
            type: "multi-select",
            placeholder: "Select Extension",
            name: "extension",
            options: [
                {value: "Open Label", label: "Open Label"},
                {value: "Double Blind", label: "Double Blind"},
                {value: "None", label: "None"},
                {value: "length", label: "length"}
            ],
            helptext: "Open Label, Double Blind, None"
    },
    {
        label: "[EXT] Patient Type",
            type: "multi-select",
            placeholder: "Select Patient Type",
            name: "patientType",
            options: [
                {value: "Inpatients", label: "Inpatients"},
                {value: "Outpatients", label: "Outpatients"},
                {value: "Normal Volunteer", label: "Normal Volunteer"}
            ],
            helptext: "Inpatient, Outpatient, Normal Volunteer"
    },
    {
        label: "[EXT] Extension Length",
        type: "text",
        placeholder: "Enter Extension Length",
        name: "extensionLength"
    },
    {
        label: "[EXT] Patient gender",
            type: "multi-select",
            placeholder: "Select Patient gender",
            name: "patientGender",
            options: [
                {value: "Male", label: "Male"},
                {value: "Female", label: "Female"},
                {value: "Both", label: "Both"}
            ],
            helptext: "Male, Female, Both"
    },
    {
        label: "[EXT] Age Range Start",
        type: "text",
        placeholder: "Enter Age Range Start",
        name: "ageRangeStart"
    },
    {
        label: "[EXT] Patient Treatment Period",
        type: "text",
        placeholder: "Enter Patient Treatment Period",
        name: "patientTreatmentPeriod",
        helptext: "Please define the treatment period of the average patient in months, please indicate if it will not be a full month (e.g. 2.5)"
    },
    {
        label: "[EXT] Age Range End",
        type: "text",
        placeholder: "Enter Age Range End",
        name: "ageRangeEnd"
    },
    {
        label: "[EXT] Study On/Off Label",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "studyOnOffLabel",
        options: [
            "None",
            "Off Label",
            "On Label"
        ]
    },
    {
        label: "[EXT] Line of Therapy",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "lineOfTherapy",
        options: [
            "None",
            "1st Line",
            "2nd Line",
            "3rd Line",
            "Adjuvant",
            "Mainteinance",
            "Metastatic",
            "Neoadjuvant",
            "Other",
            "Perioperative"
        ]
    },]
    }
    get inputFieldsArrayStudyCompound() {
       return [  {
        label: "[EXT] Lilly to Supply Study Compound",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "lillyToSupplyStudyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },	
    //[EXT] If No, why?
    {
        label: "[EXT] If No, why?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "ifNoWhy",
        options: [
            "None",
            "The Study doesn't involve an intervention",
            "Local laws/regulations prohibit it",
            "Third-party support is being provided"
        ]
    },

    //[EXT] Lilly Study Compound 1	
    {
        label: "[EXT] Lilly Study Compound 1",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 1",
        name: "lillyStudyCompound1",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },
    //[EXT] require fifth Lilly compound?
    {
        label: "[EXT] require fifth Lilly compound?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requireFifthLillyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },	
    //[EXT] Lilly Study Compound 1 Other
    {
        label: "[EXT] Lilly Study Compound 1 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 1 Other",
        name: "lillyStudyCompound1Other"
    },	
    //[EXT] Lilly Study Compound 5
    {
        label: "[EXT] Lilly Study Compound 5",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 5",
        name: "lillyStudyCompound5",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },	
    //[EXT] Compound 1 Available Material
    {
        label: "[EXT] Compound 1 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 1 Available Material",
        name: "compound1AvailableMaterial",
        options: this.state.lillyStudyCompound1MaterialList,
        onSelect: this.onSearchSelect
    },	
    //[EXT] Lilly Study Compound 5 Other
    {
        label: "[EXT] Lilly Study Compound 5 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 5 Other",
        name: "lillyStudyCompound5Other"
    },	
    //[EXT]Compound 1 Available Material Other
    {
        label: "[EXT]Compound 1 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 1 Available Material Other",
        name: "compound1AvailableMaterialOther"
    },	
    //[EXT] Compound 5 Available Material	
    {
        label: "[EXT] Compound 5 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 5 Available Material",
        name: "compound5AvailableMaterial",
        options: this.state.lillyStudyCompound5MaterialList,
        onSelect: this.onSearchSelect
    },
    //[EXT] Compound 1 Formulation	
    {
        label: "[EXT] Compound 1 Formulation",
        type: "text",
        placeholder: "Enter Compound 1 Formulation",
        name: "compound1Formulation"
    },
    //[EXT]Compound 5 Available Material Other	
    {
        label: "[EXT]Compound 5 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 5 Available Material Other",
        name: "compound5AvailableMaterialOther"
    },
    //[EXT] Compound 1 dosing Schedule
    {
        label: "[EXT] Compound 1 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 1 dosing Schedule",
        name: "compound1DosingSchedule",
        options: DOSING_DSCHEDULE
    },	    
    //[EXT] Compound 5 Formulation	
    {
        label: "[EXT] Compound 5 Formulation",
        type: "text",
        placeholder: "Enter Compound 5 Formulation",
        name: "compound5Formulation"
    },
    //[EXT] Compound 1 dosing Schedule Other
    {
        label: "[EXT] Compound 1 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 1 dosing Schedule Other",
        name: "compound1DosingScheduleOther"
    },	
    //[EXT] Compound 5 dosing Schedule
    {
        label: "[EXT] Compound 5 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 5 dosing Schedule",
        name: "compound5DosingSchedule",
        options: DOSING_DSCHEDULE
    },	
    //[EXT] Compound 1 Administered Dose
    {
        label: "[EXT] Compound 1 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 1 Administered Dose",
        name: "compound1AdministeredDose"
    },	
    //[EXT] Compound 5 dosing Schedule Other
    {
        label: "[EXT] Compound 5 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 5 dosing Schedule Other",
        name: "compound5DosingScheduleOther"
    },	
    //[EXT] Compound 1 Amount (Quantity)
    {
        label: "[EXT] Compound 1 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 1 Amount (Quantity)",
        name: "compound1AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	
    //[EXT] Compound 5 Administered Dose	
    {
        label: "[EXT] Compound 5 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 5 Administered Dose",
        name: "compound5AdministeredDose"
    },
    //[EXT] Requested amt of comp 1 (PreClin)	
    {
        label: "[EXT] Requested amt of comp 1 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 1 (PreClin)",
        name: "requestedAmtOfComp1PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    },
    //[EXT] Compound 5 Amount (Quantity)
    {
        label: "[EXT] Compound 5 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 5 Amount (Quantity)",
        name: "compound5AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	
    //[EXT] require second Lilly compound?	
    {
        label: "[EXT] require second Lilly compound?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requireSecondLillyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    //[EXT] Requested amt of comp 5 (PreClin)
    {
        label: "[EXT] Requested amt of comp 5 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 5 (PreClin)",
        name: "requestedAmtOfComp5PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    },	
    //[EXT] Lilly Study Compound 2	
    {
        label: "[EXT] Lilly Study Compound 2",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 2",
        name: "lillyStudyCompound2",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },
    //[EXT] require sixth Lilly compound?	
    {
        label: "[EXT] require sixth Lilly compound?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requireSixthLillyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    //[EXT] Lilly Study Compound 2 Other
    {
        label: "[EXT] Lilly Study Compound 2 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 2 Other",
        name: "lillyStudyCompound2Other"
    },	
    //[EXT] Lilly Study Compound 6
    {
        label: "[EXT] Lilly Study Compound 6",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 6",
        name: "lillyStudyCompound6",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },	
    //[EXT] Compound 2 Available Material	
    {
        label: "[EXT] Compound 2 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 2 Available Material",
        name: "compound2AvailableMaterial",
        options: this.state.lillyStudyCompound2MaterialList,
        onSelect: this.onSearchSelect
    }, 
    //[EXT] Lilly Study Compound 6 Other
    {
        label: "[EXT] Lilly Study Compound 6 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 6 Other",
        name: "lillyStudyCompound6Other"
    },	
    //[EXT]Compound 2 Available Material Other
    {
        label: "[EXT]Compound 2 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 2 Available Material Other",
        name: "compound2AvailableMaterialOther"
    },  	
    //[EXT] Compound 6 Available Material
    {
        label: "[EXT] Compound 6 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 6 Available Material",
        name: "compound6AvailableMaterial",
        options: this.state.lillyStudyCompound6MaterialList,
        onSelect: this.onSearchSelect
    },	
    //[EXT] Compound 2 Formulation	
    {
        label: "[EXT] Compound 2 Formulation",
        type: "text",
        placeholder: "Enter Compound 2 Formulation",
        name: "compound2Formulation"
    },
    //[EXT]Compound 6 Available Material Other
    {
        label: "[EXT]Compound 6 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 6 Available Material Other",
        name: "compound6AvailableMaterialOther"
    },	
    //[EXT] Compound 2 dosing Schedule
    {
        label: "[EXT] Compound 2 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 2 dosing Schedule",
        name: "compound2DosingSchedule",
        options: DOSING_DSCHEDULE
    },	
    //[EXT] Compound 6 Formulation
    {
        label: "[EXT] Compound 6 Formulation",
        type: "text",
        placeholder: "Enter Compound 6 Formulation",
        name: "compound6Formulation"
    },	
    //[EXT] Compound 2 dosing Schedule Other
    {
        label: "[EXT] Compound 2 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 2 dosing Schedule Other",
        name: "compound2DosingScheduleOther"
    },	
    //[EXT] Compound 6 dosing Schedule
    {
        label: "[EXT] Compound 6 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 6 dosing Schedule",
        name: "compound6DosingSchedule",
        options: DOSING_DSCHEDULE
    },	
    //[EXT] Compound 2 Administered Dose
    {
        label: "[EXT] Compound 2 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 2 Administered Dose",
        name: "compound2AdministeredDose"
    },	
    //[EXT] Compound 6 dosing Schedule Other
    {
        label: "[EXT] Compound 6 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 6 dosing Schedule Other",
        name: "compound6DosingScheduleOther"
    },	
    //[EXT] Compound 2 Amount (Quantity)
    {
        label: "[EXT] Compound 2 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 2 Amount (Quantity)",
        name: "compound2AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	  
    //[EXT] Compound 6 Administered Dose
    {
        label: "[EXT] Compound 6 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 6 Administered Dose",
        name: "compound6AdministeredDose"
    },	
    //[EXT] Requested amt of comp 2 (PreClin)
    {
        label: "[EXT] Requested amt of comp 2 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 2 (PreClin)",
        name: "requestedAmtOfComp2PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    },	
    //[EXT] Compound 6 Amount (Quantity)
    {
        label: "[EXT] Compound 6 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 6 Amount (Quantity)",
        name: "compound6AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	
    //[EXT] require third Lilly compound?
    {
        label: "[EXT] require third Lilly compound?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requireThirdLillyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },	
    //[EXT] Requested amt of comp 6 (PreClin)	
    {
        label: "[EXT] Requested amt of comp 6 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 6 (PreClin)",
        name: "requestedAmtOfComp6PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    },
    //[EXT] Lilly Study Compound 3	
    {
        label: "[EXT] Lilly Study Compound 3",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 3",
        name: "lillyStudyCompound3",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },  
    //[EXT] require seventh Lilly compound?	
    {
        label: "[EXT] require seventh Lilly compound?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requireSeventhLillyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },
    //[EXT] Lilly Study Compound 3 Other
    {
        label: "[EXT] Lilly Study Compound 3 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 3 Other",
        name: "lillyStudyCompound3Other"
    },	
    //[EXT] Lilly Study Compound 7	
    {
        label: "[EXT] Lilly Study Compound 7",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 7",
        name: "lillyStudyCompound7",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },
    //[EXT] Compound 3 Available Material
    {
        label: "[EXT] Compound 3 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 3 Available Material",
        name: "compound3AvailableMaterial",
        options: this.state.lillyStudyCompound3MaterialList,
        onSelect: this.onSearchSelect
    },	
    //[EXT] Lilly Study Compound 7 Other
    {
        label: "[EXT] Lilly Study Compound 7 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 7 Other",
        name: "lillyStudyCompound7Other"
    },	  
    //[EXT]Compound 3 Available Material Other	
    {
        label: "[EXT]Compound 3 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 3 Available Material Other",
        name: "compound3AvailableMaterialOther"
    },
    //[EXT] Compound 7 Available Material
    {
        label: "[EXT] Compound 7 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 7 Available Material",
        name: "compound7AvailableMaterial",
        options: this.state.lillyStudyCompound7MaterialList,
        onSelect: this.onSearchSelect
    },	  
    //[EXT] Compound 3 Formulation
    {
        label: "[EXT] Compound 3 Formulation",
        type: "text",
        placeholder: "Enter Compound 3 Formulation",
        name: "compound3Formulation"
    },	
    //[EXT] Compound 7 Formulation	
    {
        label: "[EXT] Compound 7 Formulation",
        type: "text",
        placeholder: "Enter Compound 7 Formulation",
        name: "compound7Formulation"
    },
    //[EXT] Compound 3 dosing Schedule
    {
        label: "[EXT] Compound 3 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 3 dosing Schedule",
        name: "compound3DosingSchedule",
        options: DOSING_DSCHEDULE
    },	
    //[EXT]Compound 7 Available Material Other
    {
        label: "[EXT]Compound 7 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 7 Available Material Other",
        name: "compound7AvailableMaterialOther"
    },	
    //[EXT] Compound 3 dosing Schedule Other
    {
        label: "[EXT] Compound 3 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 3 dosing Schedule Other",
        name: "compound3DosingScheduleOther"
    },	
    //[EXT] Compound 7 dosing Schedule
    {
        label: "[EXT] Compound 7 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 7 dosing Schedule",
        name: "compound7DosingSchedule",
        options: DOSING_DSCHEDULE
    },	
    //[EXT] Compound 3 Administered Dose
    {
        label: "[EXT] Compound 3 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 3 Administered Dose",
        name: "compound3AdministeredDose"
    },	
    //[EXT] Compound 7 dosing Schedule Other
    {
        label: "[EXT] Compound 7 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 7 dosing Schedule Other",
        name: "compound7DosingScheduleOther"
    },	
    //[EXT] Compound 3 Amount (Quantity)
    {
        label: "[EXT] Compound 3 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 3 Amount (Quantity)",
        name: "compound3AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	
    //[EXT] Compound 7 Administered Dose
    {
        label: "[EXT] Compound 7 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 7 Administered Dose",
        name: "compound7AdministeredDose"
    },	
    //[EXT] Requested amt of comp 3 (PreClin)
    {
        label: "[EXT] Requested amt of comp 3 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 3 (PreClin)",
        name: "requestedAmtOfComp3PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    },	
    //[EXT] Compound 7 Amount (Quantity)
    {
        label: "[EXT] Compound 7 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 7 Amount (Quantity)",
        name: "compound7AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	
    //[EXT] require fourth Lilly compound?
    {
        label: "[EXT] require fourth Lilly compound?",
        type: "dropdown",
        placeholder: "Select any Option",
        name: "requireFourthLillyCompound",
        options: [
            "None",
            "Yes",
            "No"
        ]
    },	
    //[EXT] Requested amt of comp 7 (PreClin)
    {
        label: "[EXT] Requested amt of comp 7 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 7 (PreClin)",
        name: "requestedAmtOfComp7PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    },	
    //[EXT] Lilly Study Compound 4
    {
        label: "[EXT] Lilly Study Compound 4",
        type: "searchableDropdown",
        placeholder: "Select Lilly Study Compound 4",
        name: "lillyStudyCompound4",
        options: this.state.compoundList,
        onSelect: this.onCompoundSelect
    },		 	 
    //[EXT] Lilly Study Compound 4 Other
    {
        label: "[EXT] Lilly Study Compound 4 Other",
        type: "text",
        placeholder: "Enter Lilly Study Compound 4 Other",
        name: "lillyStudyCompound4Other"
    },	
    //[EXT] Compound 4 Available Material
    {
        label: "[EXT] Compound 4 Available Material",
        type: "searchableDropdown",
        placeholder: "Select Compound 4 Available Material",
        name: "compound4AvailableMaterial",
        options: this.state.lillyStudyCompound4MaterialList,
        onSelect: this.onSearchSelect
    },	
    //[EXT]Compound 4 Available Material Other
    {
        label: "[EXT]Compound 4 Available Material Other",
        type: "text",
        placeholder: "Enter Compound 4 Available Material Other",
        name: "compound4AvailableMaterialOther"
    },		 
    //[EXT] Compound 4 Formulation	
    {
        label: "[EXT] Compound 4 Formulation",
        type: "text",
        placeholder: "Enter Compound 4 Formulation",
        name: "compound4Formulation"
    },	 
    //[EXT] Compound 4 dosing Schedule
    {
        label: "[EXT] Compound 4 dosing Schedule",
        type: "dropdown",
        placeholder: "Select Compound 4 dosing Schedule",
        name: "compound4DosingSchedule",
        options: DOSING_DSCHEDULE
    },	
    //[EXT] Compound 4 dosing Schedule Other
    {
        label: "[EXT] Compound 4 dosing Schedule Other",
        type: "text",
        placeholder: "Enter Compound 4 dosing Schedule Other",
        name: "compound4DosingScheduleOther"
    },		 
    //[EXT] Compound 4 Administered Dose
    {
        label: "[EXT] Compound 4 Administered Dose",
        type: "text",
        placeholder: "Enter Compound 4 Administered Dose",
        name: "compound4AdministeredDose"
    },	
    //[EXT] Compound 4 Amount (Quantity)
    {
        label: "[EXT] Compound 4 Amount (Quantity)",
        type: "text",
        placeholder: "Enter Compound 4 Amount (Quantity)",
        name: "compound4AmountQuantity",
        helptext: "Provide amount of medication needed per patient"
    },	
    //[EXT] Requested amt of comp 4 (PreClin)
    {
        label: "[EXT] Requested amt of comp 4 (PreClin)",
        type: "text",
        placeholder: "Enter Requested amt of comp 4 (PreClin)",
        name: "requestedAmtOfComp4PreClin",
        helptext: "Please designate the amount of compound that you need for this study"
    }, ]
    }
    get inputFieldsArrayFinancial() {
        return [ 
        {
            label: "[EXT] Requesting Funding",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "requestingFunding",
            options: [
                "None",
                "Yes",
                "No"
            ]
        },
        {
            label: "[EXT] Non-Lilly Support?",
            type: "dropdown",
            placeholder: "Select any Option",
            name: "nonLillySupport",
            options: [
                "None",
                "Yes",
                "No"
            ],
            helptext: "Is support being provided by another company?"
        },
        {
            label: "[EXT] Amount of funds",
            type: "text",
            placeholder: "Enter Amount of funds",
            name: "amountOfFunds"
        },
        {
            label: "Currency",
            type: "searchableDropdown",
            placeholder: "Select Currency",
            name: "currency",
            options: this.state.currencyList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Name of Company",
            type: "text",
            placeholder: "Enter Name of Company",
            name: "nameOfCompany"
        },
        {
            label: "[EXT] Type of Support",
            type: "text",
            placeholder: "Enter Type of Support",
            name: "typeOfSupport",
            helptext: "Grant, Drug, Both"
        },]
    }
    get inputFieldsArrayOtherInfo() {
        return [
        {
            label: "[EXT] References",
            type: "textarea",
            name: "references",
    
        },
        {
            label: "[EXT] HCP Proposal Comments",
            type: "textarea",
            name: "hCPProposalComments",
    
        }, ]
    }
        // get inputFieldsArrayFeedback() {
        //     return [
               
        //         /*********************************************/
                
        //         /*********************************************/
                
        //         /*********************************************/
               
        //         /*********************************************/
                
        //         /*************************************************************************/
               
        //         /*****************************************************************************/
               
        //         /*****************************************************************************/
               
                
        //         {
        //             label: "Lilly Feedback (Investigator Visible)",
        //             type: "textarea",
        //             name: "feedback",
    
        //         },
        //     ];
        // }

  async  componentDidMount() {
        let res=await API.get('reviewConcept/schemaInformation/proposals')

        console.log(res)

        this.setState({

            proposallist:res.data

        })
        this.getCompoundList();     
        this.getCurrencyList()
    }

    onCompoundSelect = (e, name) => {
        this.setState({
            [name]: e,
        });
        this.getMaterialList(name, e.value)
    }

    getCurrencyList = async () => {
        try {
            const res = await API.get(`seed/currency/data`)
            const resultArray = (res.data?.data || [] ).map(elm => ({ name: `${elm.value} - ${elm.name}`, value: elm.value}));
            this.setState({currencyList: resultArray});
        }catch(e) {
            console.log("Error in currency API")
        }
    }

    getMaterialList = async (name, compound) => {
        try {
            const res = await API.get(`seed/dosage_strength/compound/${compound}`);
            const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.name}));
            this.setState({[name+'MaterialList']: resultArray});
        }catch(e) {
            console.log("Error in compound material Api")
        }
    }

    getCompoundList = async () => {
        const res = await API.get(`seed/compound/getalldata`)
        const resultArray = res.data.filter(elm => elm.is_part_of_proposal===true );
        const arr = resultArray.map(elm=>({ name: elm.name, value: elm.id }) );
        this.setState({ compoundList: arr });
    }

    onInputChange = (e,eo) => {
        if (eo.type === "date") {
            let { name, value } = eo;
            this.setState({
                [name]: e,
                [value]: e,
                patchObject: { ...this.state.patchObject, [eo.name]:dateChange(e) },
            });
        }
        else 
        { this.setState({[e.target.name]: e.target.value});
        if(e.target.value !== "")
            this.setState({error: { ...this.state.error, [e.target.name]: false}});}
    }

    onSelectChange = (e, eo) => {
        // Array.isArray(e) ? e.map(obj => obj.label).join(";") : e
        this.setState({
            [eo.name || eo.target.name]:e
        });        
    }

    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
        }); 
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }

    getHtmlFromEditorValue = (val="") => {
       return stateToHTML(val.getCurrentContent());

    }

    getEditorValueFromHtml = (html) => {
        const blocksFromHTML = convertFromHTML(html);        
        const state = ContentState.createFromBlockArray(blocksFromHTML);
        return EditorState.createWithContent(state);
    }


    onEditorChange = (val, e) => {
        this.setState({[e]: val});
    };

    renderInputFieldsProposalIIR = () => {
        return this.inputFieldsArrayProposalIIR.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsProposal_Study = () => {
        return this.inputFieldsArrayProposal_Study.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsInformation = () => {
        return this.inputFieldsArrayInformation.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsClinical = () => {
        return this.inputFieldsArrayClinical.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsNCRI = () => {
        return this.inputFieldsArrayNCRI.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsStudyCompound = () => {
        return this.inputFieldsArrayStudyCompound.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderInputFieldsOtherInfo = () => {
        return this.inputFieldsArrayOtherInfo.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    

    // renderInputFieldsFeedback = () => {
    //     return this.inputFieldsArrayFeedback.map(field => {
    //         if (field.type === "alert")
    //             return <Col sm={12} key={field.label}><InputField {...field} /></Col>
    //         return (
    //             <Col sm={6} key={field.label}>
    //                 <InputField
    //                     {...field}
    //                     key={field.label}
    //                     //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
    //                     error={this.state.error?.[field.name]}
    //                     value={this.state[field.name]}
    //                     onSelect={field.onSelect || this.onSelectChange}
    //                     onChange={field.type !== "checkbox" ? field.onChange || this.onInputChange : this.checkboxChange}
    //                 />
    //             </Col>
    //         )
    //     });
    // }

    renderInputFieldsFinancial = () => {
        return this.inputFieldsArrayFinancial.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        key={field.label}
                        //disabled={field.hidden ? (field.hidden && (!this.state.access.edit)) : (!this.state.access.edit)}
                        error={this.state.error?.[field.name]}
                        value={this.state[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e)=>this.onInputChange(e,field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }

    formValidation = () => {
        let is_valid = true;
        let error={};
        error["lengthValidation"]={};
        if(this.state['proposalTitle'].trim() === "") {
            is_valid = false;
            error.proposalTitle=true;            
        }
        let{proposallist}=this.state;        
        const obj = ({ ...this.state.patchObject });
       
        for (let [key, value] of Object.entries(proposallist)) {
            if(value){
                if(this.state[key]&&this.state[key].length>value){
                    is_valid = false;
                    error[key] = true;
                    error["lengthValidation"][key]=true;
                }
            }
        }
        this.setState({ error });
        return is_valid;
    }

    submitForm = async () => {
        if(this.formValidation()){
        const obj = {
            proposalSubmitted: this.state.proposalSubmitted,
            last_updated_by: this.state.last_updated_by,
            proposalTitle: this.state.proposalTitle,
            shippingImportLicenseRequired: this.state.shippingImportLicenseRequired,
            iNDApplication: this.state.iNDApplication,
            uSDepartmentOfVetAffairs: this.state.uSDepartmentOfVetAffairs,
            scientificRationaleForStudy: this.state.scientificRationaleForStudy !=="" ? this.getHtmlFromEditorValue(this.state.scientificRationaleForStudy) : "", 
            studyType: this.state.studyType,
            primaryAndSecondaryObjectives: this.state.primaryAndSecondaryObjectives ,
            feedback: this.state.feedback ,
          
            ifOtherStudyType: this.state.ifOtherStudyType,
            lengthOfWashout: this.state.lengthOfWashout,
            studyToUseAnimals: this.state.studyToUseAnimals,
            iACUCDocumentationAttached: this.state.iACUCDocumentationAttached,
            descriptionOfAnimalUsed: this.state.descriptionOfAnimalUsed,
            dosesConcentrations: this.state.dosesConcentrations,
            aCAndUCommitteeApproval: this.state.aCAndUCommitteeApproval,
            endpoints: this.state.endpoints,
            modelMethodology: this.state.modelMethodology,
            targetStartDate: this.state.targetStartDate,
            statisticalAnalysis: this.state.statisticalAnalysis,
            reportSubmissionDate: this.state.reportSubmissionDate,
            humanSamplesInvolved: this.state.humanSamplesInvolved,
            publicationDateAnticipated: this.state.publicationDateAnticipated,
            typeOfHumanSamples: this.state.typeOfHumanSamples,
            projectedMilestoneTiming: this.state.projectedMilestoneTiming,
            uniquePersonalClinicalSamples: this.state.uniquePersonalClinicalSamples,
            biobankOwner: this.state.biobankOwner,
            sampleSize: this.state.sampleSize,
            dateOfFirstPatientVisit: this.state.dateOfFirstPatientVisit,
            numberOfTherapyGroups: this.state.numberOfTherapyGroups,
            dateFirstPtEntersTreatment: this.state.dateFirstPtEntersTreatment,
            anticipatedQuarterlyEnrollment: this.state.anticipatedQuarterlyEnrollment,
            dateLastPatientEntersTrial: this.state.dateLastPatientEntersTrial,
            numberOfInvestigativeSite: this.state.numberOfInvestigativeSite,
            dateLastPatientEntersTreatment: this.state.dateLastPatientEntersTreatment,
            potentialCountries: this.state.potentialCountries,
            dateOfLastPatientVisit: this.state.dateOfLastPatientVisit,
            multiCountryTrial: this.state.multiCountryTrial,
            dateOfReportSubmission: this.state.dateOfReportSubmission,
            clinicalTrialPhase: this.state.clinicalTrialPhase.map(obj => obj.value).join() || "",
            includePreClinicalPortion: this.state.includePreClinicalPortion,
            dateOfPublicationSubmission: this.state.dateOfPublicationSubmission,
            assignmentToTreatment: this.state.assignmentToTreatment.map(obj => obj.value).join() || "",
            leadInPeriod: this.state.leadInPeriod.map(obj => obj.value).join() || "",
            design: this.state.design.map(obj => obj.value).join() || "",
            blinding: this.state.blinding.map(obj => obj.value).join() || "",
            controls: this.state.controls.map(obj => obj.value).join() || "",
            blindingDoneBy: this.state.blindingDoneBy,
            extension: this.state.extension.map(obj => obj.value).join() || "",
            patientType: this.state.patientType.map(obj => obj.value).join() || "",
            extensionLength: this.state.extensionLength,
            patientGender: this.state.patientGender.map(obj => obj.value).join() || "",
            ageRangeStart: this.state.ageRangeStart,
            patientTreatmentPeriod: this.state.patientTreatmentPeriod,
            ageRangeEnd: this.state.ageRangeEnd,
            studyOnOffLabel: this.state.studyOnOffLabel,
            lineOfTherapy: this.state.lineOfTherapy,
            lillyToSupplyStudyCompound: this.state.lillyToSupplyStudyCompound,
            ifNoWhy: this.state.ifNoWhy,
            lillyStudyCompound1: this.state.lillyStudyCompound1.value,
            requireFifthLillyCompound: this.state.requireFifthLillyCompound,
            lillyStudyCompound1Other: this.state.lillyStudyCompound1Other,
            lillyStudyCompound5: this.state.lillyStudyCompound5.value,
            compound1AvailableMaterial: this.state.compound1AvailableMaterial.value,
            lillyStudyCompound5Other: this.state.lillyStudyCompound5Other,
            compound1AvailableMaterialOther: this.state.compound1AvailableMaterialOther,
            compound5AvailableMaterial: this.state.compound5AvailableMaterial.value,
            compound1Formulation: this.state.compound1Formulation,
            compound5AvailableMaterialOther: this.state.compound5AvailableMaterialOther,
            compound1DosingSchedule: this.state.compound1DosingSchedule,
            compound5Formulation: this.state.compound5Formulation,
            compound1DosingScheduleOther: this.state.compound1DosingScheduleOther,
            compound5DosingSchedule: this.state.compound5DosingSchedule,
            compound1AdministeredDose: this.state.compound1AdministeredDose,
            compound5DosingScheduleOther: this.state.compound5DosingScheduleOther,
            compound1AmountQuantity: this.state.compound1AmountQuantity,
            compound5AdministeredDose: this.state.compound5AdministeredDose,
            requestedAmtOfComp1PreClin: this.state.requestedAmtOfComp1PreClin,
            compound5AmountQuantity: this.state.compound5AmountQuantity,
            requireSecondLillyCompound: this.state.requireSecondLillyCompound,
            requestedAmtOfComp5PreClin: this.state.requestedAmtOfComp5PreClin,
            lillyStudyCompound2: this.state.lillyStudyCompound2.value,
            requireSixthLillyCompound: this.state.requireSixthLillyCompound,
            lillyStudyCompound2Other: this.state.lillyStudyCompound2Other,
            lillyStudyCompound6: this.state.lillyStudyCompound6.value,
            compound2AvailableMaterial: this.state.compound2AvailableMaterial.value,
            lillyStudyCompound6Other: this.state.lillyStudyCompound6Other,
            compound2AvailableMaterialOther: this.state.compound2AvailableMaterialOther,
            compound6AvailableMaterial: this.state.compound6AvailableMaterial.value,
            compound2Formulation: this.state.compound2Formulation,
            compound6AvailableMaterialOther: this.state.compound6AvailableMaterialOther,
            compound2DosingSchedule: this.state.compound2DosingSchedule,
            compound6Formulation: this.state.compound6Formulation,
            compound2DosingScheduleOther: this.state.compound2DosingScheduleOther,
            compound6DosingSchedule: this.state.compound6DosingSchedule,
            compound2AdministeredDose: this.state.compound2AdministeredDose,
            compound6DosingScheduleOther: this.state.compound6DosingScheduleOther,
            compound2AmountQuantity: this.state.compound2AmountQuantity,
            compound6AdministeredDose: this.state.compound6AdministeredDose,
            requestedAmtOfComp2PreClin: this.state.requestedAmtOfComp2PreClin,
            compound6AmountQuantity: this.state.compound6AmountQuantity,
            requireThirdLillyCompound: this.state.requireThirdLillyCompound,
            requestedAmtOfComp6PreClin: this.state.requestedAmtOfComp6PreClin,
            lillyStudyCompound3: this.state.lillyStudyCompound3.value,
            requireSeventhLillyCompound: this.state.requireSeventhLillyCompound,
            lillyStudyCompound3Other: this.state.lillyStudyCompound3Other,
            lillyStudyCompound7: this.state.lillyStudyCompound7.value,
            compound3AvailableMaterial: this.state.compound3AvailableMaterial.value,
            lillyStudyCompound7Other: this.state.lillyStudyCompound7Other,
            compound3AvailableMaterialOther: this.state.compound3AvailableMaterialOther,
            compound7AvailableMaterial: this.state.compound7AvailableMaterial.value,
            compound3Formulation: this.state.compound3Formulation,
            compound7Formulation: this.state.compound7Formulation,
            compound3DosingSchedule: this.state.compound3DosingSchedule,
            compound7AvailableMaterialOther: this.state.compound7AvailableMaterialOther,
            compound3DosingScheduleOther: this.state.compound3DosingScheduleOther,
            compound7DosingSchedule: this.state.compound7DosingSchedule,
            compound3AdministeredDose: this.state.compound3AdministeredDose,
            compound7DosingScheduleOther: this.state.compound7DosingScheduleOther,
            compound3AmountQuantity: this.state.compound3AmountQuantity,
            compound7AdministeredDose: this.state.compound7AdministeredDose,
            requestedAmtOfComp3PreClin: this.state.requestedAmtOfComp3PreClin,
            compound7AmountQuantity: this.state.compound7AmountQuantity,
            requireFourthLillyCompound: this.state.requireFourthLillyCompound,
            requestedAmtOfComp7PreClin: this.state.requestedAmtOfComp7PreClin,
            lillyStudyCompound4: this.state.lillyStudyCompound4.value,
            lillyStudyCompound4Other: this.state.lillyStudyCompound4Other,
            compound4AvailableMaterial: this.state.compound4AvailableMaterial.value,
            compound4AvailableMaterialOther: this.state.compound4AvailableMaterialOther,
            compound4Formulation: this.state.compound4Formulation,
            compound4DosingSchedule: this.state.compound4DosingSchedule,
            compound4DosingScheduleOther: this.state.compound4DosingScheduleOther,
            compound4AdministeredDose: this.state.compound4AdministeredDose,
            compound4AmountQuantity: this.state.compound4AmountQuantity,
            requestedAmtOfComp4PreClin: this.state.requestedAmtOfComp4PreClin,
            requestingFunding: this.state.requestingFunding,
            nonLillySupport: this.state.nonLillySupport,
            amountOfFunds: this.state.amountOfFunds,
            nameOfCompany: this.state.nameOfCompany,
            currency: this.state.currency.value,
            typeOfSupport: this.state.typeOfSupport,
            references: this.state.references ,
            hCPProposalComments: this.state.hCPProposalComments,
            createdBy: this.context.userSystemId
        }
        try {
            const res = await API.post("proposal/add", {"proposal": {...obj}, "submission" : {
                id : this.props.submissionId
                // stage :"Proposal"                
            }});
            this.setState({successModal: true});
        }catch(err){
            this.setState({errorModal: true});
        }
    }
    else{
        this.setState({
            errorModal:true
        })
    }
    }
    getErrorDescriptions=()=>{

        let {error,proposallist}=this.state;
       
        let allFields=[...this.inputFieldsArrayNCRI,
            ...this.inputFieldsArrayStudyCompound,
            ...this.inputFieldsArrayFinancial,
            ...this.inputFieldsArrayOtherInfo,
            ...this.inputFieldsArrayClinical,
            ...this.inputFieldsArrayInformation,...this.inputFieldsArrayProposalIIR,...this.inputFieldsArrayProposal_Study];
    
        let messages = [];
    
        for (let [key, value] of Object.entries(error)) {
    
            let exist = allFields.find(p => p.name === key);
    
            if (exist) {
                let messageText=exist.label;
                if(error["lengthValidation"][key]){
                    messageText=messageText+' - Maximum Length should be less than '+proposallist[key]+" chars"
                }
                messages.push(messageText);
    
            }
    
        }
    
        return <ul>
    
            {messages&&messages.map((item,index)=>{
    
                return <li>{item}</li>
    
            })}
    
        </ul>}

    changeShowState = () => {
        let {onClose}=this.props;
        // this.setState({
        //     successModal: false,
        //     errorModal: false
        // }); 
        if(!this.state.errorModal)
        //this.props.history.push(`/submissions/${this.props.submissionId}`)
        this.clearFormData(onClose,true);


         this.setState({
            successModal: false,
             errorModal: false
         });

         
    }

    // onNavOut = () => {
    //     this.props.history.push(`/submissions/${this.props.location.state.submissionId}`)
    // }
    clearFormData=(callBack,isSuccess)=>{
        this.setState({
            ...this.INITIAL_STATE
        },()=>{callBack(isSuccess);});
    }
    onHide=()=>{
        let {onClose}=this.props;
        if(onClose){
            
            this.clearFormData(onClose);
            
        }
    }
    handleBackClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth' })}
    handleStartClick=()=>{this.titleRef.current.scrollIntoView({ behavior: 'smooth',block:'start' })}
    render() {
        let {modalProps,formData,fileInfo,isIUDOperation}=this.state;
        let loaderConfig={};
        if(isIUDOperation){
             loaderConfig={disabled:"disabled"};
        }
        console.log(this.props.submissionId)
        return (
            <Container className="new-flow-modal">
                 <Modal className="new-flow-modal"
      show   
      size="lg"
      dialogClassName="lg-xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

    <Modal.Header style={{border:0}}>
        <Modal.Title id="contained-modal-title-vcenter">
         <h5 className="font-weight-bold"> Add a New proposal</h5>
         <div style={{fontSize:'1rem'}}>
            <span>Please fill out this form to add a new proposal </span>
         </div>
        </Modal.Title>
      </Modal.Header><Modal.Body className="new-flow-modal">
               
                <Accordion defaultActiveKey="1" ref={this.titleRef}>
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                           Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsInformation()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={this.handleBackClick}>
                                           Proposal : IIR Application
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsProposalIIR()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={this.handleBackClick}>
                                        Proposal: Study Description
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsProposal_Study()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                   
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="4" onClick={this.handleBackClick}>
                                        Non Clinical Research Info
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsNCRI()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="5" onClick={this.handleBackClick}>
                                       Clinical Research Info
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsClinical()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="6" onClick={this.handleStartClick}>
                                        Study Compound Support
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsStudyCompound()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                   
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="7" onClick={this.handleBackClick}>
                                        Financial Support
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="7">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsFinancial()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                   
                                    <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="8" onClick={this.handleBackClick}>
                                        Other Information
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="8">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsOtherInfo()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    
                                    {/* <Card>

                                        <Accordion.Toggle as={Card.Header} eventKey="9" onClick={this.handleBackClick}>
                                        Feedback
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="9">
                                            <Card.Body>
                                            <Row>
                    {this.renderInputFieldsFeedback()}
                   
                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card> */}
                                    </Accordion>      
                                    <Row>
                    
                                    <div className="w-100 d-flex justify-content-center">
                        {/* <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm}>
                            Save
                        </Button> */}
                        </div>
                    </Row> 
                {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Proposal has been created!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body={<><div>An error occured! Please try again later!</div>{this.getErrorDescriptions()}</>}
                        changeShowState={this.changeShowState}
                    />}</Modal.Body>
                    <Modal.Footer>
                    {isIUDOperation&&<div className="d-flex align-items-center justify-content-center p-2">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>}
                    <div className="d-flex align-items-center justify-content-center w-100 p-2">
                        <IIRBtn.default {...loaderConfig} onClick={this.onHide} className="mr-2 p-2" style="danger w-100px"><span>Cancel</span></IIRBtn.default>
                        <IIRBtn.default {...loaderConfig} onClick={this.submitForm} className='p-2 d-flex align-items-center justify-content-center' style="success w-100px"><i className="bi bi-plus" ></i><span>ADD</span></IIRBtn.default>
                    </div>
      </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}
