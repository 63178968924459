import React, { Component } from 'react';
import API from '../../../api/apis';
import { Col, Row, Card, Modal } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import LoaderEx from '../loader/LoaderEx';
import UserContext from '../../../context/UserContext';
import Button from '../../common/Button';
import { isCoordinator } from '../../ToReviewDetails/commonFunctions';
import { dateTimeformat } from '../../../utility/helperFunctions';
import SwitchView from '../SwitchView';
import { switchEnum } from '../SwitchView/switchEnum';
import SwitchHistory from '../SwitchHistory';
import { OBJ_TYPES } from '../SwitchHistory/historyConsts';
class StrengthModalEx extends React.Component {
    static contextType = UserContext;
    state = {
        showLoader:true,
        name:"",
        hasChanged: false,
        id:this.props.rowId,
        viewDetails: true
    }
    get inputFields() {
        return [
            {
                label: "Strength Name *",
                type: "text",
                name: "name",
                disabled:!this.state.is_Coordinator
            },
            {
                label: "Created By",
                type: "text",
                name: "createdby",
                disabled: true,
                edit:true

            }, {
                label: "Last Modified By",
                type: "text",
                name: "updatedby",
                disabled: true,
                edit:true

            }
        ];
    }
    onSelectChange = (e, eo) => {
        this.setState({
            hasChanged: true,
            [eo.name || eo.target.name]: e,
        });
    }
    onInputChange = (e) => {
        this.setState({ hasChanged: true, [e.target.name]: e.target.value });
    }
     async componentDidMount() {
        let _isCoordinator = isCoordinator(this.context);
        this.setState({ is_Coordinator: _isCoordinator ,access:_isCoordinator});
        if (this.props.editMode === 'edit') {
            let strengthData = await this.getStrengthById(this.props.rowId);
            if(strengthData){
            strengthData["createdby"] =strengthData.created_by?.length ?strengthData.created_by_name+"  "+ dateTimeformat(strengthData.createdAt) : "";

        strengthData["updatedby"] =strengthData.updated_by?.length > 0 ?strengthData.updated_by_name + "  " + dateTimeformat(strengthData.updatedAt):strengthData.createdby ;
        
            let {name,createdby,updatedby}=strengthData;
            this.setState({name,createdby,updatedby,showLoader:false})}
    }
    else {
        this.setState({
            showLoader:false,
           
        })
    }}
    
    getStrengthById = async (id) => {

        try {

            const res = await API.get(`/seed/strength/getbyid/${id}`);

            if (res.data.success) {

                return res.data.data || null;

            } else {

                this.setState({

                    error: true,

                    showLoader: false

                });

            }

        } catch (e) {

            console.log(e);

            this.setState({

                error: true,

                showLoader: false

            });

        }

        return null;

    }
    formValidation = () => {
        let is_valid = true;
             const   initialValues={
                   name:this.state.name,
                  
                  
                  }
                  const errors = {};
                  
                  Object.keys(initialValues).forEach((key) => {
                    if (initialValues[key] === "" || initialValues[key] === null  ) {
                    
                  
                    errors[key] = true;
                    is_valid = false;
                   
                        
                    } 
                    this.setState({
                        error:  { ...errors}
                    });
                });
                  
        return is_valid;

    }
    onSave = (validate) => {

        if (this.formValidation(this.state)) {
            if (validate === "new") {
                this.props.handleStrengthSave(this.state)
            }
            else {
                this.props.handleStrengthUpdate(this.state, this.props.rowId)
            }
        }
         
      }
    renderInputFields = () => {
        return this.inputFields.map(field => {
            if (field.type === "alert-new")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.editMode != "edit")
                return false;
            return (
                <Col sm={6} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        disabled={!this.state.is_Coordinator|| field.disabled}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => { this.onInputChange(e, field) }) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    render() {
        const { showModal, handleModal,handleStrengthUpdate, rowId } = this.props;
        const { viewDetails } = this.state;
        return (
            <div>
                <Modal show={true} size="lg" className='new-flow-modal'>
                    {<div> <Modal.Header className='border-0' >
                            <Modal.Title id="contained-modal-title-vcenter">
                            <h5 className='p-0 m-0'><strong>{this.props.editMode==="edit"?" Strength" :"Add a Strength" }</strong></h5>
                           
                            </Modal.Title>
                            <div className='d-flex justify-content-end align-items-center'>
                            <span className="material-icons" 
                            onClick={() => handleModal(false,null,{})} style={{ cursor: 'pointer' }}>
                                close
                            </span>
                        </div>
                        </Modal.Header>
                            <Modal.Body>
                                { rowId &&
                                    <SwitchView
                                        viewMode={(value) => {
                                            if (value === switchEnum.DETAILS) {
                                                this.setState({ viewDetails: true });
                                            } else {
                                                this.setState({ viewDetails: false });
                                            }
                                        }}
                                    /> 
                                }

                                { viewDetails ? <>
                                    <Row>
                                        {this.state.showLoader===true?<LoaderEx/>:this.renderInputFields()}
                                    </Row>
                                </> 
                                : <SwitchHistory 
                                    object_type={OBJ_TYPES.STRENGTH} 
                                    object_id={rowId} 
                                  /> 
                                }
                            </Modal.Body>
                        { viewDetails && <Modal.Footer className="d-flex align-items-center justify-content-center w-100 p-2 border-0" >
                            <Button style="danger w-100px" onClick={() => handleModal(false, null, {})} className="d-flex align-items-center p-2 justify-content-center"><span>CANCEL</span></Button>
                            { this.state.is_Coordinator?
                                    (this.props.editMode === "edit" ) ?
                                        <Button style="success w-100px" onClick={() => this.onSave("update")} className="d-flex align-items-center p-2 justify-content-center"><span>SAVE</span></Button>: 
                                        <Button style="success w-100px" onClick={()=>this.onSave("new")} className="d-flex align-items-center p-2 justify-content-center"><span>ADD</span></Button>
                                :<></>}
                           


                        </Modal.Footer> } </div>}
                </Modal>
            </div>
        )
    }
}
export default StrengthModalEx;