import React from 'react'
import { Modal, Col, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../common/Button'
import DualListBox from 'react-dual-listbox';
import InputField from '../common/input-fields/InputField';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'font-awesome/css/font-awesome.min.css';
import API from '../../api/apis';
import UserContext from '../../context/UserContext';
import swal from "sweetalert";
import { OPERATOROPTIONS } from "../../consts"
import { dateStringformat } from "../../utility/helperFunctions"
import Loader from '../common/loader/Loader';
class CreateViewModal extends React.Component {
    static contextType = UserContext;
    state = {
        createViewName: "",
        is_default_view: false,
        is_active: true,
        error: {},
        selected: [],
        fieldList: [],
        filterFieldList: [],
        filterRowList: [],
        isLoading: false,
    }
    get inputFieldsArray() {
        return [
            {
                label: "View Name *",
                type: "text",
                name: "createViewName",
            },
            {
                label: "Is Default View",
                type: "checkbox",
                name: "is_default_view",
            },
        ];
    }

    renderInputFields = (inputFieldsArray) => {
        return inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12} key={field.label}><InputField {...field} /></Col>
            if (field.edit && this.props.viewEditFlag != "edit")
                return false;
            return (
                <Col sm={4} key={field.label}>
                    <InputField
                        {...field}
                        value={this.state[field.name]}
                        error={this.state.error?.[field.name]}
                        customerror={this.state.customerror?.[field.name]}
                        onSelect={field.onSelect || this.onSelectChange}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => this.onInputChange(e, field)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    renderFilterRowInputFields = (inputFieldsArray, indexOuter) => {
        return inputFieldsArray?.map((field, index) => {
            if (field.type === "close")
                return <Col sm={1} key={indexOuter}><span className="material-icons" onClick={() => this.handleDeleteFilterRow(indexOuter)}>close</span></Col>
            return (
                <Col key={field.label}>
                    <InputField
                        {...field}
                        value={field.value}
                        error={field.error}
                        onSelect={field.onSelect || field.searchSelct ?
                            (e, name) => this.onSearchFilterSelect(e, name, index, indexOuter) :
                            (e, eo) => this.onSelectFilterChange(e, eo, index, indexOuter)}
                        onChange={field.type !== "checkbox" ? field.onChange || ((e) => this.onFilterInputChange(e, field, index, indexOuter)) : this.checkboxChange}
                    />
                </Col>
            )
        });
    }
    onInputChange = (e, eo) => {
        this.setState({ hasChanged: true, [e.target.name]: e.target.value })
    }

    checkboxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            hasChanged: true,
            [name]: e,
        });
    }
    onFilterInputChange = (e, eo, index, indexOuter) => {
        const { filterRowList } = this.state;
        filterRowList[indexOuter][index].value = eo.type === "date" ? e : e.target.value
        this.setState({ filterRowList })
    }
    onSelectFilterChange = (e, eo, index, indexOuter) => {
        const { filterRowList } = this.state;
        filterRowList[indexOuter][index].value = e;
        if (eo.target?.name === "operator" && filterRowList[indexOuter][2].type === "multi-select" && (e != "In" || e != "Not In")) {
            let selectValue = filterRowList[indexOuter][2]["options"];
            filterRowList[indexOuter][2]["options"] = selectValue.map(item => ({ ...item, label: item.label || item.name, name: item.label || item.name }))
            filterRowList[indexOuter][2].type = "searchableDropdown";
            filterRowList[indexOuter][2].value = "";
        }
        if (e === "In" || e === "Not In") {
            let selectValue = filterRowList[indexOuter][2]["options"];
            if (typeof filterRowList[indexOuter][2]["options"][0] !== 'object'
                && filterRowList[indexOuter][2]["options"][0] !== null) {
                filterRowList[indexOuter][2]["options"] = selectValue.map(item => ({ value: item, label: item }))
            } else {
                filterRowList[indexOuter][2]["options"] = selectValue.map(item => ({ ...item, label: item.label || item.name }))
            }
            filterRowList[indexOuter][2].type = "multi-select";
            filterRowList[indexOuter][2].value = []
        }
        this.setState({ filterRowList })
    }
    onSearchFilterSelect = async (e, name, index, indexOuter) => {
        const { filterRowList } = this.state;
        filterRowList[indexOuter][1].options = OPERATOROPTIONS[e.field_type];
        filterRowList[indexOuter][1].value = "Equals To";
        filterRowList[indexOuter][2].type = e.field_type === "boolean" ? "dropdown" : e.field_type;
        filterRowList[indexOuter][2].value = e.field_type === "date" ? new Date() : "";
        if (e.field_type === "boolean") {
            filterRowList[indexOuter][2]["options"] = ['True', 'False'];
            filterRowList[indexOuter][2].value = "True"
        }
        if (e.field_type === "dropdown") {
            if (e.dropdown_type === "static") {
                filterRowList[indexOuter][2]["options"] = JSON.parse(e.related_values);
                filterRowList[indexOuter][2].value = JSON.parse(e.related_values)[0];
            }
            if (e.dropdown_type === "dynamic") {
                let res = await API.get(e.related_values);
                const dynamicOptions = res?.data.map(item => ({ ...item, value: item.id || item.name, label: item.label || item.name }))
                filterRowList[indexOuter][2].type = "searchableDropdown";
                filterRowList[indexOuter][2]["options"] = dynamicOptions;
                filterRowList[indexOuter][2].value = dynamicOptions[0];
            }
        }
        filterRowList[indexOuter][index].value = e
        this.setState({ filterRowList })
    }
    onDualListBoxChange = (selected) => {
        this.setState({ selected });
    };
    async componentDidMount() {
        await this.getFields();
        if (this.props.viewEditFlag) {
            await this.getViewDataById()
        }
    }
    /** Get all fields List  */
    tableMapping={
        "submission":'Submissions',
        "drug_delivery_sites":"Drug Delivery Sites",
        "concept":"Concept"
    }
    getLabel=(data)=>{
        let field_obj=(data.obj_type??"").toString().toLowerCase();
        let obj_desciption=this.tableMapping[field_obj]??data.obj_type;
       return `${data.field_label} (${obj_desciption})`;
    }
    getFields = async () => {
        this.setState({ isLoading: true });
        try {
            let res = await API.get('/views/userViewFieldLabel/list');
            // let filterFieldList = res.data.map(x => ({ ...x, name: this.getLabel(x), value: x.id, field_type: x.field_type, obj_type: x.obj_type }));
            let filterFieldList = res.data.map((x) => {
                
                // for Therapeutic Area replace "Diabetes" in filter value options
                if (x?.field_name === "therapeutic_area") {
                    return {
                        ...x, 
                        name: this.getLabel(x), 
                        value: x.id, 
                        field_type: x.field_type, 
                        obj_type: x.obj_type, 
                        related_values: x?.related_values?.replace("Diabetes", "Diabetes & Obesity")
                    }
                // keep same fields as before if map item is not Therapeutic Area
                } else {
                    return {
                        ...x, 
                        name: this.getLabel(x), 
                        value: x.id, 
                        field_type: x.field_type, 
                        obj_type: x.obj_type
                    }
                }
            })
            let fieldList = res.data.map(x => ({ label: this.getLabel(x), value: JSON.stringify(x),title:x.obj_type }));
            this.setState({
                fieldList, filterFieldList,
                isLoading: false
            });
        }
        catch (e) {
            console.log(e)
        }
    }
    /** Get selected view realted Data by ID */
    getViewDataById = async () => {
        const filterRow = [];
        this.setState({ isLoading: true });
        try {
            let res = await API.get(`/views/getbyid/${this.props.viewId}`);
            let filterRowPromise = res.data?.filter.map(async (item, index) => {
                item = JSON.parse(item);

                if (item?.field_name?.field_name === "therapeutic_area") {

                    // change label for exisitng view with value selected as "Diabetes" for Equals/Not Equals operator
                    if (item?.value === "Diabetes") {
                        item.value = "Diabetes & Obesity"
                    }

                    // change label for exisitng view with value selected as "Diabetes" for In/Not In operator
                    // item.value will be an array for In/Not In operator
                    if (item.value && (item.value.constructor === Array)) {
                        // checking if any of the selected filter value is "Diabetes"
                        if (item?.value?.some((ele) => ele?.value === "Diabetes")) {

                            item.value.map((e, i) => {
                                if (e.value === "Diabetes") {
                                    e.name = "Diabetes & Obesity";
                                    e.value = "Diabetes & Obesity"
                                    e.label = "Diabetes & Obesity";
                                }
                            })
                        }
                    }
                    // change label in options of exisitng view for "Diabetes"
                    if (item?.field_name?.related_values?.includes("Diabetes") &&
                        !item?.field_name?.related_values?.includes("Diabetes & Obesity")) {
                        item.field_name.related_values = item?.field_name?.related_values.replace("Diabetes", "Diabetes & Obesity")
                    }
                }
                
                const inputFilterFieldsArray = [
                    {
                        label: "Fields",
                        type: "searchableDropdown",
                        name: "field_name",
                        options: this.state.filterFieldList,
                        searchSelct: true,
                        value: item.field_name
                    },
                    {
                        label: "Operator",
                        type: "dropdown",
                        placeholder: "None",
                        name: "operator",
                        options: OPERATOROPTIONS[item.field_type],
                        value: item.operator
                    },
                    {
                        label: "Value",
                        type: item.field_type === "boolean" ? "dropdown" : item.field_type,
                        name: "value",
                        value: item.field_type === "date" ? new Date(item.value) : item.value
                    },
                    {
                        label: "Row Operator",
                        type: "dropdown",
                        name: "logic",
                        options: ["or", "and"],
                        value: item.logic
                    },
                    {
                        label: "",
                        type: "close",
                        index: index
                    },
                ]

                if (item.field_type === "boolean") {
                    inputFilterFieldsArray[2]["options"] = ['True', 'false']
                }
                if (item.field_type === "dropdown") {
                    if (item?.field_name?.dropdown_type === "static") {
                        if (item.operator === "In" || item.operator === "Not In") {
                            inputFilterFieldsArray[2]["options"] = JSON.parse(item?.field_name?.related_values).map(item => ({ value: item, label: item }))
                            inputFilterFieldsArray[2].type = "multi-select";
                        } else {
                            if (typeof inputFilterFieldsArray[2].value === 'object') {
                                const selectValue = JSON.parse(item?.field_name?.related_values);
                                if (typeof selectValue[0] === 'object') {
                                    inputFilterFieldsArray[2]["options"] = selectValue.map(item => ({ ...item, value: item.id || item.name, label: item.label || item.name }))
                                } else {
                                    inputFilterFieldsArray[2]["options"] = selectValue.map(item => ({ value: item, label: item, name : item}))
                                }
                                inputFilterFieldsArray[2].type = "searchableDropdown";
                            } else {
                                inputFilterFieldsArray[2]["options"] = JSON.parse(item?.field_name?.related_values);
                            }
                        }
                    }
                    if (item?.field_name?.dropdown_type === "dynamic") {
                        let res = await API.get(item?.field_name?.related_values);
                        const dynamicOptions = res?.data.map(item => ({ ...item, value: item.id || item.name, label: item.label || item.name }))
                        inputFilterFieldsArray[2].type = "searchableDropdown";
                        inputFilterFieldsArray[2]["options"] = dynamicOptions;
                        if (item.operator === "In" || item.operator === "Not In") {
                            inputFilterFieldsArray[2].type = "multi-select";
                        }
                    }
                }
                filterRow.push(inputFilterFieldsArray);
            })
            await Promise.all(filterRowPromise);
            this.setState({
                createViewName: res.data?.view_name,
                is_default_view: res.data?.is_default_view,
                is_active: res.data?.is_active,
                selected: res.data?.fields_array,
                filterRowList: filterRow.sort((a, b) => a[4].index - b[4].index),
                viewId: res.data?.id,
                isLoading: false
            });
        }
        catch (e) {
            console.log(e)
        }
    }
    /** Handle Add filter Button Functionality  */
    handleAddFilterRow = () => {
        const { filterRowList } = this.state;
        const inputFilterFieldsArray = [
            {
                label: "Fields",
                type: "searchableDropdown",
                name: "field_name",
                options: this.state.filterFieldList,
                searchSelct: true,
                value: ""
            },
            {
                label: "Operator",
                type: "dropdown",
                placeholder: "None",
                name: "operator",
                options: [],
                value: "Equals To"
            },
            {
                label: "Value",
                type: "text",
                name: "value",
                value: ""
            },
            {
                label: "Row Operator",
                type: "dropdown",
                name: "logic",
                options: ["or", "and"],
                value: "or"
            },
            {
                label: "",
                type: "close"
            },
        ]
        filterRowList.push(inputFilterFieldsArray)
        this.setState({ filterRowList })
    }
    /** Handle Delete specific Filter */
    handleDeleteFilterRow = (index) => {
        const { filterRowList } = this.state;
        filterRowList.splice(index, 1)
        this.setState({ filterRowList })
    }

    formValidation = () => {
        let is_valid = true;
        const initialValues = {
            createViewName: this.state.createViewName,
        }
        const errors = {};
        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === "" || initialValues[key] === null) {
                errors[key] = true;
                is_valid = false;
            }
            this.setState({
                error: { ...errors },
            })
        });
        return is_valid;
    }
    /** Genrate payload for view Save and Update  */
    genratePayload = () => {
        const { createViewName, filterRowList, selected, is_default_view, is_active } = this.state;
        const filter = [];
        filterRowList.map(item => {
            const obj = {}
            item.map((innerItem, index) => {
                if (index === 0) {
                    obj["field_type"] = innerItem?.value?.field_type
                    obj["object_type"] = innerItem?.value?.obj_type
                    obj[innerItem.name] = innerItem?.value?.name
                }
                obj[innerItem.name] = innerItem?.value
            })
            filter.push(obj)
        })
        const payload = {
            view_name: createViewName,
            is_default_view: is_default_view,
            fields_array: selected,
            is_active: is_active,
            filter: filter
        }
        return payload;
    }
    /** Handle Save Button Functionality of View  */
    handleCreateViewSave = async () => {
        if (this.formValidation()) {
            const payload = this.genratePayload();
            payload.created_by = this.context.userSystemId;
            try {
                const res = await API.post("views/add", payload);
                swal("View Created  Successfully", {
                    icon: "success",
                });
                await Object.keys(localStorage).map(item => { if (item.includes("filter_")) { localStorage.setItem(item, "") } })
                this.props.handleCreateViewModalShowHide(false, null)
                this.props.getViewList(false);
            } catch (err) {
                swal("Error in Creating New View , Please try again!", {
                    icon: "error",
                });
            }
        }

    }
    /** Handle Update/Edit functionality of View  */
    handleCreateViewUpdate = async (state) => {
        if (this.formValidation()) {
            const payload = this.genratePayload();
            payload.updated_by = this.context.userSystemId;
            payload.id = this.state.viewId;
            try {
                const res = await API.post("views/update", payload);
                swal("View Updated Successfully", {
                    icon: "success",
                });
                await Object.keys(localStorage).map(item => { if (item.includes("filter_")) { localStorage.setItem(item, "") } })
                this.props.handleCreateViewModalShowHide(false, null)
                this.props.getViewList(true);
            } catch (err) {
                swal("Error in Updating View , Please try again!", {
                    icon: "error",
                });
            }
        }
    }


    render() {
        const { handleCreateViewModalShowHide, showHideCreateViewModal, viewEditFlag, } = this.props;
        const { filterRowList, isLoading } = this.state;
        return (
            <div>
                <Modal show={showHideCreateViewModal} size="xl" className="new-flow-modal">
                    {isLoading && <Loader />}
                    {!isLoading && <div>
                        <Modal.Header className='border-0' closeButton onClick={() => handleCreateViewModalShowHide(false, null)}>
                            <Modal.Title className="w-100 d-flex justify-content-center border-0">
                              <h5 className='font-weight-bold'>  {viewEditFlag ? "Edit View " : "Create View"}</h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                {this.renderInputFields(this.inputFieldsArray)}
                            </Row>
                            <h6 style={{ fontWeight: "700", padding: "10px 10px 5px" }}>Select Fields</h6>
                            <Row>
                                <Col sm={12}>
                                    <DualListBox options={this.state.fieldList} onChange={this.onDualListBoxChange} selected={this.state.selected} canFilter preserveSelectOrder showOrderButtons />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <h6 style={{ fontWeight: "700", padding: "15px 10px 5px" }}>Select Filter </h6>
                                </Col>
                                <Col className="text-right pt-3">
                                    <a className="new-button-link " onClick={() => this.handleAddFilterRow()}>
                                        <span className="bi bi-plus-square-fill" > Add Filter</span>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pb-2 pl-4">Fields</Col>
                                <Col className="pb-2 pl-4">
                                    Operator <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">
                                        {"Use the ‘In’ and ‘Not In’ operator to select or exclude multiple values"}</Tooltip>}>
                                        <i class="bi ml-2 mt-1 bi-info-circle-fill text-primary"></i>
                                    </OverlayTrigger>
                                </Col>
                                <Col className="pb-2 pl-4">Value</Col>
                                <Col className="pb-2 pl-4">Row Operator</Col>
                                <Col sm="1" className="pb-2 pl-4"></Col>
                            </Row>
                            <Row className="filterRowList">
                                <Col>
                                    {filterRowList.length > 0 && filterRowList.map((item, index) => {
                                        return <Row>{this.renderFilterRowInputFields(item, index)}</Row>
                                    })}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="w-100 d-flex justify-content-center border-0">
                            <Button style="danger w-100px" className="d-flex align-items-center p-2 justify-content-center" onClick={() => handleCreateViewModalShowHide(false, null, {})}>
                                Cancel
                            </Button>

                            {viewEditFlag ?
                                <Button  style="success w-100px" className="d-flex align-items-center p-2 justify-content-center" onClick={() => this.handleCreateViewUpdate()}>
                                    Update
                                </Button> :
                                <Button  style="success w-100px" className="d-flex align-items-center p-2 justify-content-center" onClick={() => this.handleCreateViewSave()}>
                                    Save
                                </Button>
                            }

                        </Modal.Footer>
                    </div>
                    }
                </Modal>
            </div>
        )
    }

}

export default CreateViewModal;