import React, { Component } from 'react';

import { Col, Container, Row, Button } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { COORDINATOR_CONFIRMATIONS } from '../../../mapping';
import UserContext from '../../../context/UserContext';

export default class CoordinatorConfirmations extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        verifyCommunicationofPublication: this.props.submissionDetails.verify_comunication_of_publication || false,
        isTaskComplete: this.props.submissionDetails.debarment_disqual_task || false,
        disclaimer: this.props.submissionDetails.disclaiemer || false,
        externalResearchGuidelines: this.props.submissionDetails.external_research_guideline || false,
        lillyExpectationsOfHCPSent: this.props.submissionDetails.lilly_expectation_hcp_sent || false,
        compoundSafetyLanguageSent: this.props.submissionDetails.compound_safety_language_sent || false,
        hasChanged: false,
        successModal: false,
        errorModal: false    ,
        patchObject: {"id": this.props.submissionDetails.id}
    };
    componentDidMount() {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        })
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
           delete: selectedRole.includes("administrator"),
       },
        })
    }
    }
    get inputFieldsArray() {
        return  [
        {
            label: "Verify Communication of Publication",
            type: "checkbox",
            name:"verifyCommunicationofPublication",
            checked: false
        },
        {
            label: "Debarment/Disqual Task Complete?",
            type: "checkbox",
            name:"isTaskComplete",
            checked: false,
            helptext:"Debarment/Disqual Task Complete?"
        },
        {
            label: "Disclaimer",
            type: "checkbox",
            name:"disclaimer",
            checked: false
        },
        {
            label: "External Research Guidelines",
            type: "checkbox",
            name:"externalResearchGuidelines",
            checked: false
        },
        {
            label: "Lilly Expectations of HCP Sent",
            type: "checkbox",
            name:"lillyExpectationsOfHCPSent",
            checked: false
        },
        {
            label: "Compound Safety Language Sent",
            type: "checkbox",
            name:"compoundSafetyLanguageSent",
            checked: false
        }
    ]
    }

    checkboxChange = (e) => {
        const {name, checked} = e.target;
        this.setState({
            [name]: checked,
            patchObject:  {...this.state.patchObject, [COORDINATOR_CONFIRMATIONS[name]]: checked},
            hasChanged: true
        });
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={field.type !== "checkbox"? this.onInputChange: this.checkboxChange}
                />
            </Col>
            )
        });
    }

    submitForm = async () =>  {
        try {
            const res = await API.patch("submission/update", this.state.patchObject);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }
    coordinatorData = () => {
        const {submissionDetails} = this.props;
        const newObject = {};
        let obj = this.state.patchObject;
        
         Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null ) {
                return false;
            } 
            else{
                newObject[key] = obj[key];
            }
        });
        delete newObject.error;
        this.state.patchObject={};
        return newObject;
    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        return (
            <Container>
            <Row>
                {this.renderInputFields()}
            </Row>
            {/* <div className="w-100 d-flex justify-content-center">
                <Button className="px-4" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                    Save
                </Button>
            </div> */}
            {this.state.successModal &&
                <BootstrapModal 
                    show={this.state.successModal} 
                    header="Success"
                    body="Updated successfully!"
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal 
                    show={this.state.errorModal} 
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            </Container>
        )
    }
}
