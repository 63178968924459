import React from 'react'
import { Container } from 'react-bootstrap';
import UserContext from '../../../../context/UserContext';
import { dateformat } from '../../../../utility/helperFunctions';
import Button from '../../../common/Button';
import InputField from '../../../common/input-fields/InputField';
import EditorEx from './EditorEx';
import styles from './index.module.css';

import {EditorState,convertFromRaw,convertToRaw} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import API from '../../../../api/apis';
export default class Comments extends React.Component{
    static contextType=UserContext;
    
    constructor(props) {
        super(props);
        this.state={
            source:[],
            userSystemId:'',
            mentions:[],
            apiError:false,
            validationMessage:'',
            refreshKey:null
        }
        this.interval=15000;
        this._commentsThread=React.createRef();
    }
    componentDidMount(){
        this.getCommentsData();      
       
    }
    componentWillUnmount(){
        this.clearThread();
       
    }
    runPeriodicFetch=()=>{
        this.clearThread();
        this._commentsThread.current=setInterval(this.handlePeriodicFetch,this.interval);
    }
    clearThread=()=>{
        if (this._commentsThread.current) {
            clearInterval(this._commentsThread.current)
        }
    }
    handlePeriodicFetch=async()=>{
       await this.getComments(true);
    }
    getComments=async(isUpdate)=>{
        this.clearThread();
        let source = [];
        try {
            let response = await API.get(`submissionReview/commentList/${this.props.reviewerId}`);
            if (response && response.data) {
                source = response.data;
            }
            if (isUpdate)
                this.setState({ source });
        }
        catch (exp) {

        }
        this.runPeriodicFetch();
        return source;
    }
    getCommentsData=async()=>{      
        let source=await this.getComments();
        let userSystemId=this.context.userSystemId;      
        let mentions=this.generateMentionData();  
        this.setState({source,userSystemId,mentions},()=>{
            this.scrollToBottom();
        });
    }
    generateMentionData=()=>{     
       
        if (this.props.users && this.props.users.length > 0) {
            const res = this.props.users[0].reviewers_details || [];
            const resultArray = res.map(elm => ({
                name: `${elm.reviewer_name} | ${elm.reviewer_system_id}`,                
                id: elm.reviewer_system_id
            }));
            if(this.props.users[0].assigned_by&&this.props.users[0].assigned_by.id){
                let assigned_by=this.props.users[0].assigned_by;
                resultArray.push({
                    name: `${assigned_by.name} | ${assigned_by.id}`,                
                    id: assigned_by.id
                })
            }
            console.log(resultArray);
            return resultArray;
           
        }
        return [];
    }
     getFirstLetters=(str)=> {
        const firstLetters = str
          .split(' ');
        if(firstLetters.length>1){
           return [firstLetters[0],firstLetters[1]].map(word => word[0])
            .join('');
        }
        if(str.length>=2){
            return str.substr(0,2);
        }       
      
        return str;
      }
    
       getNodesText = (stringContent) => {
        const el = document.createElement('div');
        el.innerHTML = stringContent;
        return el.innerText;
      };
      
     handleValidation=(htmlContent,tags)=>{        
        let isValid=true;
        let validationMessage="";
        if(this.getNodesText(htmlContent).trim().length<=0){
            isValid=false;
            validationMessage="Please enter your comments";
        }
        if(this.getNodesText(htmlContent).trim().length>255){
            isValid=false;
            validationMessage="Text length should be less than 255 chars";
        }
       return {isValid ,validationMessage};
     }
     scrollToBottom = () => {
        if(this.messageView)
        this.messageView.scrollIntoView({ behavior: "smooth" });
      }
      handleCreateComment=async()=>{
        if(this.EditorView){
           //console.log(this.EditorView.onExtractData());
            const options = {
                entityStyleFn: (entity) => {
                  const entityType = entity.get("type").toLowerCase();              
                  if (entityType === "mention") {
                    const { mention } = entity.get("data");
                    return {
                      element: "a",
                      attributes: {
                        userid: mention.id,
                        className:"text-primary"
                      }
                    };
                  }
                },
              };
            let raw=convertFromRaw(this.EditorView.onExtractData());
            let htmlContent = stateToHTML(raw, options);            
            let tags = this.EditorView.getMentions();
            let { userSystemId } = this.state;
            let validationResponse=this.handleValidation(htmlContent,tags);
            if (validationResponse.isValid) {
                let formBody = {
                    "review_header_id": this.props.reviewerId,
                    "comments_from": userSystemId,
                    "tags": tags || [],
                    "comments_to": "",
                    "message": htmlContent,
                    "created_by": userSystemId
                }
                const res = await API.post("submissionReview/commentAdd", formBody);
                if(res&&res.data&&res.data.id){
                    this.setState({refreshKey:new Date().toISOString()},()=>{
                        this.getComments(true);
                    });
                
                }
                else{
                    this.setState({apiError:true,validationMessage:"Unable to send the comments!.Please try again"},this.clearValidation)
                }
            }  
            else{
                this.setState({apiError:true,validationMessage:validationResponse.validationMessage},this.clearValidation)
            }         
        }
      }
      clearValidation=()=>{
        window.setTimeout(()=>{
            this.setState({apiError:false,validationMessage:""});
        },3000);
      }
    renderCommentArea=(item,key)=>{
        let {userSystemId}=this.state;
        let classEx="";
        if(userSystemId===item.comments_from){
            classEx="flex-row-reverse ";
        }
        return <div className={["d-flex p-2 align-items-center",styles.comment_item,classEx].join(" ")}>
                <div className={["flex-grow-1",styles.comment_item_info].join(" ")} title={item.reviewer_name} dangerouslySetInnerHTML={
                    {__html:item.message}
                }></div>
                <div className={['rounded-circle d-flex align-items-center justify-content-center  p-1',userSystemId===item.comments_from?"mr-2":"ml-2",styles.bg_avatar].join(" ")} title={item.reviewer_name}>
                    {this.getFirstLetters(item.reviewer_name)}
                </div>
              
        </div>
    }
    renderListArea=()=>{
        let {source,userSystemId}=this.state;
        return <div className='d-flex flex-column w-100 flex-column-reverse'>
               {
                source.map((item,index)=>{
                    return item.reviewer_discussions.map((data,keyIndex)=>{
                        return this.renderCommentArea(data,keyIndex);
                    })
                })
               }
        </div>
    }
    
    renderInputArea=()=>{
        let {mentions,refreshKey}=this.state;
        return <div className='d-flex align-items-center'>
            <div  style={{flexBasis:"90%"}}>
             {mentions&&mentions.length>0&&<EditorEx mentions={mentions||[]} refresh={refreshKey} ref={ref=>this.EditorView=ref}></EditorEx>}
           
            </div>
            <Button className="ml-2 d-flex align-items-center pr-2 pl-2" onClick={e=>this.handleCreateComment()} style="danger">
                <span class="material-icons">
                    send
                </span>
                <span>Send</span>
            </Button>
        </div>
    }
    render(){
        let {apiError,validationMessage}=this.state;
        return <div className="w-100 d-flex align-items-center flex-column">
            <div className="list-unstyled mt-3 w-75" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {this.renderListArea()}
                <div  style={{ float:"left", clear: "both" }} ref={ref=>this.messageView=ref} ></div>
            </div>
            <div className="list-unstyled mt-3 w-75">
            {this.renderInputArea()}
            <div className='w-100' style={{height:'25px'}}>
                {apiError&&<span className="text-danger">{validationMessage}</span>}
            </div>
            </div>
          
        </div>
    }
}