import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import InputField from '../../common/input-fields/InputField';
import API from '../../../api/apis';
import BootstrapModal from '../../common/modal/BootstrapModal';
import { PRIMARY_INVESTIGATOR } from '../../../mapping';
import UserContext from '../../../context/UserContext';

export default class PrimaryInvestigator extends Component {
    
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        access: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },        extFirstName: this.props.submissionDetails?.investigator_first_name || "",
        extInvestigatorStreetAddress: this.props.submissionDetails.investigator_street_address || "",
        extMiddleName: this.props.submissionDetails?.investigator_middle_name || "",
        extInvestigatorCity: this.props.submissionDetails.investigator_city || "",
        extLastName: this.props.submissionDetails?.investigator_last_name || "",
        extInvestigatorCountry: this.props.submissionDetails.investigator_country || {},
        extInvestigatorPrimaryInstitution: this.props.submissionDetails.concepts[0]?.primary_institution ? {label  :  this.props.submissionDetails.concepts[0]?.primary_institution , value : this.props.submissionDetails.concepts[0]?.primary_institution} : "",
        extInvestigatorState: this.props.submissionDetails.investigator_state || "",
        extInvestigatorSecondaryInstitution: this.props.submissionDetails.secondary_institution_name || "",
        extInvestigatorAreaCode: this.props.submissionDetails.investigator_zipcode || "",
        extInvestigatorEmail: this.props.submissionDetails.email || "",
        extInvestigatorPhoneNumber: this.props.submissionDetails.phone_number || "",
        extEmailAddressforSafetyMailings: this.props.submissionDetails.investigator_email_for_safety || "",
        extInvestigatorAltPhone: this.props.submissionDetails.investigator_alt_phoneno || "",
        extInvestigatorSpeciality: this.props.submissionDetails.investigator_speciality || "",
        extInvestigatorFaxNumber: this.props.submissionDetails.investigator_fax_no || "",
        extIsPhysician:this.props.submissionDetails.are_you_physician !== null ? (this.props.submissionDetails.are_you_physician? "Yes":"No") : "",
        extMedicalLicenseNumber: this.props.submissionDetails.medical_no || "",
        extIsanotherOrganizationinvolved:this.props.submissionDetails.another_org_involved !== null ? (this.props.submissionDetails.another_org_involved? "Yes":"No") : "",
        countryList: [],
        stateList: [],
        patchObject: {"id": this.props.submissionDetails.id},
        investigatorFields: ['extFirstName','extMiddleName','extLastName'],
        organisationList : [],
        impact_investigator_id: this.props.submissionDetails.impact_investigator_id || "",

        customer_id: this.props.submissionDetails?.investigator?.customer_id || "",
    }
    async componentDidMount() {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            access: {
                view: true,
                create:  selectedRole.includes("administrator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("administrator"),
            },
        })
        this.getCountryList();     
        await this.getOrganizationlist();
    }
    componentDidUpdate(previousProps, previousState) {
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
        this.setState({
            selectedRole: selectedRole,
            access: {
           view: true,
           create:  selectedRole.includes("administrator"),
           edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
           delete: selectedRole.includes("administrator"),
       },
        })
    }
    }

    get inputFieldsArray() {
        return  [
        {
            label: "[EXT] Investigator First Name",
            type: "text",
            placeholder: "Enter First Name",
            name:"extFirstName",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Street Address",
            type: "text",
            placeholder: "Enter Street Address",
            name:"extInvestigatorStreetAddress",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Middle Initial",
            type: "text",
            placeholder: "Enter Middle Initial",
            name:"extMiddleName",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator City",
            type: "text",
            placeholder: "Enter City",
            name:"extInvestigatorCity",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Last Name",
            type: "text",
            placeholder: "Enter Last Name",
            name:"extLastName",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Country *",
            type: "searchableDropdown",
            name:"extInvestigatorCountry",
            options: this.state.countryList,
            onSelect: this.onCountryChange
        },
        {
            label: "[EXT] Investigator Primary Institution",
            type: "creatable",
            placeholder: "Enter Primary Institution",
            name:"extInvestigatorPrimaryInstitution",
            options: this.state.organisationList,
            onSelect: this.oncreatableSelect
        },
        {
            label: "[EXT] Investigator State",
            type: "searchableDropdown",
            name:"extInvestigatorState",
            placeholder: "Select State",
            options: this.state.stateList,
            onSelect: this.onSearchSelect
        },
        {
            label: "[EXT] Investigator Secondary Institution",
            type: "text",
            placeholder: "Enter Secondary Institution",
            name:"extInvestigatorSecondaryInstitution",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Zip/Postal Code",
            type: "text",
            placeholder: "Enter Zip/Postal Code",
            name:"extInvestigatorAreaCode",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Email",
            type: "text",
            placeholder: "Enter Email",
            name:"extInvestigatorEmail",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Phone Number",
            type: "text",
            placeholder: "Enter Phone Number",
            name:"extInvestigatorPhoneNumber",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Email Address for Safety Mailings",
            type: "text",
            placeholder: "Enter Email",
            name:"extEmailAddressforSafetyMailings",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Alt Phone",
            type: "text",
            placeholder: "Enter Alt Phone Number",
            name:"extInvestigatorAltPhone",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Investigator Speciality",
            type: "dropdown",
            placeholder: "Select Speciality",
            options: [
                "Rheumatologist",
                "Dermatologist",
                "Immunologist",
            ],
            name:"extInvestigatorSpeciality",
        },
        {
            label: "[EXT] Investigator Fax Number",
            type: "text",
            placeholder: "Enter Fax Number",
            name:"extInvestigatorFaxNumber",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Are you a Physician?",
            type: "dropdown",
            placeholder: "Select",
            options: [
                "Yes",
                "No"
            ],
            name:"extIsPhysician",
            helptext:"Are you a physician (a medical doctor or a doctor of osteopathic medicine)"
        },
        {
            label: "[EXT] If yes, Medical License Number",
            type: "text",
            placeholder: "Enter Medical License Number",
            name:"extMedicalLicenseNumber",
            value: "Pawan Bhatt"
        },
        {
            label: "[EXT] Is another Organization involved?",
            type: "dropdown",
            placeholder: "Select",
            options: [
                "Yes",
                "No"
            ],
            name:"extIsanotherOrganizationinvolved",
        },
        {
            label: "Impact Investigator ID",
            type: "text",
            name:"impact_investigator_id",
            disabled: true
        },
        {
            label: "Customer ID",
            type: "text",
            name: "customer_id",
            placeholder: "Enter Customer ID",
            value: "Pawan Bhatt"
        },
        ];
    }

  

    getCountryList = async () => {
        const res = await API.get("seed/country/getall");
        const resultArray = (res.data?.data || [] ).map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ 
            countryList: resultArray,
            extInvestigatorCountry: resultArray.find(cnt => ""+cnt.value === this.props.submissionDetails.investigator_country) || []
        },
            () => this.getStateList(this.state.extInvestigatorCountry?.name));
    }

    getStateList = async (country) => {
        if(country){
        const res = await API.get(`seed/states/data/${country}`);
        const resultArray = res.data.map(elm => ({ name: elm.name, value: elm.id}));
        this.setState({ 
            stateList: resultArray,
            extInvestigatorState: resultArray.find(state => state.value+"" === this.state.extInvestigatorState) || ""
        });
    }
    }

    onCountryChange = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [PRIMARY_INVESTIGATOR[name]]: e.value},
            hasChanged: true
        });
        this.getStateList(e.name)
    }

    onInputChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
            patchObject:  {...this.state.patchObject, [PRIMARY_INVESTIGATOR[name]]: value},
            hasChanged: true
        });
        
    }
    getOrganizationlist=async ()=>{
        
        const res = await API.get("/seed/organisation/data")
        const resultArray = (res.data?.data || [] ).map(elm => ({ label: elm.name,value:elm.name}));
        this.setState({ organisationList: resultArray});
    }

    onSelectChange = (e, eo) => {
        this.setState({
            [eo.name || eo.target.name]: e,
            patchObject:  {...this.state.patchObject, [PRIMARY_INVESTIGATOR[eo.name || eo.target.name]]: e},
            hasChanged: true
        });
        
    }
    oncreatableSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [PRIMARY_INVESTIGATOR[name]]: e},
            hasChanged: true
        });
    }
    onSearchSelect = (e, name) => {
        this.setState({
            [name]: e,
            patchObject:  {...this.state.patchObject, [PRIMARY_INVESTIGATOR[name]]: e.value},
            hasChanged: true
        }); 
    }

    renderInputFields = () => {
        return this.inputFieldsArray.map(field => {
            if (field.type === "alert")
                return <Col sm={12}  key={field.label}><InputField {...field} /></Col>
            return (
            <Col sm={6} key={field.label}>
                <InputField 
                    {...field } 
                    disabled = {field.disabled ? field.disabled : !this.state.access.edit }
                    value={this.state[field.name]}
                    onSelect={ field.onSelect || this.onSelectChange}
                    onChange={this.onInputChange }
                    error = { this.state.error?.[field.name] }
                />
            </Col>
            )
        });
    }
    getErrorMessage=()=>{
        let fields=this.inputFieldsArray;
        let responseFields=[];
        if(this.state.error){
        for( const [key,value] of Object.entries(this.state.error))  {
            let x=fields.find(p=>p.name==key);
            if(x){responseFields.push(x.label)}
        }
        }
        return responseFields
    }
    formValidation = () => {
        let is_valid = true;
        if( !this.state.extInvestigatorCountry || this.state.extInvestigatorCountry.length===0){
            is_valid = false;
            this.setState({
                error: {
                    extInvestigatorCountry: true
                }
            })
        }
        if( is_valid ){
            this.setState({
                error: {}
            })
        }
        return is_valid;
    }
    primaryInvestigatorData=()=>{
        if(this.formValidation()){
        const newObject={};
        const obj = {...this.state.patchObject};
        
    if(obj.investigator_primary_institution) 
         obj.investigator_primary_institution = obj.investigator_primary_institution.value;
         
    // const inf_fields = ["investigator_first_name", "investigator_middle_initial", "investigator_last_name"]
    const inf_fields = []
    const investigator = {};
    inf_fields.forEach(f => {
        if(f in obj) {
            investigator[f] = obj[f];
           
        }
    })
    if(this.state.patchObject['customer_id']){
        investigator.id = this.props.submissionDetails.investigator.id;
        obj.investigator = investigator
    }
    if(Object.keys(investigator).length>0)
    
    // obj['investigator'] = {...investigator, id:this.props.submissionDetails.investigator.id};
        obj['investigator'] = {...investigator, id:this.props.submissionDetails.investigator.id, customer_id:this.state.customer_id};
        
        Object.keys(obj).forEach((key) => {
            if (obj[key] === "" || obj[key] === null ) {
                return false;
            } 
            else{
                newObject[key] = obj[key];
            }
        });
        delete newObject.error;
        this.state.patchObject={};
        
        return newObject;
        }
        return {error:true}
}
    submitForm = async () => {
        const obj = {...this.state.patchObject};
        
        const inf_fields = ["investigator_first_name", "investigator_middle_initial", "investigator_last_name"]
        const investigator = {};
        inf_fields.forEach(f => {
            if(f in obj) {
                investigator[f] = obj[f];
                delete obj[f];
            }
        })
        if(Object.keys(investigator).length>0)
            obj['investigator'] = {...investigator, id:this.props.submissionDetails.investigator.id}
        try {
            const res = await API.patch("submission/update", obj);
            this.setState({
                successModal: true,
                patchObject: {"id": this.props.submissionDetails.id},
                hasChanged: false
            });
        }catch(err){
            this.setState({errorModal: true});
        }
    }

    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }

    render() {
        return (
            <>
            <Row>
                {this.renderInputFields()}
                {/* <div className="w-100 d-flex justify-content-center">
                    <Button className="ml-1" variant="primary" type="submit" onClick={this.submitForm} disabled={!this.state.hasChanged}>
                        Save
                    </Button>
                </div> */}
            </Row>
            {this.state.successModal &&
                    <BootstrapModal 
                        show={this.state.successModal} 
                        header="Success"
                        body="Updated successfully!"
                        changeShowState={this.changeShowState}
                    />
                }
                {this.state.errorModal &&
                    <BootstrapModal 
                        show={this.state.errorModal} 
                        variant="danger"
                        header="Ooops!!"
                        body="An error occured! Please try again later!"
                        changeShowState={this.changeShowState}
                    />
                }
            </>
        )
    }
}
