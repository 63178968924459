import React, { Component } from 'react'
import { Col, Row, Button, Nav } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import DatatableComponent from '../../../common/datatable/DatatableComponent';
import ContactsModal from '../ContactsModal';
import UserContext from '../../../../context/UserContext';
import API from '../../../../api/apis';
import BootstrapModal from '../../../common/modal/BootstrapModal';
import swal from 'sweetalert';
import DelegeeModalEx from '../../../common/listDropdown/DelegeeModalEx';
import { enableWFActions, renderTableIcons } from '../../../../utility/helperFunctions';
class Contacts extends Component {
    static contextType = UserContext;
    state = {
        selectedRole: Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole,
        accessContact: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        accessDelegee: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        datadelegee: [],
        response: {},
        showHideModal: false,
        viewEditFlag: null,
        viewEditData: {},
        id: "",
        showLoader: false,
        data: [],
        error: false,
        successModal: false,
        errorModal: false,
        delegeeModal:{
            show:false
        }
    }
    componentDidMount() {
        this.getDelegee(this.props.submissionDetails.id);
        this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id);

        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        this.setState({
            selectedRole: selectedRole,
            accessDelegee: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit:  selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
            accessContact: {
                view: true,
                create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
            },
        });
    }
    componentDidUpdate(previousProps, previousState) {
        console.log(this.context.selectedRole);
        console.log(previousState.selectedRole);
        let selectedRole = Array.isArray(this.context.selectedRole) ? 'administrator' : this.context.selectedRole;
        if (!Array.isArray(this.context.selectedRole) && (previousState.selectedRole != this.context.selectedRole)) {
            this.setState({
                selectedRole: selectedRole,
                accessDelegee: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit:  selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
                accessContact: {
                    view: true,
                    create: selectedRole.includes("administrator") || selectedRole.includes("global_coordinator"),
                    edit: selectedRole.includes("coordinator") || selectedRole.includes("administrator"),
                    delete: selectedRole.includes("global_coordinator") || selectedRole.includes("administrator"),
                },
            }, () => { console.log("userRole", this.state.selectedRole) })
        }
    }
    getStudyContactDetailsBySubmissionId = async (submissionid) => {
        try {
            const res = await API.get(`contact/submission/${submissionid}`)
            this.setState({ data: res.data });
        }
        catch (error) {
            console.log("error", error);
        }
    }
    handleModalShowHide = async (flag, viewEditFlag, row) => {
        if (row && row.id && viewEditFlag === "edit") {
            await this.getStudyContactByContactId(row.id)
            this.setState({ showHideModal: flag, viewEditFlag });
            return true;
        }
        this.setState({ showHideModal: flag, viewEditFlag, viewEditData: {} });
    }
    getStudyContactByContactId = async (id) => {
        try {
            const expenseDetails = await API.get(`contact/getbyid/${id}`);
            this.setState({
                viewEditData: expenseDetails.data,
                showLoader: false
            })
        } catch (e) {
            console.log(e);
            this.setState({
                error: true,
                showLoader: false
            })
        }
    }
    handleContactsDetails = (row) => {
        this.props.history.push(`/contacts/details/${row.id}`, { contactsId: row.id });
    }
    handleStudyContactDelete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`recycle/contact/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                      this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id)
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
              
            } 
          });
        // try {
        //     const res = await API.delete(`contact/delete/${row.id}`);
        //     this.setState({
        //         success_body: "Deleted successfully!",
        //         successModal: true,
        //         hasChanged: false
        //     });
        //     this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id)
        // } catch (err) {
        //     this.setState({ errorModal: true });
        // }
    }
    delete = async (row) => {
        swal({
            // title: "Are you sure?",
            text: "Are you sure, you want to delete this record?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async(willDelete) => {
            if (willDelete) {
                try {
                    const res = await API.post(`recycle/delegee/delete/${row.id}/${this.props.submissionDetails.id}`,{system_id : this.context.userSystemId});
                    swal("Deleted Successfully", {
                        icon: "success",
                      });
                      this.getDelegee(this.props.submissionDetails.id);
                }
                 catch (err) {
                    this.setState({ errorModal: true });
                }
                this.getDelegee(this.props.submissionDetails.id);
            } 
          });
        // try {

        //     const res = await API.post(`delegee/delete/${row.id}`, row)
        //     this.getDelegee(this.props.submissionDetails.id);
        // }


        // catch (error) {
        //     console.log("error", error);
        // } 
    }
    columns = [{
        dataField: 'additional_study_contact_name',
        text: 'Additional Study Contact',
        sort: true,
        headerClasses: "width-240",
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //             onClick={() => this.handleContactsDetails(row)}>{row.additional_study_contact_name}</p>
        //     </>
        // }
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <Nav.Link as={NavLink} to={{pathname:`/contacts/details/${row.id}`,state:{submissionId: this.props.submissionDetails.id,contactsId: row.id}}} style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //             onClick={(e) => this.handleContactsDetails(row,e)}>{row.additional_study_contact_name}</Nav.Link>
        //     </>
        // }
    }, {
        dataField: 'type',
        text: 'Type',
        sort: true,
        // formatter: (cell, row, rowIndex) => {
        //     return <>
        //         <p variant="link" style={{ "padding": "0", "fontsize": ".9rem", "margin-bottom": "0" }}
        //             onClick={() => this.handleContactsDetails(row)}>{row.type}</p>
        //     </>
        // }
    }, {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true
    }, {
        dataField: '',
        text: "Actions",
        csvExport: false,
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        headerClasses: "width-90",
        formatter: (cell, row, rowIndex) => {
           // var visibilityEdit = this.state.accessContact.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.accessContact.delete ? "visible" : "hidden";
            
            return <>{enableWFActions()&&<>
               <i className="bi bi-trash-fill" onClick={() => this.handleStudyContactDelete(row)}
                    style={{ visibility: visibilityDelete,  fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>
                {/* <i className="bi bi-pencil-square" onClick={() => this.handleModalShowHide(true, "edit", row)}
                    style={{ visibility: visibilityEdit, fontSize: "1.2rem", color: "black", marginRight: "1rem", padding: ".1rem", float: "left" }}></i> */}
            </>}</>
        }
    }];
    columnsdelegee = [{
        dataField: "submissionId",
        text: 'LLIR Delegee Name',
        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.name}
            </>
        }
    }, {
        dataField: 'email',
        text: '[EXT] Email',
        sort: true
    }, {
        dataField: 'accepted',
        text: 'Accepted',

        sort: true,
        formatter: (cell, row, rowIndex) => {
            return <>
                <input type="checkbox" checked={row.accepted} disabled
                    value={row.accepted} />
            </>
        }

    },
    {
        dataField: '',
        text: "Actions",
        csvExport: false,
        headerClasses: "width-90",
        events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        formatter: (cell, row, rowIndex) => {
            var visibilityEdit = this.state.accessDelegee.edit ? "visible" : "hidden";
            var visibilityDelete = this.state.accessDelegee.delete ? "visible" : "hidden";
            
            return <>{enableWFActions()&&<>
                <i className="bi bi-trash-fill" onClick={() => this.delete(row)}
                    style={{ visibility: visibilityDelete ,fontSize: "1.2rem", color: "black", padding: ".1rem", float: "left" }}></i>

            </>}</>
        }
    }
    ];

    data = [
        {
            'contactsTitle': "Submission-2922: Contacts- 1",
            'approval': "Awaiting Review",
            'decisionDate': "12/02/2018"
        }
    ]; 
    rowEvents= {
        onClick: (e, row, rowIndex) => {
            if(enableWFActions()){
                this.handleDelegee(true,row.id);
            }
            else{
                this.props.history.push(`/delegee/details/${row.id}`, { delegeeId: row.id });
            }
            
        }
       
    };
    columnEvents= {
        onClick: (e, row, rowIndex) => {
            if(enableWFActions()){
            this.handleModalShowHide(true,"edit",row);
            }
            else{
                this.handleContactsDetails(row);
            }
        }
       //this.props.history.push(`/delegee/details/${row.id}`, { delegeeId: row.id });
    };
   
    handleContactsSave = async (state) => {
        delete state.countryList;
        state.created_by = this.context.userSystemId;
       
        try {
            const res = await API.post("contact/add", state);
            this.setState({
                success_body: "Created successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id)

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }
    handleContactsUpdate = async (state) => {
        state.updated_by = this.context.userSystemId;
        state.last_modified_by_id=this.context.userSystemId;
        try {
            const res = await API.post("contact/update", state);
            this.setState({
                success_body: "Updated successfully!",
                successModal: true,
                hasChanged: false,
                showHideModal: false,
                viewEditFlag: null,
                viewEditData: {},
            });
            this.getStudyContactDetailsBySubmissionId(this.props.submissionDetails.id)

        } catch (err) {
            this.setState({ errorModal: true });
        }
    }

    getDelegee = async (submissionid) => {
        try {
            const res = await API.get(`delegee/submission/${submissionid}`)
            console.log("responsedelegee", res)

            this.setState({ datadelegee: res.data });
        }


        catch (error) {
            console.log("error", error);
        }



    }
    changeShowState = () => {
        this.setState({
            successModal: false,
            errorModal: false
        });
    }
    handleDelegee=(show,id)=>{
        this.setState({delegeeModal:{show,id}},()=>{
            if(!show){
                this.getDelegee(this.props.submissionDetails.id);
            }
        })
    }
   
    render() {
        const { showHideModal, viewEditFlag, viewEditData, data,
            successModal, showLoader, success_body, errorModal,delegeeModal } = this.state;
            let scTitle=`Additional Study Contacts (${this.state.data.length})`;
            let dgTiltle=` Delegees (${this.state.datadelegee.length})`;
        return <>
            <Row>
                
                <DatatableComponent 
                title={scTitle}
                pageIcons={renderTableIcons("ADD CONTACT",() => this.handleModalShowHide(true, null, {}),"add",this.state.accessContact.create&&enableWFActions() )}
                id="contact" data={this.state.data} rowEvents={this.columnEvents}  columns={this.columns} pagination="true" />
                         
            </Row>
            <Row className='pt-5'>
                <DatatableComponent id="delegee" 
                title={dgTiltle}
                pageIcons={renderTableIcons("ADD DELEGEE",() => this.handleDelegee(true),"add",this.state.accessDelegee.create&&enableWFActions() )}
                data={this.state.datadelegee}   rowEvents={this.rowEvents} columns={this.columnsdelegee} pagination="true" />
            </Row>
            {
                showHideModal &&
                <ContactsModal
                    showHideModal={showHideModal}
                    handleModalShowHide={this.handleModalShowHide}
                    handleContactsSave={this.handleContactsSave}
                    handleContactsUpdate={this.handleContactsUpdate}
                    viewEditFlag={viewEditFlag}
                    viewEditData={viewEditData}
                    submissionId={this.props.submissionDetails.id}
                />
            }
             {this.state.successModal &&
                <BootstrapModal
                    show={successModal}
                    header="Success"
                    body={success_body}
                    changeShowState={this.changeShowState}
                />
            }
            {this.state.errorModal &&
                <BootstrapModal
                    show={errorModal}
                    variant="danger"
                    header="Ooops!!"
                    body="An error occured! Please try again later!"
                    changeShowState={this.changeShowState}
                />
            }
            {
                delegeeModal && delegeeModal.show && <DelegeeModalEx
                    showHideModal={true}
                    handleModalShowHide={this.handleDelegee}
                    delegeeId={delegeeModal.id || null}
                    submissionId={this.props.submissionDetails.id}
                    >
                </DelegeeModalEx>
            }
        </>
    }
}


export default withRouter(Contacts);
